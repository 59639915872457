import React, { useState, useEffect } from 'react';
import { Chart } from '../../components/chart/Chart';
import apiCli from '../../service/apiCli';
import { Redirect } from 'react-router';

export const GraficoOcorrenciasStatus = (props) => {

    const [grafico, setGrafico] = useState(null);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [statusClicado, setStatusClicado] = useState(null);
    const [statusDescricaoClicado, setStatusDescricaoClicado] = useState(null);
    const [loading, setLoading] = useState(true);

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        }
    };

    const darkOptions = {
        legend: {
            labels: {
                fontColor: '#ebedef'
            }
        },
        responsive: true
    };

    const detalhes = (evt, item) => {
        if (item.length > 0) {
            setStatusClicado(grafico.Ids[item[0]._index]);
            setStatusDescricaoClicado(grafico.labels[item[0]._index]);
            setExibirDetalhes(true);
        }
    };

    const optionExibirDetalhes = {
        legend: {
            display: false,
            labels: {
                fontColor: '#495057'
            }
        },
        hover: {
            onHover: function(e) {
               var point = this.getElementAtEvent(e);
               if (point.length) e.target.style.cursor = 'pointer';
               else e.target.style.cursor = 'default';
            }
        },
        'onClick': detalhes
    };

    useEffect(() => {
        setLoading(true);
        apiCli.get(`/ocorrencias/status/id/${props.idCliente}`)
            .then(res => {
                setGrafico(res.data);
                setLoading(false);
            });
        }, [props.idCliente]);

        if (exibirDetalhes) {
            return <Redirect push to={`/ocorrenciastipostatuslist/${statusClicado}/${statusDescricaoClicado}`} />
        }
        else {
            return (
                <div>
                    <Chart type="horizontalBar" data={grafico} options={optionExibirDetalhes} />
                    {/* <AppContentContext.Consumer>
                        {
                            context => {
                                // let options = context.darkTheme ? this.darkOptions : this.lightOptions;
                                let options = lightOptions;
                                return <Chart type="horizontalBar" data={grafico} options={exibirDetalhes} />
                            }
                        }
                    </AppContentContext.Consumer> */}
                </div>
            )
        }
}