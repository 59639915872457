import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { TipoItem } from './TipoItem';
import { Dropdown } from '../components/dropdown/Dropdown';
import { Link, Redirect } from 'react-router-dom';

export const Itens = () => {

    let emptyItens = {
        iditem: null,
        item: '',
        descricao: '',
        tag: '',
        idtipoitem: null,
        idsistemaconstrutivo: null
    };

    const [item, setItem] = useState(emptyItens);
    const [listaItens, setListaItens] = useState(null);
    const [listaSistemaConstrutivo, setListaSistemaConstrutivo] = useState(null);
    const [itemDialog, setItemDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [tipoItem, setTipoItem] = useState(null);
    const [listaTiposItem, setListaTiposItem] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [edicao, setEdicao] = useState(false);

    useEffect(() => {
        api.get(`/itens`).then((res) => {
            setListaItens(res.data);
        });

        api.get(`/tipoitem`).then((res) => {
            setListaTiposItem(res.data);
        });

        api.get(`/sistemaconstrutivo`).then((res) => {
            setListaSistemaConstrutivo(res.data);
        });
    }, []);

    const openNew = () => {
        setItem(emptyItens);
        setSubmitted(false);
        setItemDialog(true);
    }

    const onTipoItemChange = (e) => {
        const val = e.target.value;
        // console.log(e.target.label);
        let _item = { ...item };
        _item['idtipoitem'] = val;
        setItem(_item);
    }

    const onSistemaConstrutivoChange = (e) => {
        const val = e.target.value;
        // console.log(e.target.label);
        let _item = { ...item };
        _item['idsistemaconstrutivo'] = val;
        setItem(_item);
    }

    const saveItens = () => {
        setSubmitted(true);

        if (item.item.trim() && item.idtipoitem && item.descricao) {
            let _listaItens = [...listaItens];
            let _item = { ...item };
            if (_item.iditem) {
                const index = findIndexById(_item.iditem);
                api.put('/itens', _item).then(res => {
                    if (res.status === 200) {
                        _listaItens[index] = _item;
                        setListaItens(_listaItens);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _item.iditem = -1;
                //_item.tag = 'tag';
                console.log(_item);
                api.post('/itens', _item).then(res => {
                    if (res.status === 200) {
                        _item.iditem = res.data.id;
                        setItem(_item);
                        _listaItens.push(_item);
                        setListaItens(_listaItens);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                        setEdicao(true);
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            // setListaItens(_listaItens);
            setItemDialog(false);
            setItem(emptyItens);
        }
    }

    const editItem = (item) => {
        setItem({ ...item });
        setItemDialog(true);
    }

    const deleteItens = () => {
        api.delete(`/itens/${item.iditem}`).then(res => {
            if (res.status === 200) {
                let _item = listaItens.filter(val => val.iditem !== item.iditem);
                setListaItens(_item);
                setDeleteItemDialog(false);
                setItem(emptyItens);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteItem = (item) => {
        setItem(item);
        setDeleteItemDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setItemDialog(false);
    }

    const hideDeleteItensDialog = () => {
        setDeleteItemDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaItens.length; i++) {
            if (listaItens[i].iditem === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _item = { ...item };
        _item[`${name}`] = val;
        setItem(_item);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaItens);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaItens');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link to={`/item/${rowData['iditem']}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link>
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteItem(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Itens</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const itensDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveItens} />
        </>
    );

    const deleteItensDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItensDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteItens} />
        </>
    );

    if (edicao) {
        return <Redirect push to={`/item/${item.iditem}`} />;
    }
    else {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">

                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                        <DataTable
                            ref={dt} value={listaItens} dataKey="iditem"
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Itens" globalFilter={globalFilter}
                            emptyMessage="Nenhuma Itens encontrada." header={header}>
                            <Column field="iditem" header="Id" sortable style={{ width: '6%' }} />
                            <Column field="tipoitem" header="Tipo de Item" sortable ></Column>
                            <Column field="item" header="Item" sortable ></Column>
                            <Column field="descricao" header="Descrição" sortable ></Column>
                            <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '100px' }} ></Column>
                        </DataTable>
                        <Dialog visible={itemDialog} style={{ width: '550px' }} header="Itens" modal className="p-fluid" footer={itensDialogFooter} onHide={hideDialog}>
                            <div className="p-field">
                                <label htmlFor="tipoitem">Tipo de item</label>
                                <Dropdown id="tipoitem" value={item.idtipoitem} options={listaTiposItem} onChange={(e) => onTipoItemChange(e)}
                                    optionValue="idtipoitem" optionLabel="tipoitem" filter showClear filterBy="tipoitem" virtualScrollerOptions={{ itemSize: 31 }} placeholder="Selecione"
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !item.idtipoitem })} />
                                {submitted && !item.tipoitem && <small className="p-invalid">Tipo de Item é requerido.</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="idsistemaconstrutivo">Sistema Construtivo</label>
                                <Dropdown id="idsistemaconstrutivo" value={item.idsistemaconstrutivo} options={listaSistemaConstrutivo} onChange={(e) => onSistemaConstrutivoChange(e)}
                                    optionValue="idsistemaconstrutivo" optionLabel="sistemaconstrutivo" filter showClear filterBy="sistemaconstrutivo" 
                                    virtualScrollerOptions={{ itemSize: 31 }} placeholder="Selecione"
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !item.idsistemaconstrutivo })} />
                                {submitted && !item.idsistemaconstrutivo && <small className="p-invalid">Sistema Construtivo é requerido.</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="item">Item</label>
                                <InputText id="item" value={item.item} onChange={(e) => onInputChange(e, 'item')} required className={classNames({ 'p-invalid': submitted && !item.item })} />
                                {submitted && !item.item && <small className="p-invalid">Item é requerido.</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="descricao">Descrição</label>
                                <InputText id="descricao" value={item.descricao} onChange={(e) => onInputChange(e, 'descricao')} required className={classNames({ 'p-invalid': submitted && !item.descricao })} />
                                {submitted && !item.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                            </div>
                        </Dialog>
                        <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteItensDialogFooter} onHide={hideDeleteItensDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {item && <span>Tem certeza que deseja excluir <b>{item.item}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}
