import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import classNames from 'classnames';
import { Toast } from '../components/toast/Toast';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import { Button } from '../components/button/Button';
import { InputMask } from '../components/inputmask/InputMask';
import { Panel } from '../components/panel/Panel';
import { Voltar } from './Componentes_Detalhes/Voltar';
import { Dropdown } from '../components/dropdown/Dropdown';
import { ClientesRepresentantes } from './ClienteRepresentantes';
import { ClientesAreas } from './ClienteAreas';
import { Calendar } from '../components/calendar/Calendar';
import { format, parseISO } from "date-fns";
import { ClientesPlanos } from './ClientePlanos';
import { ClienteUsuario } from './ClienteUsuarios';

export const ClienteEditEngenharia = (props) => {

    let emptyCliente = {
        idcliente: null,
        idempresa: 1,
        razaosocial: '',
        nomefantasia: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        // cidade: '',
        // uf: '',
        idtipocliente: 0,
        cnpj: '',
        datahabitese: null,
        cep: '',
        idestado: 0,
        idcidade: 0,
        planoativo: false
    };

    const [cliente, setCliente] = useState(emptyCliente);
    const [listaClientes, setListaClientes] = useState(null);
    const [representante, setRepresentante] = useState(null);
    const [listaRepresentantes, setListaRepresentantes] = useState(null);
    const [edicaoCliente, setEdicaoCliente] = useState(false);
    const [deleteRepresentantesDialog, setDeleteRepresentantesDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [id, setId] = useState(props.match.params.id);
    const [listaEstados, setListaEstados] = useState(null);
    const [listaCidades, setListaCidades] = useState(null);
    const [idestado, setIdEstado] = useState(null);

    useEffect(() => {
        //const { id } = match.params;

        api.get(`/estados`).then((res) => {
            setListaEstados(res.data);
        });


        // api.get(`/clientes/${id}`).then((res) => {
        //     setCliente(res.data);
        //     setIdEstado(res.data.idestado)

        // });

        // api.get(`/cidades/uf/${idestado}`).then((res) => {
        //     setListaCidades(res.data);
        // });
        api.get(`/clientes/${id}`).then((res) => {
            if (res.status === 200) {
                setCliente(res.data);
                api.get(`/cidades/uf/${res.data.idestado}`).then((res) => {
                    setListaCidades(res.data);
                });
            }
        });

    }, []);

    const saveCliente = () => {
        setSubmitted(true);
        if (cliente.razaosocial && cliente.nomefantasia && cliente.idcidade && cliente.idestado &&
            cliente.cnpj && cliente.datahabitese) {

            let _cliente = { ...cliente };
            console.table(_cliente)
            if (_cliente.idcliente) {
                // const index = findIndexById(_cliente.idcliente);
                api.put('/clientes', _cliente).then(res => {
                    if (res.status === 200) {
                        //_listaClientes[index] = _cliente;
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                setCliente(emptyCliente);
            }
        }
    }

    const onDropDownEstado = (e) => {
        let _cliente = { ...cliente };
        _cliente.idestado = e.value;


        api.get(`/cidades/uf/` + _cliente.idestado).then((res) => {
            setListaCidades(res.data);
        });
        setCliente(_cliente);
    }

    const onDropDownCidade = (e) => {
        let _cliente = { ...cliente };
        _cliente.idcidade = e.value;
        setCliente(_cliente);
    }


    const editCliente = (cliente) => {
        setCliente({ ...cliente });
        setEdicaoCliente(true);
    }

    const cancelEditCliente = () => {
        setSubmitted(false);
        setEdicaoCliente(false);
    }

    const editRepresentante = (representante) => {
        setRepresentante({ ...representante });
        //setEdicaoCliente(true);
    }

    const deleteRepresentante = () => {
        /* api.delete(`/clientes/${cliente.idcliente}`).then(res => {
            if (res.status === 200) {
                let _cliente = listaClientes.filter(val => val.idcliente !== cliente.idcliente);
                setListaClientes(_cliente);
                setDeleteClientesDialog(false);
                setCliente(emptyCliente);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            }) */
        alert("Este item foi xcluído!")
    }

    const confirmDeleteRepresentante = (representante) => {
        setRepresentante(representante);
        setDeleteRepresentantesDialog(true);
    }

    const confirmDeleteRepresentanteDialog = () => {
        setDeleteRepresentantesDialog(false);
    }

    const consultaCEP = () => {
        let cep = cliente.cep;
        if (cep.trim()) {
            let cepAux = cep.replace('.', '');
            cepAux = cepAux.replace('-', '');
            if (cepAux.length === 8) {
                api.get(`https://viacep.com.br/ws/${cepAux}/json/`)
                    .then(res => {
                        if (res.status === 200) {
                            if (typeof res.data.erro === "undefined") {
                                let _cliente = { ...cliente };
                                let ibge = res.data.ibge;
                                api.get(`/cidades/ibge/${ibge}`)
                                    .then(cidade => {
                                        api.get(`/cidades/uf/${cidade.data.idestado}`)
                                            .then(res => {
                                                setListaCidades(res.data);
                                            });
                                        _cliente.idcidade = cidade.data.idcidade;
                                        _cliente.idestado = cidade.data.idestado;
                                    })
                                _cliente.logradouro = res.data.logradouro.toUpperCase();
                                _cliente.bairro = res.data.bairro.toUpperCase();
                                // _cliente.cidades = res.data.ibge;
                                setCliente(_cliente);
                            }
                            else {
                                this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${cep} não foi localizado`, life: 3000 });
                            }
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: `Ocorreu algum problema ao tentar consultar o CEP ${cep}`, life: 3000 });
                        console.log(error.data);
                    })
            }
            else {
                alert(`CEP ${cep} inválido`)
            }
        }
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaClientes.length; i++) {
            if (listaClientes[i].idcliente === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _cliente = { ...cliente };
        _cliente[`${name}`] = val;
        setCliente(_cliente);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-eye" className="p-button p-button-primary p-mr-2" onClick={() => editRepresentante(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteRepresentante(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Cliente</h5>
        </div>
    );

    const deleteRepresentantesDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={confirmDeleteRepresentanteDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteRepresentante} />
        </>
    );

    const FormatarData = (value) => {
        if (value) {
            let data = parseISO(value);
            return data;
        }
    }
    
    return (
        <div className="p-grid crud-demo">
            <div className="content-section introduction">
                <h5>Cliente - {cliente.nomefantasia} </h5>
            </div>

            <div className=" p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <Panel header={'Cliente'} toggleable collapsed={true}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-1" >
                                <label htmlFor="idcliente">Id</label>
                                <InputText id="idcliente" value={cliente.idcliente} readOnly={true} disabled={true} />
                            </div>
                            <div className="p-field p-col-5" >
                                <label htmlFor="razaosocial">Razão Social</label>
                                <InputText id="razaosocial" value={cliente.razaosocial} onChange={(e) => onInputChange(e, 'razaosocial')}
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !cliente.razaosocial })}
                                    onFocus={(e) => e.target.select()} readOnly={!edicaoCliente} disabled={!edicaoCliente} />
                                {submitted && !cliente.razaosocial && <small className="p-invalid">Razão Social é requerido.</small>}
                            </div>
                            <div className="p-field p-col-4" >
                                <label htmlFor="nomefantasia">Nome Fantasia</label>
                                <InputText id="nomefantasia" value={cliente.nomefantasia} onChange={(e) => onInputChange(e, 'nomefantasia')}
                                    required className={classNames({ 'p-invalid': submitted && !cliente.nomefantasia })}
                                    onFocus={(e) => e.target.select()} readOnly={!edicaoCliente} disabled={!edicaoCliente} />
                                {submitted && !cliente.nomefantasia && <small className="p-invalid">Nome Fantasia é requerido.</small>}
                            </div>
                            <div className="p-field p-col-2">
                                <label htmlFor="cnpj">CNPJ</label>
                                <InputMask id="cnpj" value={cliente.cnpj} onChange={(e) => onInputChange(e, 'cnpj')}
                                    required className={classNames({ 'p-invalid': submitted && !cliente.cnpj })}
                                    disabled={!edicaoCliente} mask={"99.999.999/9999-99"}
                                    onFocus={(e) => e.target.select()} readonly={!edicaoCliente} />
                                {submitted && cliente.cnpj == "" && <small className="p-invalid">CNPJ é requerido.</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-1">
                                <label htmlFor="cep">CEP</label>
                                <div className="p-inputgroup">
                                    <InputMask id="cep" value={cliente.cep} onChange={(e) => onInputChange(e, 'cep')}
                                        mask="99.999-999" onFocus={(e) => e.target.select()} disabled={!edicaoCliente} />
                                    {/* <Button icon="pi pi-search" className="p-button" onClick={consultaCEP} /> */}
                                </div>
                            </div>
                            <div className="p-field p-col-4">
                                <label htmlFor="logradouro">Logradouro</label>
                                <InputText id="logradouro" value={cliente.logradouro} onChange={(e) => onInputChange(e, 'logradouro')}
                                    required className={classNames({ 'p-invalid': submitted && !cliente.logradouro })}
                                    onFocus={(e) => e.target.select()} readOnly={!edicaoCliente} disabled={!edicaoCliente} />
                                {submitted && !cliente.logradouro && <small className="p-invalid">Logradouro é requerido.</small>}
                            </div>
                            <div className="p-field p-col-1">
                                <label htmlFor="numero">Número</label>
                                <InputText id="numero" value={cliente.numero} onChange={(e) => onInputChange(e, 'numero')}
                                    required className={classNames({ 'p-invalid': submitted && !cliente.numero })}
                                    onFocus={(e) => e.target.select()} readOnly={!edicaoCliente} disabled={!edicaoCliente} />
                                {submitted && !cliente.numero && <small className="p-invalid">Número é requerido.</small>}
                            </div>
                            <div className="p-field p-col-3">
                                <label htmlFor="complemento">Complemento</label>
                                <InputText id="complemento" value={cliente.complemento} onChange={(e) => onInputChange(e, 'complemento')}
                                    onFocus={(e) => e.target.select()} readOnly={!edicaoCliente} disabled={!edicaoCliente} />
                            </div>
                            <div className="p-field p-col-3">
                                <label htmlFor="bairro">Bairro</label>
                                <InputText id="bairro" value={cliente.bairro} onChange={(e) => onInputChange(e, 'bairro')}
                                    required className={classNames({ 'p-invalid': submitted && !cliente.bairro })}
                                    onFocus={(e) => e.target.select()} readOnly={!edicaoCliente} disabled={!edicaoCliente} />
                                {submitted && !cliente.bairro && <small className="p-invalid">Bairro é requerido.</small>}
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-3">
                                <label htmlFor="idcidade">Cidade</label>
                                {/* <InputText id="cidade" value={cliente.idcidade} onChange={(e) => onInputChange(e, 'idcidade')}
                                    required className={classNames({ 'p-invalid': submitted && !cliente.idcidade })}
                                    onFocus={(e) => e.target.select()} readOnly={!edicaoCliente} disabled={!edicaoCliente} /> */}
                                <Dropdown id="idcidade" value={cliente.idcidade} options={listaCidades} onChange={(e) => onDropDownCidade(e)}
                                    optionLabel="cidade" optionValue='idcidade' placeholder="Selecione"
                                    disabled={!edicaoCliente} filter showClear required className={classNames({ 'p-invalid': submitted && !cliente.idcidade })} />
                                {submitted && !cliente.idcidade && <small className="p-invalid">Cidade é requerido.</small>}
                                {/* {submitted && !cliente.idcidade && <small className="p-invalid">Cidade é requerido.</small>} */}
                            </div>
                            <div className="p-field p-col-3">
                                <label htmlFor="uf">Estado</label>
                                {/* <InputText id="uf" value={cliente.idestado} onChange={(e) => onInputChange(e, 'idestado')}
                                    required className={classNames({ 'p-invalid': submitted && !cliente.idestado })}
                                    onFocus={(e) => e.target.select()} readOnly={!edicaoCliente} disabled={!edicaoCliente} /> */}
                                <Dropdown id="idestado" value={cliente.idestado} options={listaEstados} onChange={(e) => onDropDownEstado(e)}
                                    optionLabel="estado" optionValue='idestado' placeholder="Selecione"
                                    disabled={!edicaoCliente} filter showClear required className={classNames({ 'p-invalid': submitted && !cliente.idestado })} />
                                {submitted && !cliente.idestado && <small className="p-invalid">UF é requerido.</small>}
                            </div>

                            <div className="p-field p-col-3">
                                {/* <label htmlFor="datahabitese">Data de Habite-se ${cliente.datahabitese}</label>
                                <InputMask id="datahabitese" value={cliente.datahabitese} onChange={(e) => onInputChange(e, 'datahabitese')} mask="99/99/9999" placeholder="99/99/9999" slotChar="mm/dd/yyyy" className={classNames({ 'p-invalid': submitted && !cliente.datahabitese })} ></InputMask>
                                {submitted && !cliente.datahabitese && <small className="p-invalid">Data de Habite-se é requerido.</small>} */}
                                <label htmlFor="datahabitese">Data de Habite-se</label>
                                {/* <InputMask id="datahabitese" value={cliente.datahabitese} disabled={!edicaoCliente} onChange={(e) => onInputChange(e, 'datahabitese')} mask="99/99/9999" slotChar="mm/dd/aaaa" className={classNames({ 'p-invalid': submitted && !cliente.datahabitese })} ></InputMask>
                                {submitted && !cliente.datahabitese && <small className="p-invalid">Data de Habite-se é requerido.</small>} */}
                                <Calendar id="datahabitese" showIcon dateFormat="dd/mm/yy" value={FormatarData(cliente.datahabitese)} disabled={!edicaoCliente}
                                > </Calendar>
                            </div>
                        </div>
                        {/* <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-3">
                                    <label htmlFor="cnpj">Edição</label>
                                    <InputText value={edicaoCliente} />
                                </div>
                                <div className="p-field p-col-3">
                                    <label htmlFor="cnpj">Submitted</label>
                                    <InputText value={submitted} />
                                </div>
                            </div> */}

                    </Panel>
                    <br />
                    <div>
                        <ClientesRepresentantes valorId={id} edicao={false} ></ClientesRepresentantes>
                    </div>
                    <br />
                    <div>
                        <Panel header="Usuários" toggleable collapsed={true}>
                            <ClienteUsuario valorId={id} />
                        </Panel>
                    </div>

                    <br />
                    <div>
                        <ClientesAreas valorId={id} ></ClientesAreas>
                    </div>
                    <br />
                    <div>
                        <ClientesPlanos valorId={id} planoAtivo={cliente.planoativo}></ClientesPlanos>
                    </div>
                    <br />
                    <div>
                        <React.Fragment>
                            {/* {!edicaoCliente && <Button label="Editar" icon="pi pi-pencil" className="p-button p-mr-2" onClick={editCliente} />}
                            {edicaoCliente && <Button label="Salvar" icon="pi pi-check" className="p-button-success p-mr-2" onClick={saveCliente} />}
                            {edicaoCliente && <Button label="Cancelar" icon="pi pi-times" className="p-button-warning p-mr-2" onClick={cancelEditCliente} />} */}

                            <div className="p-d-flex p-justify-end">
                                <div>
                                    <Voltar page={"clientesengenharia"} ></Voltar>
                                    <Button label="Salvar" icon="pi pi-check" className="p-button-success" onClick={saveCliente} />
                                </div>
                            </div>
                        </React.Fragment>
                    </div>

                    <Dialog visible={deleteRepresentantesDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteRepresentantesDialogFooter} onHide={confirmDeleteRepresentanteDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {representante && <span>Tem certeza que deseja excluir <b>{representante.nome}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );

}
