import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';

export const TipoArea = () => {

    let emptyTipoArea = {
        idarea: null,
        area: ''
    };

    const [area, setTipoArea] = useState(emptyTipoArea);
    const [listaTipoAreas, setListaTipoAreas] = useState(null);
    const [areaDialog, setTipoAreaDialog] = useState(false);
    const [deleteAreaDialog, setDeleteAreaDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/areas`).then((res) => {
            setListaTipoAreas(res.data);
        });
    }, []);

    const openNew = () => {
        setTipoArea(emptyTipoArea);
        setSubmitted(false);
        setTipoAreaDialog(true);
    }

    const saveArea = () => {
        setSubmitted(true);

        if (area.area.trim()) {
            let _listaTipoAreas = [...listaTipoAreas];
            let _area = { ...area };

            if (_area.idarea) {
                const index = findIndexById(_area.idarea);

                api.put('/areas', _area).then(res => {
                    if (res.status === 200) {
                        _listaTipoAreas[index] = _area;              
                        setListaTipoAreas(_listaTipoAreas);   
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });                                             
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _area.idarea = 0;

                api.post('/areas', _area).then(res => {
                    if (res.status === 200) {
                        _area.idarea = res.data.id;
                        _listaTipoAreas.push(_area);                   
                        setListaTipoAreas(_listaTipoAreas);                        
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });                        
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }

            // setListaTipoAreas(_listaTipoAreas);
            setTipoAreaDialog(false);
            setTipoArea(emptyTipoArea);
        }
    }

    const editArea = (area) => {
        setTipoArea({ ...area });
        setTipoAreaDialog(true);
    }

    const deleteArea = () => {
        api.delete(`/areas/${area.idarea}`).then(res => {
            if (res.status === 200){
                let _areas = listaTipoAreas.filter(val => val.idarea !== area.idarea);
                setListaTipoAreas(_areas);
                setDeleteAreaDialog(false);
                setTipoArea(emptyTipoArea);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
        .catch(error => {
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
            console.log(error);            
        })

    }

    const confirmDeleteArea = (area) => {
        setTipoArea(area);
        setDeleteAreaDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTipoAreaDialog(false);
    }

    const hideDeleteAreaDialog = () => {
        setDeleteAreaDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaTipoAreas.length; i++) {
            if (listaTipoAreas[i].idarea === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _area = { ...area };
        _area[`${name}`] = val;
        setTipoArea(_area);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaTipoAreas);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaTipoAreas');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editArea(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteArea(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Tipos de Áreas</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const areaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveArea} />
        </>
    );
    const deleteAreaDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAreaDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteArea} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt} value={listaTipoAreas} dataKey="idarea"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Áreas" globalFilter={globalFilter}
                        emptyMessage="Nenhum Tipo de Área encontrado." header={header}>
                        <Column field="idarea" header="Id" sortable style={{ width: '6%' }}></Column>
                        <Column field="area" header="Descrição" sortable ></Column>
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }}></Column>
                    </DataTable>

                    <Dialog visible={areaDialog} style={{ width: '450px' }} header="Tipo de Área" modal className="p-fluid" footer={areaDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="area">Descrição</label>
                            <InputText id="area" value={area.area} onChange={(e) => onInputChange(e, 'area')} required autoFocus className={classNames({ 'p-invalid': submitted && !area.area })} />
                            {submitted && !area.area && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                    </Dialog>

                    <Dialog visible={deleteAreaDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteAreaDialogFooter} onHide={hideDeleteAreaDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {area && <span>Tem certeza que deseja excluir <b>{area.area}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
