import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import classNames from 'classnames';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import { InputTextarea } from '../components/inputtextarea/InputTextarea';
import { Button } from '../components/button/Button';
import { Panel } from '../components/panel/Panel';
import { Dropdown } from '../components/dropdown/Dropdown';
import { InputSwitch } from "../components/inputswitch/InputSwitch";
import { Card } from '../components/card/Card';
import { Voltar } from './Componentes_Detalhes/Voltar';
import { Chips } from '../components/chips/Chips';


export const Area = (props) => {

    let emptyTipoArea = {
        idclientesareas: 0,
        idcliente: 0,
        idarea: 0,
        descricao: '',
        dataatualizacao: null,
        ativo: false
    };
    //ClientesAreasSistemaConstrutivoItens
    let emptyItemArea = {
        idclientesareassistemaconstrutivoitens: 0,
        idclientesareas: 0,
        descricao: '',
        idsistemaconstrutivoitens: 0,
        ativo: false,
        sistemaconstrutivo: ''
    }

    let emptyAreaClonada = {
        descricao: ''
    }

    let testeJson = {
        descricao: 'teste',
        descricao: 'opa',
        descricao: 'inputarea'
    };



    const [area, setTipoArea] = useState(emptyTipoArea);
    const [listaTipoAreas, setListaTipoAreas] = useState(null);
    const [item, setItem] = useState(null);
    const [listaItens, setListaItens] = useState(null);
    const [listaItensInseridos, setListaItensInseridos] = useState(null);
    const [addItemDialog, setAddItemDialog] = useState(false);
    const [editItemDialog, seteditItemDialog] = useState(false);
    const [deleteItemDialog, setDeleteItemDialog] = useState(false);
    const [deleteAreaDialog, setDeleteAreaDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const subsRef = useRef(null);
    const dt = useRef(null);
    const [id, setId] = useState(props.match.params.id);
    const [idCliente, setIdCliente] = useState(props.match.params.idcliente);
    const [itemAreaEdit, setItemAreaEdit] = useState(emptyItemArea);
    const [clonarDialog, setClonarDialog] = useState(false);
    const [areaClonada, setAreaClonada] = useState(emptyAreaClonada);
    const [areasClonadas, setAreasClonadas] = useState([]);
    const [areasClonadasChips, setAreasClonadasChips] = useState([]);
    const [teste, setTeste] = useState('');
    const [descClonada, setdescClonada] = useState(null);
    const [subs, setSubs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [listaClienteAreas, setListaClienteAreas] = useState(null);


    useEffect(() => {

        api.get('/areas').then(res => {
            setListaTipoAreas(res.data);
        });

        api.get(`/clientesareas/${id}`).then((res) => {
            setTipoArea(res.data);
        });

        api.get(`/clientesareassistemaconstrutivoitens/clientesareas/${id}`).then((res) => {
            setListaItensInseridos(res.data);
        });

        api.get(`/clientesareas/cliente/${idCliente}`).then((res) => {
            setListaClienteAreas(res.data);
        });

    }, []);

    const editItens = (item) => {
        /*setItem({ ...item });
        setItemDialog(true);*/
        alert("editar item")
    }

    const showAddItemDialog = () => {
        api.get(`/sistemaconstrutivoitens/clienteareas/${id}`).then((res) => {
            setListaItens(res.data);
        });

        setAddItemDialog(true);
    }


    const confirmDeleteItem = (item) => {
        /*setItem({ ...item });
        setItemDialog(true);*/
        alert("item foi excluido!")
    }

    const carregarDropdowns = () => {
        api.get('/areas').then(res => {
            setListaTipoAreas(res.data);
        })
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _area = { ...area };
        _area[`${name}`] = val;
        setTipoArea(_area);
    }

    const onTextAreaChange = (e) => {
        // let _areasClonadas = [...areasClonadas];
        // let _area = {...areaClonada};

        // _area['descricao'] = e.target.value;
        // _areasClonadas.push(_area)
        // // console.log(_areasClonadas);
        // // console.log(e.target.value);
        // // setAreaClonada(_area);
        // setAreasClonadas(_areasClonadas);
        // console.log(_areasClonadas);
        setTeste(e.target.value);
    }

    const onInputChangeEdit = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _item = { ...itemAreaEdit };
        _item[`${name}`] = val;
        setItemAreaEdit(_item);
    }

    const onInputChangeClonadaEdit = (e) => {
        // const val = (e.target && e.target.value) || '';
        // let _item = { ...itemAreaEdit };
        // _item[`${name}`] = val;
        // setItemAreaEdit(_item);
        let _desc = { ...descClonada };
        _desc = e.target.value;
        setdescClonada(_desc);
    }

    const onInputChangeSubs = (e) => {
        // const val = (e.target && e.target.value) || '';
        // let _item = { ...itemAreaEdit };
        // _item[`${name}`] = val;
        // setItemAreaEdit(_item);
        let _desc = { ...subs };
        _desc = e.target.value;
        setSubs(_desc);
    }


    const onDropDownChange = (e) => {
        // const val = (e.target && e.target.value) || '';
        let _clienteArea = { ...area };
        // _clienteArea[`${name}`] = val;
        // console.log(e.value, e.optionValue);
        _clienteArea['idarea'] = e.value;
        // _clienteArea['descricao'] = val.area;
        setTipoArea(_clienteArea);
    }

    const onKeyPressSubs = (e) => {
        console.log(e.keyCode);
        // if (e.key === "Enter")
        if (e.keyCode === 13) {
            addAreaClonada();
        }
    }


    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editItens(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteItem(rowData)} />
            </div>
        );
    }

    const itemBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <InputSwitch checked={rowData.ativo} />
                {/* //  onChange={(e) => setTipoArea(e.value)} /> */}
            </div>
        );
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaItens.length; i++) {
            if (listaItens[i].idsistemaconstrutivoitens === n) {
                index = i;
                break;
            }
        }
        return index;
    }
    const findIndexByIdInseridos = (n) => {
        let index = -1;
        for (let i = 0; i < listaItensInseridos.length; i++) {
            if (listaItensInseridos[i].idclientesareassistemaconstrutivoitens === n) {
                index = i;
                break;
            }
        }
        return index;
    }


    const addItem = (rowData) => {
        setLoading(true);
        let itemArea = emptyItemArea;
        let _listaItens = [...listaItens];
        let _listaItensInseridos = [...listaItensInseridos];

        itemArea.idclientesareassistemaconstrutivoitens = 0;
        itemArea.idclientesareas = area.idclientesareas;
        itemArea.idsistemaconstrutivoitens = rowData.idsistemaconstrutivoitens;
        itemArea.ativo = true;
        itemArea.descricao = rowData.descricao + ' - ' + (rowData.qtdinserida + 1);
        itemArea.sistemaconstrutivo = rowData.sistemaconstrutivo;

        api.post('/clientesareassistemaconstrutivoitens', itemArea).then(res => {
            if (res.status === 200) {

                itemArea.idclientesareassistemaconstrutivoitens = res.data.id;
                _listaItens[findIndexById(itemArea.idsistemaconstrutivoitens)].qtdinserida = (rowData.qtdinserida + 1);
                setListaItens(_listaItens);

                _listaItensInseridos.push(itemArea);
                setListaItensInseridos(_listaItensInseridos);
                setLoading(false)
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                setLoading(false)
                console.log(error);
            })

    }

    const clickClonar = () => {
        setClonarDialog(true);
        setdescClonada(area.descricao);
    }

    const getDescricao = () => {
        let x = '';
        x = areasClonadas.map((descricao) => {
            // console.log(descricao.descricao)
            // x.join(descricao.descricao)
            return descricao.descricao
        }).join('\n');
        return (x);
    }

    const addAreaClonada = () => {

        // let _area = emptyAreaClonada;
        // let _areasClonadas = [...areasClonadas];

        // _area.descricao = descClonada.replace('<>', subs);

        // _areasClonadas.push(_area);
        // setAreasClonadas(_areasClonadas);

        // subsRef.current.focus();
        // subsRef.current.select();


        let _listaClienteAreas = [...listaClienteAreas];
        let _areasClonadas = [...areasClonadasChips];

        if (_listaClienteAreas.some(list => list.descricao === (descClonada.replace('<>', subs)))) {
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Já existe uma área com esta descrição cadastrada para este cliente.', life: 3000 });
            subsRef.current.focus();
            subsRef.current.select();
        }
        else if (_areasClonadas.includes(descClonada.replace('<>', subs))) {
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Já existe uma área com esta descrição na lista a ser clonada.', life: 3000 });
            subsRef.current.focus();
            subsRef.current.select();
        }
        else {
            // let _area = emptyAreaClonada;

            // _area.descricao = descClonada.replace('<>', subs);
            _areasClonadas.push(descClonada.replace('<>', subs));
            setAreasClonadasChips(_areasClonadas);
            subsRef.current.focus();
            subsRef.current.select();
        }

    }

    const itemTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-plus" className="p-button p-button-primary p-mr-2" onClick={() => addItem(rowData)} />
            </div>
        );
    }

    const editItem = (rowData) => {
        let _item = { ...itemAreaEdit };
        let _listaItensInseridos = [...listaItensInseridos];

        api.put('/clientesareassistemaconstrutivoitens', _item).then(res => {
            if (res.status === 200) {
                _listaItensInseridos[findIndexByIdInseridos(_item.idclientesareassistemaconstrutivoitens)].descricao = _item.descricao;
                setListaItensInseridos(_listaItensInseridos);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Descrição alterada', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                console.log(error);
            })

        seteditItemDialog(false);
    }

    const deleteItem = () => {
        
        api.delete(`/clientesareassistemaconstrutivoitens/${itemAreaEdit.idclientesareassistemaconstrutivoitens}`).then(res => {
            if (res.status === 200) {
                let _itens = listaItensInseridos.filter(val => val.idclientesareassistemaconstrutivoitens !== itemAreaEdit.idclientesareassistemaconstrutivoitens);
                setListaItensInseridos(_itens);
                setDeleteItemDialog(false);
                setItemAreaEdit(emptyItemArea);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const clonar = () => {
        let _areas = {
            id: area.idclientesareas,
            area: area.descricao,
            areas: [
            ]
        };

        _areas.areas = areasClonadasChips;

        console.log(_areas);

        api.post('/clonararea', _areas).then(res => {
            if (res.status === 200) {
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Área clonada com sucesso.', life: 3000 });
                setClonarDialog(false);
                setAreasClonadasChips('');
                setSubs('');
            }
        }
        )
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu clonar a área.', life: 3000 });
                console.log(error.response.data);
            })


    }

    const oneditItemDialog = (rowData) => {
        seteditItemDialog(true);
        let _itemInserido = listaItensInseridos[findIndexByIdInseridos(rowData.idclientesareassistemaconstrutivoitens)];
        // console.log(_itemInserido);
        setItemAreaEdit(_itemInserido);
        // console.log(listaItensInseridos[findIndexByIdInseridos(rowData.idclientesareassistemaconstrutivoitens)])
        // console.log(_itemInserido.descricao);
        //carregar item
    }

    const onDeleteItemDialog = (rowData) => {
        setDeleteItemDialog(true);
        let _itemInserido = listaItensInseridos[findIndexByIdInseridos(rowData.idclientesareassistemaconstrutivoitens)];
        setItemAreaEdit(_itemInserido);
    }

    const itemEditTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => oneditItemDialog(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => onDeleteItemDialog(rowData)} />
            </div>
        );
    }


    const hideAddItemDialog = () => {
        setSubmitted(false);
        setAddItemDialog(false);
    }

    const hideDeleteItemDialog = () => {
        setDeleteItemDialog(false)
    }

    const hideEditItemDialog = () => {
        // setSubmitted(false);
        seteditItemDialog(false);
    }

    const hideClonarDialog = () => {
        // setSubmitted(false);
        setClonarDialog(false);
        setAreasClonadas([]);
        setSubs('');
        setdescClonada('');
        setAreasClonadasChips('');
    }

    const addItemDialogFooter = (
        <>
            <Button label="Fechar" icon="pi pi-times" className="p-button-text" onClick={hideAddItemDialog} />
            {/* <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={addItem} /> */}
        </>
    );

    const editItemDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideEditItemDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={editItem} />
        </>
    );

    const clonarDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideClonarDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={clonar} />
        </>
    );

    const deleItemDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteItemDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteItem} />
        </>
    );

    const headerItem = () => {
        return (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                </span>
            </div>
        );
    }

    return (

        <div className="p-grid crud-demo">
            <div className="content-section introduction">
                <h5>Área - {area.area}</h5>
            </div>
            <div className="p-col-12">
                <div className="card">

                    <Toast ref={toast} />

                    <Panel header={'Área'}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-2" >
                                <label htmlFor="idarea">Id Área</label>
                                <InputText id="idarea" value={area.idclientesareas} readOnly={true} disabled={true} />
                            </div>
                            <div className="p-field p-col-6">
                                {/* <label htmlFor="area">Descrição</label> */}
                                <label htmlFor="area">Tipo de Área</label>
                                {/* <Dropdown id="area" value={area.area} options={listaTipoAreas} onChange={(e) => onInputChange(e, 'area')}
                                    optionLabel="area" placeholder="Selecione" required autoFocus className={classNames({ 'p-invalid': submitted && !area.area })} />
                                {submitted && !area.area && <small className="p-invalid">Tipo de Área é requerido.</small>} */}
                                <Dropdown id="area" inputId='idarea' optionValue='idarea' optionLabel='area' value={area.idarea} options={listaTipoAreas}
                                    onChange={(e) => onDropDownChange(e)}
                                    placeholder="Selecione" required autoFocus className={classNames({ 'p-invalid': submitted && !area.idarea })} />
                                {submitted && !area.idarea && <small className="p-invalid">Tipo de Área é requerido.</small>}

                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="area">Descrição</label>
                                <InputText id="area" value={area.descricao} onChange={(e) => onInputChange(e, 'descricao')} required autoFocus
                                    className={classNames({ 'p-invalid': submitted && !area.descricao })} />
                                {submitted && !area.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                            </div>
                            <div className="p-d-flex" >
                                <label htmlFor="area" className="p-ml-2 p-mr-2 p-as-center" >Ativo</label>
                                <InputSwitch checked={area.ativo}
                                    // onChange={(e) => setTipoArea(e.value)} 
                                    className="p-mr-2 p-as-center" />

                            </div>
                            {submitted && !area.area && <small className="p-invalid">Ativo é requerido.</small>}
                        </div>

                        <br />
                        <div>
                            <React.Fragment>
                                <div className="p-d-flex p-jc-between p-mt-1">
                                    <div><Button label="Clonar" icon="pi pi-copy" className="p-button" onClick={clickClonar} /></div>
                                    <div>
                                        {/* <Button label="Editar" icon="pi pi-pencil" className="p-button-warning p-mr-2" onClick={addItem} /> */}
                                        <Voltar page={"clienteengenharia/" + idCliente} ></Voltar>
                                        {/* <Voltar page={"clientesengenharia"} ></Voltar> */}
                                        <Button label="Salvar" icon="pi pi-check" className="p-button-success" onClick={addItem} />
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>
                    </Panel>
                    <br />
                    <Panel header={"Item"} /*header={headerItemDialog}*/>
                        <div>
                            <React.Fragment>
                                <div className="p-d-flex p-jc-between p-py-2">
                                    <div><Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={showAddItemDialog} /></div>
                                    <div>
                                        <span className="p-input-icon-left p-mr-2">
                                            <i className="pi pi-search" />
                                            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Localizar..." />
                                        </span>
                                    </div>
                                </div>
                            </React.Fragment>
                        </div>

                        <DataTable
                            ref={dt} value={listaItensInseridos} dataKey="idclientesareassistemaconstrutivoitens"
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive p-datatable-customers"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Itens" globalFilter={globalFilter}
                            emptyMessage="Nenhum Item encontrado." sortMode="multiple" >
                            <Column field="idclientesareassistemaconstrutivoitens" header="Id" style={{ width: '6%' }} />
                            <Column field="descricao" header="Item" style={{ width: '50%' }}></Column>
                            <Column field="sistemaconstrutivo" header="Sistema Construtivo" style={{ width: '30%' }} ></Column>
                            <Column body={itemBodyTemplate} className="p-text-center" style={{ width: '7%' }}></Column>
                            <Column body={itemEditTemplate} className="p-text-center" style={{ width: '7%' }}></Column>
                        </DataTable>
                    </Panel>
                    <br />


                    <Dialog visible={addItemDialog} style={{ width: '60%' }} header="Item" modal className="p-fluid" footer={addItemDialogFooter} onHide={hideAddItemDialog}>
                        <DataTable
                            ref={dt} value={listaItens} dataKey="iditem"
                            paginator rows={8} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive p-datatable-customers"
                            globalFilter={globalFilter}
                            loading={loading}
                            emptyMessage="Nenhum Item encontrado." sortMode="multiple" >
                            <Column field="idsistemaconstrutivoitens" header="Id" sortable filter filterPlaceholder="Id" style={{ width: '8%' }}
                                filterMatchMode="contains" />
                            <Column field="descricao" header="Item" sortable filter filterPlaceholder="pesquisar Item" style={{ width: '50%' }}
                                filterMatchMode="contains"></Column>
                            <Column field="sistemaconstrutivo" header="Sistema Construtivo" sortable filter filterPlaceholder="pesquisar Sistema Contrutivo"
                                style={{ width: '30%' }} filterMatchMode="contains" ></Column>
                            <Column field="qtdinserida" header="Qtd." sortable style={{ width: '10%' }} ></Column>
                            <Column body={itemTemplate} className="p-text-center" style={{ width: '10%' }}></Column>
                        </DataTable>
                    </Dialog>

                    <Dialog visible={editItemDialog} style={{ width: '40%' }} header="Item" modal className="p-fluid" footer={editItemDialogFooter} onHide={hideEditItemDialog}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="desc">Descrição</label>
                                <InputText id="desc" value={itemAreaEdit.descricao}
                                    onChange={(e) => onInputChangeEdit(e, 'descricao')}
                                />
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={clonarDialog} style={{ width: '40%' }} header="Clonar" modal className="p-fluid" footer={clonarDialogFooter} onHide={hideClonarDialog}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="desc">Descrição</label>
                                <InputText id="desc" value={descClonada}
                                    onChange={(e) => onInputChangeClonadaEdit(e)}
                                />
                            </div>
                            <div className="p-field p-col-3">
                                <label htmlFor="subs">Valor</label>
                                <InputText id="subs" value={subs}
                                    onChange={(e) => onInputChangeSubs(e)}
                                    ref={subsRef}
                                />
                            </div>
                            {/* <div className="p-field p-sm-4 p-md-3 p-lg-2"> */}
                            <div className="p-col-fixed" style={{ width: '80px' }}>
                                {/* <label htmlFor="add">a</label> */}
                                <Button id="add" label="Add" icon="pi pi-plus" className="p-button-primary"
                                    onClick={addAreaClonada} tooltip="Acrescente os caracteres <> ao campo Descrição para que eles sejam automaticamente substituídos pelo conteúdo do campo Valor. Ao Terminar clique em Salvar para criar as novas áreas."
                                    ooltipOptions={{ position: 'top' }}
                                    style={{ marginTop: '1.8rem' }}
                                />
                            </div>
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="newareas">Áreas</label>
                                {/* <InputTextarea id="newareas"
                                    // value={teste}  {this.state.films.map(r=>r).join(",")}                                  
                                    // value={areasClonadas.map(r => r).join("\n")}
                                    value={getDescricao()}
                                    onChange={(e) => onTextAreaChange(e)}
                                    // onKeyPress={(e) => onKeyPressSubs(e)}
                                    autoResize rows={5}
                                // onChange={(e) => onInputChangeEdit(e, 'descricao')}
                                /> */}
                                <Chips value={areasClonadasChips} onChange={(e) => setAreasClonadasChips(e.value)}></Chips>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteItemDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleItemDialogFooter} onHide={hideDeleteItemDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {itemAreaEdit && <span>Tem certeza que deseja excluir <b>{itemAreaEdit.descricao}</b>?</span>}
                        </div>
                    </Dialog>


                </div>
            </div>
        </div>
    );
}
