import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';

export const TipoStatusNavegacao = () => {

  let emptyTipoStatusNavegacao = {
    idtipostatus: null,
    idtipostatusnavegacao: null,
  };

  const [tipostatusnavegacao, setTipoStatusNavegacao] = useState(emptyTipoStatusNavegacao);
  const [listaTipoStatusNavegacao, setListaTipoStatusNavegacao] = useState(null);
  const [tipostatusnavegacaoDialog, setTipoStatusNavegacaoDialog] = useState(false);
  const [deleteTipoStatusNavegacaoDialog, setDeleteTipoStatusNavegacaoDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
      api.get(`/tipostatusnavegacao`).then((res) => {
          setListaTipoStatusNavegacao(res.data);
      });
  }, []);

  const openNew = () => {
    setTipoStatusNavegacao(emptyTipoStatusNavegacao);
    setSubmitted(false);
    setTipoStatusNavegacaoDialog(true);
  }

  const saveTipoStatusNavegacao = () => {
    setSubmitted(true);
    if (tipostatusnavegacao.idtipostatusnavegacao.trim()) {
        let _listaTipoStatusNavegacao = [...listaTipoStatusNavegacao];
        let _tipostatusnavegacao = { ...tipostatusnavegacao };
        if (_tipostatusnavegacao.idtipostatus){
            const index = findIndexById(_tipostatusnavegacao.idtipostatus);
            api.put('/tipostatusnavegacao', _tipostatusnavegacao).then(res => {
                if (res.status === 200) {
                    _listaTipoStatusNavegacao[index] = _tipostatusnavegacao;
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                })
        }
        else {
            _tipostatusnavegacao.idtipostatus = 0;
            api.post('/tipostatusnavegacao',_tipostatusnavegacao).then(res => {
                if (res.status === 200) {
                    _tipostatusnavegacao.idtipostatus = res.data.idtipostatus;
                    _listaTipoStatusNavegacao.push(_tipostatusnavegacao);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                console.log(error);
            })
        }
        setListaTipoStatusNavegacao(_listaTipoStatusNavegacao);
        setTipoStatusNavegacaoDialog(false);
        setTipoStatusNavegacao(emptyTipoStatusNavegacao);
    }
  }

  const editTipoStatusNavegacao = (tipostatusnavegacao) => {
      setTipoStatusNavegacao({ ...tipostatusnavegacao});
      setTipoStatusNavegacaoDialog(true);
  }

  const deleteTipoStatusNavegacao= () => {
      api.delete(`/tipostatusnavegacao/${tipostatusnavegacao.idtipostatus}`).then(res => {
          if (res.status === 200){
              let _tipostatusnavegacao= listaTipoStatusNavegacao.filter(val => val.idtipostatus !== tipostatusnavegacao.idtipostatus);
              setListaTipoStatusNavegacao(_tipostatusnavegacao);
              setDeleteTipoStatusNavegacaoDialog(false);
              setTipoStatusNavegacao(emptyTipoStatusNavegacao);
              toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
          }
      })
      .catch(error => {
          toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
          console.log(error);
      })
  }

  const confirmDeleteTipoStatusNavegacao= (tipostatusnavegacao) => {
      setTipoStatusNavegacao(tipostatusnavegacao);
      setDeleteTipoStatusNavegacaoDialog(true);
  }

  const hideDialog = () => {
      setSubmitted(false);
      setTipoStatusNavegacaoDialog(false);
  }

  const hideDeleteTipoStatusNavegacaoDialog = () => {
      setDeleteTipoStatusNavegacaoDialog(false);
  }

  const findIndexById = (n) => {
      let index = -1;
      for (let i = 0; i < listaTipoStatusNavegacao.length; i++) {
          if (listaTipoStatusNavegacao[i].idtipostatus === n) {
              index = i;
              break;
          }
      }
      return index;
  }

  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';
      let _tipostatusnavegacao = { ...tipostatusnavegacao};
      _tipostatusnavegacao[`${name}`] = val;
      setTipoStatusNavegacao(_tipostatusnavegacao);
  }

  const leftToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
          </React.Fragment>
      )
  }

  const exportExcel = () => {
      import('xlsx').then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(listaTipoStatusNavegacao);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          saveAsExcelFile(excelBuffer, 'listaTipoStatusNavegacao');
      });
  }

  const saveAsExcelFile = (buffer, fileName) => {
      import('file-saver').then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  const rightToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
          </React.Fragment>
      )
  }

  const actionBodyTemplate = (rowData) => {
      return (
          <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editTipoStatusNavegacao(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteTipoStatusNavegacao(rowData)} />
          </div>
      );
  }

  const header = (
      <div className="table-header">
          <h5 className="p-m-0 p-text-bold">Status de Navegação</h5>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
          </span>
      </div>
  );

  const tipostatusnavegacaoDialogFooter = (
      <>
          <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
          <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveTipoStatusNavegacao} />
      </>
  );

  const deleteTipoStatusNavegacaoDialogFooter = (
      <>
          <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTipoStatusNavegacaoDialog} />
          <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteTipoStatusNavegacao} />
      </>
  );

  return (
      <div className="p-grid crud-demo">
          <div className="p-col-12">
              <div className="card">

                  <Toast ref={toast} />
                  <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                  <DataTable
                      ref={dt} value={listaTipoStatusNavegacao} dataKey="idtipostatus"
                      paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Tipos de Status de Navegação" globalFilter={globalFilter}
                      emptyMessage="Nenhuma TipoStatusNavegacao encontrada." header={header}>
                      <Column field="idtipostatus" header="idtipostatus" sortable ></Column>
                      <Column field="idtipostatusnavegacao" header="idtipostatusnavegacao" sortable ></Column>
                      <Column body={actionBodyTemplate}></Column>
                  </DataTable>
                  <Dialog visible={tipostatusnavegacaoDialog} style={{ width: '450px' }} header="Tipo de Status de Navegação" modal className="p-fluid" footer={tipostatusnavegacaoDialogFooter} onHide={hideDialog}>
                      <div className="p-field">
                          <label htmlFor="idtipostatus">idtipostatus</label>
                          <InputText id="idtipostatus" value={tipostatusnavegacao.idtipostatus} onChange={(e) => onInputChange(e, 'tipostatusnavegacao')} required autoFocus className={classNames({ 'p-invalid': submitted && !tipostatusnavegacao.idtipostatus})} />
                          {submitted && !tipostatusnavegacao.idtipostatus && <small className="p-invalid">IdTipoStatus é requerido.</small>}
                      </div>
                      <div className="p-field">
                          <label htmlFor="idtipostatusnavegacao">idtipostatusnavegacao</label>
                          <InputText id="idtipostatusnavegacao" value={tipostatusnavegacao.idtipostatusnavegacao} onChange={(e) => onInputChange(e, 'tipostatusnavegacao')} required autoFocus className={classNames({ 'p-invalid': submitted && !tipostatusnavegacao.idtipostatusnavegacao})} />
                          {submitted && !tipostatusnavegacao.idtipostatusnavegacao && <small className="p-invalid">IdTipoStatusNavegacao é requerido.</small>}
                      </div>
                  </Dialog>
                  <Dialog visible={deleteTipoStatusNavegacaoDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteTipoStatusNavegacaoDialogFooter} onHide={hideDeleteTipoStatusNavegacaoDialog}>
                      <div className="confirmation-content">
                          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                          {tipostatusnavegacao && <span>Tem certeza que deseja excluir <b>{tipostatusnavegacao.idtipostatusnavegacao}</b>?</span>}
                      </div>
                  </Dialog>
              </div>
          </div>
      </div>
  );
}
