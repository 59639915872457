import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { Badge } from '../components/badge/Badge';
import { ChromePicker, SketchPicker } from 'react-color';

export const Gravidade = () => {

    let emptyGravidade = {
        idgravidade: null,
        gravidade: '',
        corhexa: '',
    };

    const [gravidade, setGravidade] = useState(emptyGravidade);
    const [listaGravidade, setListaGravidade] = useState(null);
    const [gravidadeDialog, setGravidadeDialog] = useState(false);
    const [deleteGravidadeDialog, setDeleteGravidadeDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);    
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/gravidade`).then((res) => {
            setListaGravidade(res.data);
        });
    }, []);

    const openNew = () => {
        setGravidade(emptyGravidade);
        setSubmitted(false);
        setGravidadeDialog(true);
    }

    const saveGravidade = () => {
        setSubmitted(true);
        if (gravidade.gravidade.trim()) {
            let _listaGravidade = [...listaGravidade];
            let _gravidade = { ...gravidade };
            if (_gravidade.idgravidade) {
                const index = findIndexById(_gravidade.idgravidade);
                api.put('/gravidade', _gravidade).then(res => {
                    if (res.status === 200) {
                        _listaGravidade[index] = _gravidade;
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                        setListaGravidade(_listaGravidade);
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _gravidade.idgravidade = 0;
                api.post('/gravidade', _gravidade).then(res => {
                    if (res.status === 200) {
                        _gravidade.idgravidade = res.data.id;//parseInt(res.data.id);
                        _listaGravidade.push(_gravidade);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                        setListaGravidade(_listaGravidade);
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            console.log(_listaGravidade);
            // setListaGravidade(_listaGravidade);
            setGravidadeDialog(false);
            setGravidade(emptyGravidade);
        }
    }

    const editGravidade = (gravidade) => {
        setGravidade({ ...gravidade });
        setGravidadeDialog(true);
    }

    const deleteGravidade = () => {
        api.delete(`/gravidade/${gravidade.idgravidade}`).then(res => {
            if (res.status === 200) {
                let _gravidade = listaGravidade.filter(val => val.idgravidade !== gravidade.idgravidade);
                setListaGravidade(_gravidade);
                setDeleteGravidadeDialog(false);
                setGravidade(emptyGravidade);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteGravidade = (gravidade) => {
        setGravidade(gravidade);
        setDeleteGravidadeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setGravidadeDialog(false);
    }

    const hideDeleteGravidadeDialog = () => {
        setDeleteGravidadeDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaGravidade.length; i++) {
            if (listaGravidade[i].idgravidade === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _gravidade = { ...gravidade };
        _gravidade[`${name}`] = val;
        setGravidade(_gravidade);
    }

    const handleChangeComplete = (color) => {
        let _gravidade = { ...gravidade };
        _gravidade.corhexa = color.hex;
        setGravidade(_gravidade);
      };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaGravidade);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaGravidade');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editGravidade(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteGravidade(rowData)} />
            </div>
        );
    }

    const corTemplate = (rowData) => {
        return (<Badge className="product-badge" size="large" style={{ background: `${rowData.corhexa}` }} ></Badge>);
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Gravidade</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const gravidadeDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveGravidade} />
        </>
    );

    const deleteGravidadeDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteGravidadeDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteGravidade} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt} value={listaGravidade} dataKey="idgravidade"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Gravidade" globalFilter={globalFilter}
                        emptyMessage="Nenhuma Gravidade encontrada." header={header}>
                        <Column field="idgravidade" header="Id" style={{ width: '6%' }} sortable />
                        <Column field="gravidade" header="Gravidade" sortable ></Column>
                        <Column field="corhexa" header="Cor" body={corTemplate} style={{ width: '6%' }} ></Column>
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }}></Column>
                    </DataTable>
                    <Dialog visible={gravidadeDialog} style={{ width: '450px' }} header="Gravidade" modal className="p-fluid" footer={gravidadeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="gravidade">Gravidade</label>
                            <InputText id="gravidade" value={gravidade.gravidade} onChange={(e) => onInputChange(e, 'gravidade')} required autoFocus className={classNames({ 'p-invalid': submitted && !gravidade.gravidade })} />
                            {submitted && !gravidade.gravidade && <small className="p-invalid">Gravidade é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="corhexa">Cor</label>
                            {/* <InputText id="corhexa" value={gravidade.corhexa} onChange={(e) => onInputChange(e, 'corhexa')} required className={classNames({ 'p-invalid': submitted && !gravidade.corhexa })} />
                            {submitted && !gravidade.corhexa && <small className="p-invalid">CorHexa é requerido.</small>} */}                            
                            <ChromePicker id="corhexa"
                            disableAlpha  
                            color={gravidade.corhexa}
                            onChangeComplete={handleChangeComplete}
                            />
                        </div>
                    </Dialog>
                    <Dialog visible={deleteGravidadeDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteGravidadeDialogFooter} onHide={hideDeleteGravidadeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {gravidade && <span>Tem certeza que deseja excluir <b>{gravidade.gravidade}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
