import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { Badge } from '../components/badge/Badge';
import { ChromePicker, SketchPicker } from 'react-color';

export const TipoAtividade = () => {

    let emptyTipoAtividade = {
        idtipoatividade: null,
        tipoatividade: '',
        cor: '',
        corhexa: '',
    };

    const [tipoatividade, setTipoAtividade] = useState(emptyTipoAtividade);
    const [listaTipoAtividade, setListaTipoAtividade] = useState(null);
    const [tipoatividadeDialog, setTipoAtividadeDialog] = useState(false);
    const [deleteTipoAtividadeDialog, setDeleteTipoAtividadeDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/tipoatividade`).then((res) => {
            setListaTipoAtividade(res.data);
        });
    }, []);

    const openNew = () => {
        setTipoAtividade(emptyTipoAtividade);
        setSubmitted(false);
        setTipoAtividadeDialog(true);
    }

    const saveTipoAtividade = () => {
        setSubmitted(true);
        if (tipoatividade.tipoatividade.trim()) {
            let _listaTipoAtividade = [...listaTipoAtividade];
            let _tipoatividade = { ...tipoatividade };
            if (_tipoatividade.idtipoatividade) {
                const index = findIndexById(_tipoatividade.idtipoatividade);
                api.put('/tipoatividade', _tipoatividade).then(res => {
                    if (res.status === 200) {
                        _listaTipoAtividade[index] = _tipoatividade;
                        setListaTipoAtividade(_listaTipoAtividade);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _tipoatividade.idtipoatividade = 0;
                api.post('/tipoatividade', _tipoatividade).then(res => {
                    if (res.status === 200) {
                        _tipoatividade.idtipoatividade = res.data.id;
                        _listaTipoAtividade.push(_tipoatividade);
                        setListaTipoAtividade(_listaTipoAtividade);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            // setListaTipoAtividade(_listaTipoAtividade);
            setTipoAtividadeDialog(false);
            setTipoAtividade(emptyTipoAtividade);
        }
    }

    const editTipoAtividade = (tipoatividade) => {
        setTipoAtividade({ ...tipoatividade });
        setTipoAtividadeDialog(true);
    }

    const deleteTipoAtividade = () => {
        api.delete(`/tipoatividade/${tipoatividade.idtipoatividade}`).then(res => {
            if (res.status === 200) {
                let _tipoatividade = listaTipoAtividade.filter(val => val.idtipoatividade !== tipoatividade.idtipoatividade);
                setListaTipoAtividade(_tipoatividade);
                setDeleteTipoAtividadeDialog(false);
                setTipoAtividade(emptyTipoAtividade);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteTipoAtividade = (tipoatividade) => {
        setTipoAtividade(tipoatividade);
        setDeleteTipoAtividadeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTipoAtividadeDialog(false);
    }

    const hideDeleteTipoAtividadeDialog = () => {
        setDeleteTipoAtividadeDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaTipoAtividade.length; i++) {
            if (listaTipoAtividade[i].idtipoatividade === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _tipoatividade = { ...tipoatividade };
        _tipoatividade[`${name}`] = val;
        setTipoAtividade(_tipoatividade);
    }

    const handleChangeComplete = (color) => {
        let _tipoatividade = { ...tipoatividade };
        _tipoatividade.corhexa = color.hex;
        setTipoAtividade(_tipoatividade);
    };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaTipoAtividade);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaTipoAtividade');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editTipoAtividade(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteTipoAtividade(rowData)} />
            </div>
        );
    }

    const corTemplate = (rowData) => {
        return (<Badge className="product-badge" size="large" style={{ background: `${rowData.corhexa}` }} ></Badge>);
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Tipo de Atividade</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const tipoatividadeDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveTipoAtividade} />
        </>
    );

    const deleteTipoAtividadeDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTipoAtividadeDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteTipoAtividade} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt} value={listaTipoAtividade} dataKey="idtipoatividade"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Tipos de Atividade" globalFilter={globalFilter}
                        emptyMessage="Nenhum Tipo de Atividade encontrado." header={header} /*sortField="idtipoatividade" sortOrder={1}*/>
                        <Column field="idtipoatividade" header="Id" sortable style={{ width: '6%' }} />
                        <Column field="tipoatividade" header="Descrição" sortable ></Column>
                        <Column field="corhexa" header="Cor" body={corTemplate} className="p-text-center" style={{ width: '6%' }} ></Column>
                        <Column body={actionBodyTemplate} /*className="p-text-center"*/ style={{ width: '12%' }} ></Column>
                    </DataTable>
                    <Dialog visible={tipoatividadeDialog} style={{ width: '450px' }} header="Tipo de Atividade" modal className="p-fluid" footer={tipoatividadeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="tipoatividade">Descrição</label>
                            <InputText id="tipoatividade" value={tipoatividade.tipoatividade} onChange={(e) => onInputChange(e, 'tipoatividade')} required autoFocus className={classNames({ 'p-invalid': submitted && !tipoatividade.tipoatividade })} />
                            {submitted && !tipoatividade.tipoatividade && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="corhexa">Cor Hexadecimal</label>
                            {/* <InputText id="corhexa" value={tipoatividade.corhexa} onChange={(e) => onInputChange(e, 'corhexa')} required className={classNames({ 'p-invalid': submitted && !tipoatividade.corhexa })} />
                            {submitted && !tipoatividade.corhexa && <small className="p-invalid">Cor Hexadecimal é requerido.</small>} */}
                            <ChromePicker id="corhexa"
                                disableAlpha
                                color={tipoatividade.corhexa}
                                onChangeComplete={handleChangeComplete}
                            />
                        </div>
                    </Dialog>
                    <Dialog visible={deleteTipoAtividadeDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteTipoAtividadeDialogFooter} onHide={hideDeleteTipoAtividadeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {tipoatividade && <span>Tem certeza que deseja excluir <b>{tipoatividade.tipoatividade}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
