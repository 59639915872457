import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';

export const TipoCliente = () => {

  let emptyTipoCliente = {
    idtipocliente: null,
    tipocliente: '',
  };

  const [tipocliente, setTipoCliente] = useState(emptyTipoCliente);
  const [listaTipoCliente, setListaTipoCliente] = useState(null);
  const [tipoclienteDialog, setTipoClienteDialog] = useState(false);
  const [deleteTipoClienteDialog, setDeleteTipoClienteDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
      api.get(`/tipocliente`).then((res) => {
          setListaTipoCliente(res.data);
      });
  }, []);

  const openNew = () => {
    setTipoCliente(emptyTipoCliente);
    setSubmitted(false);
    setTipoClienteDialog(true);
  }

  const saveTipoCliente = () => {
    setSubmitted(true);
    if (tipocliente.tipocliente.trim()) {
        let _listaTipoCliente = [...listaTipoCliente];
        let _tipocliente = { ...tipocliente };
        if (_tipocliente.idtipocliente){
            const index = findIndexById(_tipocliente.idtipocliente);
            api.put('/tipocliente', _tipocliente).then(res => {
                if (res.status === 200) {
                    _listaTipoCliente[index] = _tipocliente;
                    setListaTipoCliente(_listaTipoCliente);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                })
        }
        else {
            _tipocliente.idtipocliente = 0;
            api.post('/tipocliente',_tipocliente).then(res => {
                if (res.status === 200) {
                    _tipocliente.idtipocliente = res.data.id;
                    _listaTipoCliente.push(_tipocliente);
                    setListaTipoCliente(_listaTipoCliente);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                console.log(error);
            })
        }
        // setListaTipoCliente(_listaTipoCliente);
        setTipoClienteDialog(false);
        setTipoCliente(emptyTipoCliente);
    }
  }

  const editTipoCliente = (tipocliente) => {
      setTipoCliente({ ...tipocliente});
      setTipoClienteDialog(true);
  }

  const deleteTipoCliente= () => {
      api.delete(`/tipocliente/${tipocliente.idtipocliente}`).then(res => {
          if (res.status === 200){
              let _tipocliente= listaTipoCliente.filter(val => val.idtipocliente !== tipocliente.idtipocliente);
              setListaTipoCliente(_tipocliente);
              setDeleteTipoClienteDialog(false);
              setTipoCliente(emptyTipoCliente);
              toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
          }
      })
      .catch(error => {
          toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
          console.log(error);
      })
  }

  const confirmDeleteTipoCliente= (tipocliente) => {
      setTipoCliente(tipocliente);
      setDeleteTipoClienteDialog(true);
  }

  const hideDialog = () => {
      setSubmitted(false);
      setTipoClienteDialog(false);
  }

  const hideDeleteTipoClienteDialog = () => {
      setDeleteTipoClienteDialog(false);
  }

  const findIndexById = (n) => {
      let index = -1;
      for (let i = 0; i < listaTipoCliente.length; i++) {
          if (listaTipoCliente[i].idtipocliente === n) {
              index = i;
              break;
          }
      }
      return index;
  }

  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';
      let _tipocliente = { ...tipocliente};
      _tipocliente[`${name}`] = val;
      setTipoCliente(_tipocliente);
  }

  const leftToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
          </React.Fragment>
      )
  }

  const exportExcel = () => {
      import('xlsx').then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(listaTipoCliente);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          saveAsExcelFile(excelBuffer, 'listaTipoCliente');
      });
  }

  const saveAsExcelFile = (buffer, fileName) => {
      import('file-saver').then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  const rightToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
          </React.Fragment>
      )
  }

  const actionBodyTemplate = (rowData) => {
      return (
          <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editTipoCliente(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteTipoCliente(rowData)} />
          </div>
      );
  }

  const header = (
      <div className="table-header">
          <h5 className="p-m-0 p-text-bold">Tipo de Cliente</h5>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
          </span>
      </div>
  );

  const tipoclienteDialogFooter = (
      <>
          <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
          <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveTipoCliente} />
      </>
  );

  const deleteTipoClienteDialogFooter = (
      <>
          <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTipoClienteDialog} />
          <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteTipoCliente} />
      </>
  );

  return (
      <div className="p-grid crud-demo">
          <div className="p-col-12">
              <div className="card">

                  <Toast ref={toast} />
                  <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                  <DataTable
                      ref={dt} value={listaTipoCliente} dataKey="idtipocliente"
                      paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Tipos de Cliente" globalFilter={globalFilter}
                      emptyMessage="Nenhum Tipo de Cliente encontrado." header={header}>
                      <Column field="idtipocliente" header="Id" style={{ width: '6%' }} sortable />
                      <Column field="tipocliente" header="Descrição" sortable ></Column>
                      <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                  </DataTable>
                  <Dialog visible={tipoclienteDialog} style={{ width: '450px' }} header="Tipo de Cliente" modal className="p-fluid" footer={tipoclienteDialogFooter} onHide={hideDialog}>
                      <div className="p-field">
                          <label htmlFor="tipocliente">Descrição</label>
                          <InputText id="tipocliente" value={tipocliente.tipocliente} onChange={(e) => onInputChange(e, 'tipocliente')} required autoFocus className={classNames({ 'p-invalid': submitted && !tipocliente.tipocliente})} />
                          {submitted && !tipocliente.tipocliente && <small className="p-invalid">Descrição é requerido.</small>}
                      </div>
                  </Dialog>
                  <Dialog visible={deleteTipoClienteDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteTipoClienteDialogFooter} onHide={hideDeleteTipoClienteDialog}>
                      <div className="confirmation-content">
                          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                          {tipocliente && <span>Tem certeza que deseja excluir <b>{tipocliente.tipocliente}</b>?</span>}
                      </div>
                  </Dialog>
              </div>
          </div>
      </div>
  );
}
