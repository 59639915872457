import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';

export const TipoRespostaCheckList = () => {

  let emptyTipoRespostaCheckList = {
    idtiporespostachecklist: null,
    tiporespostachecklist: '',
  };

  const [tiporespostachecklist, setTipoRespostaCheckList] = useState(emptyTipoRespostaCheckList);
  const [listaTipoRespostaCheckList, setListaTipoRespostaCheckList] = useState(null);
  const [tiporespostachecklistDialog, setTipoRespostaCheckListDialog] = useState(false);
  const [deleteTipoRespostaCheckListDialog, setDeleteTipoRespostaCheckListDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
      api.get(`/tiporespostachecklist`).then((res) => {
          setListaTipoRespostaCheckList(res.data);
      });
  }, []);

  const openNew = () => {
    setTipoRespostaCheckList(emptyTipoRespostaCheckList);
    setSubmitted(false);
    setTipoRespostaCheckListDialog(true);
  }

  const saveTipoRespostaCheckList = () => {
    setSubmitted(true);
    if (tiporespostachecklist.tiporespostachecklist.trim()) {
        let _listaTipoRespostaCheckList = [...listaTipoRespostaCheckList];
        let _tiporespostachecklist = { ...tiporespostachecklist };
        if (_tiporespostachecklist.idtiporespostachecklist){
            const index = findIndexById(_tiporespostachecklist.idtiporespostachecklist);
            api.put('/tiporespostachecklist', _tiporespostachecklist).then(res => {
                if (res.status === 200) {
                    _listaTipoRespostaCheckList[index] = _tiporespostachecklist;
                    setListaTipoRespostaCheckList(_listaTipoRespostaCheckList);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                })
        }
        else {
            _tiporespostachecklist.idtiporespostachecklist = 0;
            api.post('/tiporespostachecklist',_tiporespostachecklist).then(res => {
                if (res.status === 200) {
                    _tiporespostachecklist.idtiporespostachecklist = res.data.id;
                    _listaTipoRespostaCheckList.push(_tiporespostachecklist);
                    setListaTipoRespostaCheckList(_listaTipoRespostaCheckList);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                console.log(error);
            })
        }
        // setListaTipoRespostaCheckList(_listaTipoRespostaCheckList);
        setTipoRespostaCheckListDialog(false);
        setTipoRespostaCheckList(emptyTipoRespostaCheckList);
    }
  }

  const editTipoRespostaCheckList = (tiporespostachecklist) => {
      setTipoRespostaCheckList({ ...tiporespostachecklist});
      setTipoRespostaCheckListDialog(true);
  }

  const deleteTipoRespostaCheckList= () => {
      api.delete(`/tiporespostachecklist/${tiporespostachecklist.idtiporespostachecklist}`).then(res => {
          if (res.status === 200){
              let _tiporespostachecklist= listaTipoRespostaCheckList.filter(val => val.idtiporespostachecklist !== tiporespostachecklist.idtiporespostachecklist);
              setListaTipoRespostaCheckList(_tiporespostachecklist);
              setDeleteTipoRespostaCheckListDialog(false);
              setTipoRespostaCheckList(emptyTipoRespostaCheckList);
              toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
          }
      })
      .catch(error => {
          toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
          console.log(error);
      })
  }

  const confirmDeleteTipoRespostaCheckList= (tiporespostachecklist) => {
      setTipoRespostaCheckList(tiporespostachecklist);
      setDeleteTipoRespostaCheckListDialog(true);
  }

  const hideDialog = () => {
      setSubmitted(false);
      setTipoRespostaCheckListDialog(false);
  }

  const hideDeleteTipoRespostaCheckListDialog = () => {
      setDeleteTipoRespostaCheckListDialog(false);
  }

  const findIndexById = (n) => {
      let index = -1;
      for (let i = 0; i < listaTipoRespostaCheckList.length; i++) {
          if (listaTipoRespostaCheckList[i].idtiporespostachecklist === n) {
              index = i;
              break;
          }
      }
      return index;
  }

  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';
      let _tiporespostachecklist = { ...tiporespostachecklist};
      _tiporespostachecklist[`${name}`] = val;
      setTipoRespostaCheckList(_tiporespostachecklist);
  }

  const leftToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
          </React.Fragment>
      )
  }

  const exportExcel = () => {
      import('xlsx').then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(listaTipoRespostaCheckList);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          saveAsExcelFile(excelBuffer, 'listaTipoRespostaCheckList');
      });
  }

  const saveAsExcelFile = (buffer, fileName) => {
      import('file-saver').then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  const rightToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
          </React.Fragment>
      )
  }

  const actionBodyTemplate = (rowData) => {
      return (
          <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editTipoRespostaCheckList(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteTipoRespostaCheckList(rowData)} />
          </div>
      );
  }

  const header = (
      <div className="table-header">
          <h5 className="p-m-0 p-text-bold">Tipo de Resposta do Checklist</h5>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
          </span>
      </div>
  );

  const tiporespostachecklistDialogFooter = (
      <>
          <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
          <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveTipoRespostaCheckList} />
      </>
  );

  const deleteTipoRespostaCheckListDialogFooter = (
      <>
          <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTipoRespostaCheckListDialog} />
          <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteTipoRespostaCheckList} />
      </>
  );

  return (
      <div className="p-grid crud-demo">
          <div className="p-col-12">
              <div className="card">

                  <Toast ref={toast} />
                  <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                  <DataTable
                      ref={dt} value={listaTipoRespostaCheckList} dataKey="idtiporespostachecklist"
                      paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Tipos de Resposta de CheckList" globalFilter={globalFilter}
                      emptyMessage="Nenhuma TipoRespostaCheckList encontrada." header={header}>
                      <Column field="idtiporespostachecklist" header="Id"sortable style={{ width: '6%' }} />
                      <Column field="tiporespostachecklist" header="Tipo de Resposta do Checklist" sortable ></Column>
                      <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                  </DataTable>
                  <Dialog visible={tiporespostachecklistDialog} style={{ width: '450px' }} header="Tipo de Resposta do Checklist" modal className="p-fluid" footer={tiporespostachecklistDialogFooter} onHide={hideDialog}>
                      <div className="p-field">
                          <label htmlFor="tiporespostachecklist">Tipo de Resposta do Checklist</label>
                          <InputText id="tiporespostachecklist" value={tiporespostachecklist.tiporespostachecklist} onChange={(e) => onInputChange(e, 'tiporespostachecklist')} required autoFocus className={classNames({ 'p-invalid': submitted && !tiporespostachecklist.tiporespostachecklist})} />
                          {submitted && !tiporespostachecklist.tiporespostachecklist && <small className="p-invalid">Tipo de Resposta do Checklist é requerido.</small>}
                      </div>
                  </Dialog>
                  <Dialog visible={deleteTipoRespostaCheckListDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteTipoRespostaCheckListDialogFooter} onHide={hideDeleteTipoRespostaCheckListDialog}>
                      <div className="confirmation-content">
                          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                          {tiporespostachecklist && <span>Tem certeza que deseja excluir <b>{tiporespostachecklist.tiporespostachecklist}</b>?</span>}
                      </div>
                  </Dialog>
              </div>
          </div>
      </div>
  );
}
