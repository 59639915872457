import React from 'react';
import { Panel } from 'primereact/panel';
import { Chart } from 'primereact/chart';


export const TesteAlan = () => {

    const chartData = {
        labels: ['EL', 'CL', 'AC', 'PE'],
        datasets: [
            {
                data: [300, 50, 100, 200],
                backgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#FFCE84",
                ],
                hoverBackgroundColor: [
                    "#FF6384",
                    "#36A2EB",
                    "#FFCE56",
                    "#FFCE84",
                ]
            }]
    };

    const lightOptions = {
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    };

    return (
        <div className="p-grid p-fluid dashboard">
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Users</span>
                    <span className="detail">Number of visitors</span>
                    <span className="count visitors">12</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Sales</span>
                    <span className="detail">Number of purchases</span>
                    <span className="count purchases">534</span>
                </div>
            </div>
            <div className="p-col-12 p-lg-4">
                <div className="card summary">
                    <span className="title">Revenue</span>
                    <span className="detail">Income for today</span>
                    <span className="count revenue">$3,200</span>
                </div>
            </div>

            <div className="p-col-6 ">
                <Panel header="Cadastros">
                    <div className="p-grid p-justify-center">

                        <div className="p-col-12 p-md-4 p-md-6 p-lg-8 p-xl-12 ">
                            <div className="highlight-box">
                                <div className="initials" style={{ backgroundColor: '#007be5', color: '#00448f' }}><span>PF</span></div>
                                <div className="highlight-details " style={{ backgroundColor: '#edf0f5' }}>
                                    <i className="pi pi-user"></i>
                                    <span>Clientes</span>
                                    <span className="count">523</span>
                                </div>

                                <div className="highlight-details " style={{ backgroundColor: '#edf0f5' }}>
                                    <i className="pi pi-money-bill"></i>
                                    <span>Negócios</span>
                                    <span className="count">638</span>
                                </div>

                            </div>
                        </div>

                        <div className="p-col-12 p-md-4 p-md-6 p-lg-8 p-xl-12">
                            <div className="highlight-box">
                                <div className="initials" style={{ backgroundColor: '#ef6262', color: '#a83d3b' }}><span>PJ</span></div>
                                <div className="highlight-details " style={{ backgroundColor: '#edf0f5' }}>
                                    <i className="pi pi pi-user"></i>
                                    <span>Clientes</span>
                                    <span className="count">120</span>
                                </div>

                                <div className="highlight-details " style={{ backgroundColor: '#edf0f5' }}>
                                    <i className="pi pi-money-bill"></i>
                                    <span>Negócios</span>
                                    <span className="count">133</span>
                                </div>

                            </div>
                        </div>
                    </div>
                </Panel>
                <Panel header="Negócios" >
                    <div className="p-grid p-justify-center" >
                        <div className="p-col-12">
                            <Chart type="doughnut" data={chartData} options={lightOptions} style={{ position: 'relative', width: '100%' }} />
                        </div>
                    </div>
                    <div className="p-grid p-fluid dashboard">
                        <div className="p-col-12 p-lg-6">
                            <div className="card summary">
                                <span className="title">Total</span>
                                {/* <span className="detail">Quantidade de dimensionamentos cadastrados</span> */}
                                <span className="count purchases">534</span>
                            </div>
                        </div>
                        <div className="p-col-12 p-lg-6">
                            <div className="card summary">
                                <span className="title">Conversão</span>
                                {/* <span className="detail">Percentual de conversão</span> */}
                                <span className="count visitors">5,61%</span>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>
            <div className="p-col-6">
                <Panel header="Financeiro">
                    <div className="p-grid p-justify-center">
                        <div className="p-col-6 ">
                            <div className="card summary">
                                <span className="title">Users</span>
                                <span className="detail">Number of visitors</span>
                                <span className="count visitors">12</span>
                            </div>
                        </div>
                        <div className="p-col-6 ">
                            <div className="card summary">
                                <span className="title">Sales</span>
                                <span className="detail">Number of purchases</span>
                                <span className="count purchases">534</span>
                            </div>
                        </div>
                        <div className="p-col-12 ">
                            <div className="card summary">
                                <span className="title">Revenue</span>
                                <span className="detail">Income for today</span>
                                <span className="count revenue">$3,200</span>
                            </div>
                        </div>
                    </div>
                </Panel>
            </div>


            <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                    <div className="initials" style={{ backgroundColor: '#20d077', color: '#038d4a' }}><span>OI</span></div>
                    <div className="highlight-details ">
                        <i className="pi pi-filter"></i>
                        <span>Open Issues</span>
                        <span className="count">21</span>
                    </div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-xl-3">
                <div className="highlight-box">
                    <div className="initials" style={{ backgroundColor: '#f9c851', color: '#b58c2b' }}><span>CI</span></div>
                    <div className="highlight-details ">
                        <i className="pi pi-check"></i>
                        <span>Closed Issues</span>
                        <span className="count">60</span>
                    </div>
                </div>
            </div>
        </div>
    );

}
