import axios from 'axios';

const token = sessionStorage.getItem("simpemptoken");

const api = axios.create({
    // baseURL : 'http://localhost:9100',
    baseURL : 'https://www.sistemaimperium.com.br/simpemp',   
    // baseURL : 'https://www.sistemaimperium.com.br/simpempteste',    
    headers: {"Authorization" : `Bearer ${token}`}
});
// api.defaults.headers.common = {"Authorization" : `Bearer ${sessionStorage.getItem("simpemptoken")}`}

api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response) {
        if (error.response.status === 401) {
          // Do something, call refreshToken() request for example;
          // return a request      
          
          if (token){
            sessionStorage.removeItem("simpemptoken");
          }
          window.location.reload();
          return api;
        }
        else{
        // if (error.response.status === ANOTHER_STATUS_CODE) {
          // Do something 
          return Promise.reject(error.response.data);
        }
      }
  
      return Promise.reject(error);
    }
  );

export default api;