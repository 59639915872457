import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { Badge } from '../components/badge/Badge';
import { ChromePicker, SketchPicker } from 'react-color';

export const Prioridades = () => {

    let emptyPrioridade = {
        idprioridade: null,
        prioridade: '',
        cor: '#30a2c0'
    };

    const [prioridade, setPrioridade] = useState(emptyPrioridade);
    const [listaPrioridades, setListaPrioridades] = useState(null);
    const [prioridadeDialog, setPrioridadeDialog] = useState(false);
    const [rows, setRows] = useState(10);
    const [deletePrioridadeDialog, setDeletePrioridadeDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/prioridades`).then((res) => {
            setListaPrioridades(res.data);
            console.log(res.data)
        });
    }, []);

    const openNew = () => {
        setPrioridade(emptyPrioridade);
        setSubmitted(false);
        setPrioridadeDialog(true);
    }

    const savePrioridades = () => {
        setSubmitted(true);
        if (prioridade.prioridade.trim()) {
            let _listaPrioridades = [...listaPrioridades];
            let _prioridade = { ...prioridade };
            if (_prioridade.idprioridade) {
                const index = findIndexById(_prioridade.idprioridade);
                api.put('/prioridades', _prioridade).then(res => {
                    if (res.status === 200) {
                        _listaPrioridades[index] = _prioridade;
                        setListaPrioridades(_listaPrioridades);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _prioridade.idprioridade = 0;
                api.post('/prioridades', _prioridade).then(res => {
                    if (res.status === 200) {
                        _prioridade.idprioridade = res.data.idprioridade;
                        _listaPrioridades.push(_prioridade);
                        setListaPrioridades(_listaPrioridades);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
           
            setPrioridadeDialog(false);
            setPrioridade(emptyPrioridade);
        }
    }

    const editPrioridades = (prioridade) => {
        setPrioridade({ ...prioridade });
        setPrioridadeDialog(true);
    }

    const deletePrioridades = () => {
        api.delete(`/prioridades/${prioridade.idprioridade}`).then(res => {
            if (res.status === 200) {
                let _prioridade = listaPrioridades.filter(val => val.idprioridade !== prioridade.idprioridade);
                setListaPrioridades(_prioridade);
                setDeletePrioridadeDialog(false);
                setPrioridade(emptyPrioridade);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeletePrioridades = (prioridade) => {
        setPrioridade(prioridade);
        setDeletePrioridadeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setPrioridadeDialog(false);
    }

    const hideDeletePrioridadesDialog = () => {
        setDeletePrioridadeDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaPrioridades.length; i++) {
            if (listaPrioridades[i].idprioridade === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _prioridade = { ...prioridade };
        _prioridade[`${name}`] = val;
        setPrioridade(_prioridade);
    }

    const handleChangeComplete = (color) => {
        let _prioridade = { ...prioridade };
        _prioridade.cor = color.hex;
        setPrioridade(_prioridade);
      };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaPrioridades);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaPrioridades');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editPrioridades(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeletePrioridades(rowData)} />
            </div>
        );
    }

    const corTemplate = (rowData) => {
        return (<Badge className="product-badge" size="large" style={{ background: `${rowData.cor}` }} ></Badge>);
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Prioridades</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const prioridadesDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={savePrioridades} />
        </>
    );

    const deletePrioridadesDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeletePrioridadesDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deletePrioridades} />
        </>
    );

   const onPageChange = (event) => {
       setRows(event.rows);
  };

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card" >

                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                    {/* <div className="card p-grid p-justify-center" > */}
                    <DataTable
                        ref={dt} value={listaPrioridades} dataKey="idprioridade"
                         className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Prioridades" globalFilter={globalFilter}
                        paginator rows={rows} rowsPerPageOptions={[5, 10, 25]}
                        emptyMessage="Nenhuma Prioridade encontrada." header={header}
                        onPage={event => onPageChange(event)}>
                        <Column field="idprioridade" header="Id" sortable style={{ width: '6%' }} />
                        <Column field="prioridade" header="Descrição" sortable ></Column>
                        <Column field="cor" header="Cor" body={corTemplate} style={{ width: '6%' }}  ></Column>
                        <Column body={actionBodyTemplate} style={{ width: '12%' }} ></Column>
                    </DataTable>
                    {/* </div> */}
                    <Dialog visible={prioridadeDialog} style={{ width: '450px' }} header="Prioridade" modal className="p-fluid" footer={prioridadesDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="prioridade">Descrição</label>
                            <InputText id="prioridade" value={prioridade.prioridade} onChange={(e) => onInputChange(e, 'prioridade')} required autoFocus className={classNames({ 'p-invalid': submitted && !prioridade.prioridade })} />
                            {submitted && !prioridade.prioridade && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="cor">Cor</label>
                            {/* <InputText id="cor" value={prioridade.cor} onChange={(e) => onInputChange(e, 'cor')} required className={classNames({ 'p-invalid': submitted && !prioridade.cor })} />
                            {/* <ColorPicker value={prioridade.cor} onChange={(e) => setColor(e.value)} > </ColorPicker>  */}
                            {/* {submitted && !prioridade.cor && <small className="p-invalid">Cor é requerido.</small>} */} 
                            <ChromePicker id="corhexa"
                            disableAlpha  
                            color={prioridade.cor}
                            onChangeComplete={handleChangeComplete}
                            />
                            
                        </div>
                    </Dialog>
                    <Dialog visible={deletePrioridadeDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deletePrioridadesDialogFooter} onHide={hideDeletePrioridadesDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {prioridade && <span>Tem certeza que deseja excluir <b>{prioridade.prioridade}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
