import { Panel } from 'primereact/panel';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toolbar } from '../components/toolbar/Toolbar';
import { InputText } from '../components/inputtext/InputText';
import { Button } from '../components/button/Button';
import { Dialog } from '../components/dialog/Dialog';
import { Dropdown } from '../components/dropdown/Dropdown';
import classNames from 'classnames';
import { Toast } from '../components/toast/Toast';
import { Link, Redirect } from 'react-router-dom';

export const ClientesAreas = (props) => {

    let emptyClienteAreas = {
        idclientesareas: 0,
        idcliente: 0,
        idarea: 0,
        descricao: '',
        dataatualizacao: null,
        ativo: false,
        area: ''
    }

    const [idCliente, setIdCliente] = useState(props.valorId);
    const [clienteArea, setClienteArea] = useState(emptyClienteAreas)
    const [listaClienteAreas, setListaClienteAreas] = useState(null);
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [NovaAreaDialog, setNovaAreaDialog] = useState(false);
    const [listaTipoAreas, setListaTipoAreas] = useState(null);
    const toast = useRef(null);
    const [edicao, setEdicao] = useState(false);
    const [deleteClienteAreaDialog, setDeleteClienteAreaDialog] = useState(false);

    useEffect(() => {

        api.get(`/clientesareas/cliente/${idCliente}`).then((res) => {
            setListaClienteAreas(res.data);
        });
    }, []);

    const newArea = () => {
        api.get('/areas').then(res => {
            setListaTipoAreas(res.data);
        });

        setNovaAreaDialog(true);
    }

    const addClienteArea = () => {
        let _listaClienteAreas = [...listaClienteAreas];
        let _clienteArea = { ...clienteArea };
        // credentials.includes({ username: 'foo, password: 'bar' });
        // _listaClienteAreas.includes({descricao: 'aa'}) ? alert("Encontrou") : alert("Não encontrou");
        // credentials.some(cred => cred.username === this.state.username);

        if (_listaClienteAreas.some(list => list.descricao === _clienteArea.descricao)) {
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'Já existe uma área com esta descrição cadastrada para este cliente.', life: 3000 });
        }
        else {
            setSubmitted(true);

            if (clienteArea.descricao.trim() && clienteArea.idarea) {
                // let _listaClienteAreas = [...listaClienteAreas];
                // let _clienteArea = { ...clienteArea };
                if (!_clienteArea.idclientesareas) {
                    _clienteArea.idclientesareas = 0;
                    _clienteArea.idcliente = idCliente;
                    _clienteArea.ativo = false;
                    console.table(_clienteArea);

                    api.post('/clientesareas', _clienteArea).then(res => {
                        if (res.status === 200) {
                            _clienteArea.idclientesareas = res.data.id;
                            console.log(res.data.id);
                            setClienteArea(_clienteArea);
                            _listaClienteAreas.push(_clienteArea);
                            setListaClienteAreas(_listaClienteAreas);
                            toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                            setEdicao(true);
                        }
                    })
                        .catch(error => {
                            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                            console.log(error);
                        })
                }
                // setListaAtividades(_listaAtividades);
                setNovaAreaDialog(false);
                setClienteArea(emptyClienteAreas);
            }
        }
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _clienteArea = { ...clienteArea };
        _clienteArea[`${name}`] = val;
        setClienteArea(_clienteArea);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaTipoAreas.length; i++) {
            if (listaTipoAreas[i].idarea === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onDropDownChange = (e) => {
        let _clienteArea = { ...clienteArea };
        _clienteArea['idarea'] = e.value;
        _clienteArea['descricao'] = listaTipoAreas[findIndexById(e.value)].area;
        setClienteArea(_clienteArea);
    }


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
                </span>
            </React.Fragment>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div><Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={newArea} /></div>
            </React.Fragment>
        )
    }

    const confirmDeleteArea = (clienteAreaItem) => {
        setClienteArea(clienteAreaItem)
        setDeleteClienteAreaDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Link to={`/itensatividades/${rowData['idatividade']}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link> */}
                <div >
                    {/* <Button icon="pi pi-pencil" className="p-button p-button-secondary p-mr-2" /> */}
                    {/* <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editClienteArea(rowData)} />  */}
                    <Link to={`/area/${rowData[`idclientesareas`]}/${idCliente}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link>
                    <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteArea(rowData)} />
                </div>
            </React.Fragment>
        );
    }

    const hideNovaAreaDialog = () => {
        setSubmitted(false);
        setClienteArea(emptyClienteAreas);
        setNovaAreaDialog(false);
    }

    const hideDeleteDialog = () => {
        setDeleteClienteAreaDialog(false);
    }

    const deleteArea = () => {
        api.delete(`/clientesareas/${clienteArea.idclientesareas}`).then(res => {
            if (res.status === 200) {
                let _listaClienteArea = listaClienteAreas.filter(val => val.idclientesareas !== clienteArea.idclientesareas);
                setListaClienteAreas(_listaClienteArea);
                setDeleteClienteAreaDialog(false);
                setClienteArea(emptyClienteAreas);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });

            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);

            })
    }

    const deleteClienteAreaDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteArea} />
        </>
    );

    const NovaAreaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideNovaAreaDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={addClienteArea} />
        </>
    );
    if (edicao) {
        return <Redirect push to={`/area/${clienteArea.idclientesareas}/${idCliente}`} />;
    }
    else {
        return (
            <div>
                <Toast ref={toast} />
                <Panel header={'Áreas'}>
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                    <br></br>
                    <DataTable
                        ref={dt} value={listaClienteAreas} dataKey="idclientesareas"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Áreas"
                        globalFilter={globalFilter}
                        emptyMessage="Nenhuma área encontrada."
                        // header={'Áreas'} 
                        sortMode="multiple" >
                        <Column field="idclientesareas" header="Id" style={{ width: '6%' }} />
                        <Column field="descricao" header="Descrição" style={{ width: '40%' }}></Column>
                        <Column field="area" header="Tipo de Área" style={{ width: '40%' }} ></Column>
                        <Column field="ativo" header="Ativo" style={{ width: '6%' }}></Column>
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                    </DataTable>
                </Panel>

                <Dialog visible={NovaAreaDialog} style={{ width: '450px' }} header="Área" modal className="p-fluid"
                    footer={NovaAreaDialogFooter} onHide={hideNovaAreaDialog} >
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="area">Tipo de Área</label>
                            <Dropdown id="area" inputId='idarea' optionValue='idarea' optionLabel='area' value={clienteArea.idarea} options={listaTipoAreas}
                                onChange={(e) => onDropDownChange(e)} filter
                                placeholder="Selecione" required autoFocus className={classNames({ 'p-invalid': submitted && !clienteArea.idarea })} />
                            {submitted && !clienteArea.idarea && <small className="p-invalid">Tipo de Área é requerido.</small>}

                            {/* <Dropdown inputId="idtipousuarioaprovador" optionValue="idtipousuario" optionLabel="tipousuario" value={atividade.idtipousuarioaprovador} options={listaTipoUsuario}
                                onChange={(e) => onTipoUsuarioAprovadorChange(e)} placeholder="Selecione"
                                required className={classNames({ 'p-invalid': submitted && !atividade.idtipousuarioaprovador })} />
                            {submitted && !atividade.idtipousuarioaprovador && <small className="p-invalid">Tipo de Usuário é requerido.</small>} */}
                            {/* {submitted && !area.area && <small className="p-invalid">Descrição é requerido.</small>} */}
                        </div>
                    </div>
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={clienteArea.descricao} onChange={(e) => onInputChange(e, 'descricao')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !clienteArea.descricao })} />
                            {submitted && !clienteArea.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                    </div>
                </Dialog>

                <Dialog visible={deleteClienteAreaDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteClienteAreaDialogFooter} onHide={confirmDeleteArea}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {clienteArea && <span>Tem certeza que deseja excluir <b>{clienteArea.descricao}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}