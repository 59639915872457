import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { Checkbox } from '../components/checkbox/Checkbox';

export const UndPeriodicidade = () => {

    let emptyUndPeriodicidade = {
        idundperiodicidade: null,
        undperiodicidade: '',
        qtddias: null,
        adiar: false,
    };

    const [undperiodicidade, setUndPeriodicidade] = useState(emptyUndPeriodicidade);
    const [listaUndPeriodicidade, setListaUndPeriodicidade] = useState(null);
    const [undperiodicidadeDialog, setUndPeriodicidadeDialog] = useState(false);
    const [deleteUndPeriodicidadeDialog, setDeleteUndPeriodicidadeDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/undperiodicidade`).then((res) => {
            setListaUndPeriodicidade(res.data);
        });
    }, []);

    const openNew = () => {
        setUndPeriodicidade(emptyUndPeriodicidade);
        setSubmitted(false);
        setUndPeriodicidadeDialog(true);
    }

    const saveUndPeriodicidade = () => {
        setSubmitted(true);
        if (undperiodicidade.undperiodicidade.trim() && undperiodicidade.qtddias && undperiodicidade.qtddias > 0) {
            let _listaUndPeriodicidade = [...listaUndPeriodicidade];
            let _undperiodicidade = { ...undperiodicidade };
            if (_undperiodicidade.idundperiodicidade) {
                const index = findIndexById(_undperiodicidade.idundperiodicidade);
                api.put('/undperiodicidade', _undperiodicidade).then(res => {
                    if (res.status === 200) {
                        _listaUndPeriodicidade[index] = _undperiodicidade;
                        setListaUndPeriodicidade(_listaUndPeriodicidade);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _undperiodicidade.idundperiodicidade = 0;
                api.post('/undperiodicidade', _undperiodicidade).then(res => {
                    if (res.status === 200) {
                        _undperiodicidade.idundperiodicidade = res.data.id;
                        _listaUndPeriodicidade.push(_undperiodicidade);
                        setListaUndPeriodicidade(_listaUndPeriodicidade);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            // setListaUndPeriodicidade(_listaUndPeriodicidade);
            setUndPeriodicidadeDialog(false);
            setUndPeriodicidade(emptyUndPeriodicidade);
        }
    }

    const editUndPeriodicidade = (undperiodicidade) => {
        setUndPeriodicidade({ ...undperiodicidade });
        setUndPeriodicidadeDialog(true);
    }

    const deleteUndPeriodicidade = () => {
        api.delete(`/undperiodicidade/${undperiodicidade.idundperiodicidade}`).then(res => {
            if (res.status === 200) {
                let _undperiodicidade = listaUndPeriodicidade.filter(val => val.idundperiodicidade !== undperiodicidade.idundperiodicidade);
                setListaUndPeriodicidade(_undperiodicidade);
                setDeleteUndPeriodicidadeDialog(false);
                setUndPeriodicidade(emptyUndPeriodicidade);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteUndPeriodicidade = (undperiodicidade) => {
        setUndPeriodicidade(undperiodicidade);
        setDeleteUndPeriodicidadeDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setUndPeriodicidadeDialog(false);
    }

    const hideDeleteUndPeriodicidadeDialog = () => {
        setDeleteUndPeriodicidadeDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaUndPeriodicidade.length; i++) {
            if (listaUndPeriodicidade[i].idundperiodicidade === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _undperiodicidade = { ...undperiodicidade };
        _undperiodicidade[`${name}`] = val;
        setUndPeriodicidade(_undperiodicidade);
    }

    const onCheckChange = (value) => {
        let _undperiodicidade = { ...undperiodicidade };
        _undperiodicidade['adiar'] = value;
        setUndPeriodicidade(_undperiodicidade);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const badgeTemplate = (rowData) => {
        var trueValue = <span className="product-badge" style={{ background: '#c8e6c9', color: '#256029' }} >{rowData.adiar && "sim"}</span>
        var falseValue = <span className="product-badge" style={{ background: '#ffcdd2', color: '#c63737' }} >{!rowData.adiar && "não"}</span>
        return (rowData.adiar ? trueValue : falseValue)
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaUndPeriodicidade);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaUndPeriodicidade');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editUndPeriodicidade(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteUndPeriodicidade(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Periodicidade</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const undperiodicidadeDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveUndPeriodicidade} />
        </>
    );

    const deleteUndPeriodicidadeDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUndPeriodicidadeDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteUndPeriodicidade} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt} value={listaUndPeriodicidade} dataKey="idundperiodicidade"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Periodicidades" globalFilter={globalFilter}
                        emptyMessage="Nenhuma UndPeriodicidade encontrada." header={header}>
                        <Column field="idundperiodicidade" header="Id" style={{ width: '6%' }} sortable />
                        <Column field="undperiodicidade" header="Descrição" sortable ></Column>
                        <Column field="qtddias" header="Qtd. Dias" className="p-text-center" style={{ width: '6%' }}  ></Column>
                        <Column field="adiar" header="Adiar" body={badgeTemplate} style={{ width: '6%' }} ></Column>
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                    </DataTable>
                    <Dialog visible={undperiodicidadeDialog} style={{ width: '450px' }} header="Periodicidade" modal className="p-fluid" footer={undperiodicidadeDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="undperiodicidade">Periodicidade</label>
                            <InputText id="undperiodicidade" value={undperiodicidade.undperiodicidade} onChange={(e) => onInputChange(e, 'undperiodicidade')} required className={classNames({ 'p-invalid': submitted && !undperiodicidade.undperiodicidade })} />
                            {submitted && !undperiodicidade.undperiodicidade && <small className="p-invalid">Periodicidade é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="qtddias">Qtd. Dias</label>
                            <InputText id="qtddias" value={undperiodicidade.qtddias} onChange={(e) => onInputChange(e, 'qtddias')} required className={classNames({ 'p-invalid': submitted && !undperiodicidade.qtddias })} />
                            {submitted && !undperiodicidade.qtddias && <small className="p-invalid">QtdDias é requerido.</small>}
                            {/* {submitted && !undperiodicidade.qtddias > 0 && <small className="p-invalid"> QtdDias deve ser maior que zero.</small>} */}
                        </div>
                        {/* <div className="p-field">
                          <label htmlFor="adiar">Adiar</label>
                          <InputText id="adiar" value={undperiodicidade.adiar} onChange={(e) => onInputChange(e, 'adiar')} required className={classNames({ 'p-invalid': submitted && !undperiodicidade.adiar})} />
                          {submitted && !undperiodicidade.adiar && <small className="p-invalid">Adiar é requerido.</small>}
                      </div> */}
                        <div className="p-field">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="finaliza" checked={undperiodicidade.adiar} onChange={(e) => onCheckChange(e.checked)} />
                                <label htmlFor="finaliza"> Adiar</label>
                            </div>
                        </div>
                    </Dialog>
                    <Dialog visible={deleteUndPeriodicidadeDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteUndPeriodicidadeDialogFooter} onHide={hideDeleteUndPeriodicidadeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {undperiodicidade && <span>Tem certeza que deseja excluir <b>{undperiodicidade.undperiodicidade}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
