import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { Panel } from '../components/panel/Panel';
import { Link, Redirect} from 'react-router-dom';
import { Dropdown } from '../components/dropdown/Dropdown';
import { Checkbox } from '../components/checkbox/Checkbox';

export const ItemAtividades = (props) => {

    let emptyAtividade = {
        idatividade: null,
        atividade: '',
        iditem: null,
        idtipoatividade: null,
        idprioridade: null,
        idtipoexecutor: null,
        avisodeimportancia: false,
        ajustededata: false,
        requeraprovacaobaixa: false,
        idtipousuarioaprovador: null,
        periodicidade: null,
        idundperiodicidade: null,
        doctecapoio: false,
        ativo: true,
        manutencaogarantia: true,
        compoelaudo: true,
        tag: null
    };

    const [idItem, setIditem] = useState(props.valorId);                            // Item que está sendo editado
    const [atividade, setAtividade] = useState(emptyAtividade);             // Atividade 
    const [listaAtividades, setListaAtividades] = useState(null);           // Lista com todas as Atividades do item
    const [listaTiposAtividade, setListaTiposAtividade] = useState(null);   // Lista dos Tipos de Itens 
    const [listaPeriodicidade, setListaPeriodicidade] = useState(null);     // Lista das Periodicidades 
    const [listaPrioridade, setListaPrioridade] = useState(null);           // Lista das Prioridades 
    const [listaTipoExecutor, setListaTipoExecutor] = useState(null);       // Lista dos Tipos de Executores 
    const [listaTipoUsuario, setListaTipoUsuario] = useState(null);         // Lista dos Tipos de Usuarios 
    const [atividadeDialog, setAtividadeDialog] = useState(false);          // Dialog para cadastrar Nova Atividade
    const [deleteAtividadeDialog, setDeleteAtividadeDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [edicao, setEdicao] = useState(false);


    useEffect(() => {
        // Lista com todas as Atividades do item
        api.get(`/itensatividades/item/${idItem}`).then((res) => {
            setListaAtividades(res.data);
        });

        api.get(`/tipoatividade`).then((res) => {
            setListaTiposAtividade(res.data);
        });
    }, []);

    const newAtividade = () => {
        api.get(`/tipoatividade`).then((res) => {
            setListaTiposAtividade(res.data);
        });

        api.get(`/undperiodicidade`).then((res) => {
            setListaPeriodicidade(res.data);
        });

        api.get(`/prioridades`).then((res) => {
            setListaPrioridade(res.data);
        });

        api.get(`/tipoexecutor`).then((res) => {
            setListaTipoExecutor(res.data);
        });

        api.get(`/tipousuario`).then((res) => {
            setListaTipoUsuario(res.data);
        });

        setAtividadeDialog(true);
    }

    const addAtividade = () => {
        setSubmitted(true);
        if (atividade.atividade.trim() && atividade.idtipoatividade && atividade.idundperiodicidade && atividade.idprioridade
            && atividade.idtipoexecutor && atividade.idtipousuarioaprovador) { //validar se está preenchido o campo atividade
            let _listaAtividades = [...listaAtividades];
            let _atividade = { ...atividade };
            if (!_atividade.idatividade) { //se não possui id
                _atividade.idatividade = 0;
                _atividade.iditem = idItem;
                _atividade.ativo = true;
                _atividade.manutencaogarantia = false; //virar campo
                _atividade.compoelaudo = false;
                _atividade.tag = 'tag';
                console.table(_atividade)



                api.post('/itensatividades', _atividade).then(res => {
                    if (res.status === 200) {
                        _atividade.idatividade = res.data.id;
                        setAtividade(_atividade);
                        console.log('id ' + res.data.id);
                        _listaAtividades.push(_atividade);
                        setListaAtividades(_listaAtividades);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                        setEdicao(true);
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            // setListaAtividades(_listaAtividades);
            setAtividadeDialog(false);
            setAtividade(emptyAtividade);
        }
    }

    const onTipoAtividadeChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.idtipoatividade = e.value;
        setAtividade(_atividade);
    }

    const onPeriodicidadeChange = (e) => {
        let _atividade = { ...atividade };
        let id = e.value;
        _atividade.idundperiodicidade = id;

        for (let i = 0; i < listaPeriodicidade.length; i++) {
            if (listaPeriodicidade[i].idundperiodicidade === id) {
                _atividade.periodicidade = listaPeriodicidade[i].qtddias;
                break;
            }
        }

        setAtividade(_atividade);
    }

    const onPrioridadeChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.idprioridade = e.value;
        setAtividade(_atividade);
    }
    const onTipoExecutorChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.idtipoexecutor = e.value;
        setAtividade(_atividade);
    }
    const onTipoUsuarioAprovadorChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.idtipousuarioaprovador = e.value;
        setAtividade(_atividade);
    }

    const onInputChangeAtividade = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _atividade = { ...atividade };
        _atividade[`${name}`] = val;
        setAtividade(_atividade);
    }

    const onCheckboxChange = (e, name) => {
        let _atividade = { ...atividade };
        _atividade[`${name}`] = e.checked;
        setAtividade(_atividade);
    };

    const confirmDeleteAtividade = (atividade) => {
        setAtividade(atividade);
        setDeleteAtividadeDialog(true);
    }

    const deleteAtividade = () => {
        api.delete(`/itensatividades/${atividade.idatividade}`).then(res => {
            if (res.status === 200) {
                let _atividade = listaAtividades.filter(val => val.idatividade !== atividade.idatividade);
                setListaAtividades(_atividade);
                setDeleteAtividadeDialog(false);
                setAtividade(emptyAtividade);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const hideAddAtividadeDialog = () => {
        setSubmitted(false);
        setAtividadeDialog(false);
    }

    const hideDeleteAtividadeDialog = () => {
        setDeleteAtividadeDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div><Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={newAtividade} /></div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter({ globalFilter: e.target.value })} placeholder="Search..." />
                </span>
            </React.Fragment>
        )
    }

    const tipoAtividadeBody = (rowData) => {
        if (listaTiposAtividade) {
            let n = rowData.idtipoatividade;
            let tipo = null;
            for (let i = 0; i < listaTiposAtividade.length; i++) {
                if (listaTiposAtividade[i].idtipoatividade === n) {
                    tipo = listaTiposAtividade[i].tipoatividade;

                    break;
                }
            }
            return tipo;
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link to={`/itensatividades/${rowData['idatividade']}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link>
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteAtividade(rowData)} />
            </React.Fragment>
        );
    }

    const atividadeDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideAddAtividadeDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={addAtividade} />
        </>
    );

    const deleteAtividadeDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAtividadeDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteAtividade} />
        </>
    );

    if (edicao) {
        return <Redirect push to={`/itensatividades/${atividade.idatividade}`} />;
    }
    else {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">

                    <Toast ref={toast} />

                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                    <Panel>
                        <DataTable
                            ref={dt} value={listaAtividades} dataKey="idatividade"
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive p-datatable-customers"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Itens" globalFilter={globalFilter}
                            emptyMessage="Nenhum Item encontrado." sortMode="multiple" >
                            <Column field="idatividade" header="Id" style={{ width: '6%' }} />
                            <Column field="atividade" header="Atividade" ></Column>
                            <Column field="idtipoatividade" header="Tipo de Atividade" body={tipoAtividadeBody} ></Column>
                            <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '100px' }} ></Column>
                        </DataTable>
                    </Panel>

                    <Dialog visible={atividadeDialog} style={{ width: '450px' }} header="Atividade" modal className="p-fluid" footer={atividadeDialogFooter} onHide={hideAddAtividadeDialog}>
                        <div className="p-field">
                            <label htmlFor="atividade">Atividade</label>
                            <InputText id="atividade" value={atividade.atividade} onChange={(e) => onInputChangeAtividade(e, 'atividade')} required className={classNames({ 'p-invalid': submitted && !atividade.atividade })} />
                            {submitted && !atividade.atividade && <small className="p-invalid">Atividade é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="idtipoatividade">Tipo de Atividade</label>
                            <Dropdown inputId="idtipoatividade" optionValue="idtipoatividade" optionLabel="tipoatividade" value={atividade.idtipoatividade} options={listaTiposAtividade}
                                onChange={(e) => onTipoAtividadeChange(e)} placeholder="Selecione" filter
                                required className={classNames({ 'p-invalid': submitted && !atividade.idtipoatividade })} />
                            {submitted && !atividade.idtipoatividade && <small className="p-invalid">Tipo de Atividade é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="idundperiodicidade">Periodicidade</label>
                            <Dropdown inputId="idundperiodicidade" optionValue="idundperiodicidade" optionLabel="undperiodicidade" value={atividade.idundperiodicidade} options={listaPeriodicidade}
                                onChange={(e) => onPeriodicidadeChange(e)} placeholder="Selecione"
                                required className={classNames({ 'p-invalid': submitted && !atividade.idundperiodicidade })} />
                            {submitted && !atividade.idundperiodicidade && <small className="p-invalid">Periodicidade é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="idprioridade">Prioridade</label>
                            <Dropdown inputId="idprioridade" optionValue="idprioridade" optionLabel="prioridade" value={atividade.idprioridade} options={listaPrioridade}
                                onChange={(e) => onPrioridadeChange(e)} placeholder="Selecione"
                                required className={classNames({ 'p-invalid': submitted && !atividade.idprioridade })} />
                            {submitted && !atividade.idprioridade && <small className="p-invalid">Prioridade é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="idtipoexecutor">Tipo de  Executor</label>
                            <Dropdown inputId="idtipoexecutor" optionValue="idtipoexecutor" optionLabel="tipoexecutor" value={atividade.idtipoexecutor} options={listaTipoExecutor}
                                onChange={(e) => onTipoExecutorChange(e)} placeholder="Selecione" filter
                                required className={classNames({ 'p-invalid': submitted && !atividade.idtipoexecutor })} />
                            {submitted && !atividade.idtipoexecutor && <small className="p-invalid">Tipo de Executor é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="idtipousuarioaprovador">Tipo de Usuário</label>
                            <Dropdown inputId="idtipousuarioaprovador" optionValue="idtipousuario" optionLabel="tipousuario" value={atividade.idtipousuarioaprovador} options={listaTipoUsuario}
                                onChange={(e) => onTipoUsuarioAprovadorChange(e)} placeholder="Selecione" filter
                                required className={classNames({ 'p-invalid': submitted && !atividade.idtipousuarioaprovador })} />
                            {submitted && !atividade.idtipousuarioaprovador && <small className="p-invalid">Tipo de Usuário é requerido.</small>}
                        </div>
                        <div className="p-grid p-col-12">
                            <div className="p-field-checkbox p-col-6">
                                <Checkbox inputId="ajustededata" name="ajustededata" value="ajustededata" checked={atividade.ajustededata} onChange={(e) => onCheckboxChange(e, 'ajustededata')} />
                                <label htmlFor="ajustededata" className="p-mr-2">Ajuste de Data</label>
                            </div>
                            <div className="p-field-checkbox p-col-6">
                                <Checkbox inputId="avisodeimportancia" name="avisodeimportancia" value="avisodeimportancia" checked={atividade.avisodeimportancia} onChange={(e) => onCheckboxChange(e, 'avisodeimportancia')} />
                                <label htmlFor="avisodeimportancia" className="p-mr-2">Aviso de Importância</label>

                            </div>
                        </div>
                        <div className="p-grid p-col-12">
                            <div className="p-field-checkbox p-col-6">
                                <Checkbox inputId="doctecapoio" name="doctecapoio" value="doctecapoio" checked={atividade.doctecapoio} onChange={(e) => onCheckboxChange(e, 'doctecapoio')} />
                                <label htmlFor="doctecapoio" className="p-mr-2">Doc. Téc. Apoio</label>
                            </div>
                            <div className="p-field-checkbox p-col-6">
                                <Checkbox inputId="requeraprovacaobaixa" name="requeraprovacaobaixa" value="requeraprovacaobaixa" checked={atividade.requeraprovacaobaixa} onChange={(e) => onCheckboxChange(e, 'requeraprovacaobaixa')} />
                                <label htmlFor="requeraprovacaobaixa" className="p-mr-2">Requer Aprovação Baixa</label>
                            </div>
                        </div>

                    </Dialog>

                    <Dialog visible={deleteAtividadeDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteAtividadeDialogFooter} onHide={hideDeleteAtividadeDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {listaAtividades && <span>Tem certeza que deseja excluir <b>{atividade.atividade}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        );
    }
}
