import { Panel } from 'primereact/panel';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toolbar } from '../components/toolbar/Toolbar';
import { InputText } from '../components/inputtext/InputText';
import { InputNumber } from '../components/inputnumber/InputNumber';
import { Button } from '../components/button/Button';
import { Dialog } from '../components/dialog/Dialog';
import { Dropdown } from '../components/dropdown/Dropdown';
import classNames from 'classnames';
import { InputSwitch } from "../components/inputswitch/InputSwitch";
import { Toast } from '../components/toast/Toast';
import { Link, Redirect } from 'react-router-dom';


export const ClientesPlanos = (props) => {

    let emptyClienteAreas = {
        idclientesareas: 0,
        idcliente: 0,
        idarea: 0,
        descricao: '',
        dataatualizacao: null,
        ativo: false,
        area: ''
    }

    let emptyPlano = {
        idclientesplanos: 0,
        datahora: '',
        periodo: 0,
        idcliente: 0
    }

    const [idCliente, setIdCliente] = useState(props.valorId);
    const [planoAtivo, setPlanoAtivo] = useState(props.planoAtivo);
    const [clienteArea, setClienteArea] = useState(emptyClienteAreas)
    const [ClientePlano, setClientePlano] = useState(emptyPlano);
    const [listaClientePlano, setListaClientePlano] = useState(null);
    const [listaClienteAreas, setListaClienteAreas] = useState(null);
    const dt = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [NovaAreaDialog, setNovaAreaDialog] = useState(false);
    const [listaTipoAreas, setListaTipoAreas] = useState(null);
    const toast = useRef(null);
    const [edicao, setEdicao] = useState(false);
    const [periodo, setPeriodo] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {        
        api.get(`/clientesplano/cliente/${idCliente}`).then((res) => {
            setListaClientePlano(res.data);
            for (let i = 0; i < res.data.length; i++) {
                if (!res.data[i].datahora) {
                    setLoading(true)
                }
            }
        });


    }, []);

    const newPlano = () => {    
        if (props.planoAtivo) {
            toast.current.show({ severity: 'warn', summary: 'Atenção', detail: 'Já existe um plano ativo para este cliente.', life: 3000 });
        }
        else {
            setNovaAreaDialog(true);
        }
    }

    // const addClienteArea = () => {
    //     setSubmitted(true);

    //     if (clienteArea.descricao.trim() && clienteArea.idarea) {
    //         let _listaClienteAreas = [...listaClienteAreas];
    //         let _clienteArea = { ...clienteArea };
    //         if (!_clienteArea.idclientesareas) {
    //             _clienteArea.idclientesareas = 0;
    //             _clienteArea.idcliente = idCliente;
    //             _clienteArea.ativo = false;
    //             console.table(_clienteArea);

    //             api.post('/clientesareas', _clienteArea).then(res => {
    //                 if (res.status === 200) {
    //                     _clienteArea.idclientesareas = res.data.id;
    //                     console.log(res.data.id);
    //                     setClienteArea(_clienteArea);
    //                     _listaClienteAreas.push(_clienteArea);
    //                     setListaClienteAreas(_listaClienteAreas);
    //                     toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
    //                     setEdicao(true);
    //                 }
    //             })
    //                 .catch(error => {
    //                     toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
    //                     console.log(error);
    //                 })
    //         }
    //         // setListaAtividades(_listaAtividades);
    //         setNovaAreaDialog(false);
    //         setClienteArea(emptyClienteAreas);
    //     }
    // }

    const salvarPlano = () => {
        let _plano = { ...ClientePlano };
        let _listaClientePlanos = [...listaClientePlano];
        // ClientePlano.idcliente = idCliente;
        _plano.idcliente = idCliente;
        _plano.periodo = periodo;

        api.post('/clientesplano', _plano).then(res => {
            if (res.status === 200) {
                _plano.idclientesplanos = res.data.id;
                // setClientePlano(_plano);
                _listaClientePlanos.push(_plano);
                setListaClientePlano(_listaClientePlanos);
                listaClientePlano.push(ClientePlano);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Plano criado com sucesso. O processo demora alguns minutos, atualize em breve.', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                console.log(error);
            });

        setNovaAreaDialog(false);
        setLoading(true);
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _clienteArea = { ...clienteArea };
        _clienteArea[`${name}`] = val;
        setClienteArea(_clienteArea);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaTipoAreas.length; i++) {
            if (listaTipoAreas[i].idarea === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onDropDownChange = (e) => {
        let _clienteArea = { ...clienteArea };
        _clienteArea['idarea'] = e.value;
        _clienteArea['descricao'] = listaTipoAreas[findIndexById(e.value)].area;
        setClienteArea(_clienteArea);
    }


    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
                </span>
            </React.Fragment>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div><Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={newPlano}
                    disabled={loading}
                /></div>
            </React.Fragment>
        )
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Link to={`/itensatividades/${rowData['idatividade']}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link> */}
                <div >
                    {/* <Button icon="pi pi-pencil" className="p-button p-button-secondary p-mr-2" /> */}
                    {/* <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editClienteArea(rowData)} />  */}
                    <Link to={`/area/${rowData[`idclientesareas`]}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link>
                    <Button icon="pi pi-trash" className="p-button p-button-danger" />
                    {/* onClick={() => confirmDeleteRepresentante(rowData)} /> */}
                </div>
            </React.Fragment>
        );
    }

    const hideNovaAreaDialog = () => {
        setSubmitted(false);
        setClienteArea(emptyClienteAreas);
        setNovaAreaDialog(false);
    }

    const NovaAreaDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideNovaAreaDialog} />
            <Button label="Gerar" icon="pi pi-save" className="p-button-text" onClick={salvarPlano} />
        </>
    );
    if (edicao) {
        return <Redirect push to={`/area/${clienteArea.idclientesareas}`} />;
    }
    else {
        return (
            <div>
                <Toast ref={toast} />
                <Panel header={'Planos'}>
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                    <br></br>
                    <DataTable
                        ref={dt} value={listaClientePlano} dataKey="idclientesplanos"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Áreas"
                        globalFilter={globalFilter}
                        loading={loading}
                        emptyMessage="Nenhuma plano encontrado."
                        // header={'Áreas'} 
                        sortMode="multiple" >
                        <Column field="idclientesplanos" header="Id" style={{ width: '6%' }} />
                        <Column field="datahora" header="Data de criação" style={{ width: '40%' }}></Column>
                        <Column field="periodo" header="Periodo" style={{ width: '40%' }} ></Column>
                        {/* <Column field="ativo" header="Ativo" style={{ width: '6%' }}></Column> */}
                        {/* <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column> */}
                    </DataTable>
                </Panel>

                <Dialog visible={NovaAreaDialog} style={{ width: '450px' }} header="Gerar Plano" modal className="p-fluid"
                    footer={NovaAreaDialogFooter} onHide={hideNovaAreaDialog} >
                    <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-3">
                            <label htmlFor="vertical" style={{ display: 'block' }}>Período</label>
                            <InputNumber inputId="vertical" value={periodo} onValueChange={(e) => setPeriodo(e.value)} mode="decimal" showButtons buttonLayout="vertical" style={{ width: '4rem' }}
                                decrementButtonClassName="p-button-secondary" incrementButtonClassName="p-button-secondary" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                min={1} max={5}
                            />
                        </div>
                        <div className="p-field p-col-9">
                            <label style={{ marginTop: '1.5rem' }}>  Ao clicar em gerar será criado o plano de atividades inicial
                                pelo período de {periodo} ano(s), levando em consideração os itens e áreas cadastrados neste momento.</label>
                        </div>
                    </div>
                    {/* <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col-12">
                            <label htmlFor="descricao">Descrição</label>
                            <InputText id="descricao" value={clienteArea.descricao} onChange={(e) => onInputChange(e, 'descricao')} required autoFocus
                                className={classNames({ 'p-invalid': submitted && !clienteArea.descricao })} />
                            {submitted && !clienteArea.descricao && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                    </div> */}

                </Dialog>
            </div>
        )
    }
}