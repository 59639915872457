import axios from 'axios';

const token = sessionStorage.getItem("simpemptoken");

const apiCli = axios.create({baseURL : 'https://sistemaimperium.com.br/simpcli',
// const apiCli = axios.create({ baseURL: 'http://localhost:9500',   
  headers: { "Authorization": `Bearer ${token}` }
});

apiCli.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        // Do something, call refreshToken() request for example;
        // return a request      

        if (token) {
          sessionStorage.removeItem("simpemptoken");
        }
        window.location.reload();
        return apiCli;
      }
      else {
        // if (error.response.status === ANOTHER_STATUS_CODE) {
        // Do something 
        return Promise.reject(error.response.data);
      }
    }

    return Promise.reject(error);
  }
);

export default apiCli;
