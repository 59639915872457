import React from 'react';
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = props => {
     

    const isLogged = !!sessionStorage.getItem('simpemptoken') ;

    console.log('islogged: ', isLogged, ' permissao: ', props.permissao);
    if (isLogged) {
        let p = [];
        if (props.permissao) {
            p = props.permissao;
            if (props.permissao.includes(parseInt(sessionStorage.getItem('idtipousuario')))) {
                return <Route {...props} />
            }
            else {
                return (
                <Redirect to="/" push={true}/>            
                )              
            }
        }
        else {
            return <Route {...props} />
        }
    }
    else {
        return <Redirect to="/login" />
    }
}

export default PrivateRoute;