import React, { useState, useEffect, useRef, useContext } from 'react';
import classNames from 'classnames';
import { Route, Switch, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { UserContext } from './context';
// import UserProvider from './context';
import PrivateRoute from './privateRoute';

import { AppTopbar } from './AppTopbar';
import { AppFooter } from './AppFooter';
import { AppMenu } from './AppMenu';
import { AppProfile } from './AppProfile';
import { AppConfig } from './AppConfig';

import { Dashboard } from './components/Dashboard';
import { ButtonDemo } from './components/ButtonDemo';
import { ChartDemo } from './components/ChartDemo';
import { Documentation } from './components/Documentation';
import { FileDemo } from './components/FileDemo';
import { FloatLabelDemo } from './components/FloatLabelDemo';
import { FormLayoutDemo } from './components/FormLayoutDemo';
import { InputDemo } from './components/InputDemo';
import { ListDemo } from './components/ListDemo';
import { MenuDemo } from './components/MenuDemo';
import { MessagesDemo } from './components/MessagesDemo';
import { MiscDemo } from './components/MiscDemo';
import { OverlayDemo } from './components/OverlayDemo';
import { PanelDemo } from './components/PanelDemo';
import { TableDemo } from './components/TableDemo';
import { TreeDemo } from './components/TreeDemo';
import { InvalidStateDemo } from './components/InvalidStateDemo';

import { Calendar } from './pages/Calendar';
import { Crud } from './pages/Crud';
import { EmptyPage } from './pages/EmptyPage';

import { DisplayDemo } from './utilities/DisplayDemo';
import { ElevationDemo } from './utilities/ElevationDemo';
import { FlexBoxDemo } from './utilities/FlexBoxDemo';
import { GridDemo } from './utilities/GridDemo';
import { IconsDemo } from './utilities/IconsDemo';
import { SpacingDemo } from './utilities/SpacingDemo';
import { TextDemo } from './utilities/TextDemo';
import { TypographyDemo } from './utilities/TypographyDemo';
import { TimelineDemo } from './utilities/TimelineDemo';

import { Area } from "./view/Area";
import { Clientes } from './view/Clientes';
import { ClienteEdit } from './view/ClienteEdit';
import { Empresas } from './view/Empresas';
import { Gravidade } from './view/Gravidade';
import { Itens } from './view/Itens';
import { ItemDetalhes } from './view/ItemDetalhes';
import { AtividadeDetalhes } from './view/AtividadeDetalhes';
import { AtividadeChecklist } from './view/AtividadeChecklist';
import { Prioridades } from './view/Prioridades';
import { SistemaConstrutivo } from './view/SistemaConstrutivo';
import { SistemaConstrutivoItens } from './view/SistemaConstrutivoItens';
import { TipoArea } from './view/TipoArea'
import { TipoAreaCliente } from './view/TipoAreaCliente'
import { TipoAtividade } from './view/TipoAtividade';
import { TipoCliente } from './view/TipoCliente';
import { TipoExecucao } from './view/TipoExecucao';
import { TipoExecutor } from './view/TipoExecutor';
import { TipoItem } from './view/TipoItem';
import { TipoRespostaCheckList } from './view/TipoRespostaCheckList';
import { TipoStatus } from './view/TipoStatus';
import { TipoStatusDetalhes } from './view/TipoStatusDetalhes';
import { TipoStatusNavegacao } from './view/TipoStatusNavegacao';
import { TipoUsuario } from './view/TipoUsuario';
import { UndPeriodicidade } from './view/UndPeriodicidade';
import { Usuarios } from './view/Usuarios';
import { SistemaConstrutivoEdit } from './view/SistemaConstrutivoEdit';
import { ClientesEngenharia } from './view/ClientesEngenharia';
import { ClienteEditEngenharia } from './view/ClienteEditEngenharia';
import Login from './view/login'
import { PageTest } from './view/pagetest';
import { PageTest2 } from './view/pagetest2';
import { TesteAlan } from './view/testalan';
import { TrocarSenha } from './view/trocarSenha'

import PrimeReact from 'primereact/api';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'prismjs/themes/prism-coy.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/flags/flags.css';
import './layout/layout.scss';
import './App.scss';
import '../node_modules/primeflex/primeflex.css'
import { OcorrenciasClientes } from './view/ocorrencias/OcorrenciasClientes';
import { DashboardOcorrencias } from './view/ocorrencias/dashBoardOcorrencias';
import { OcorrenciasGravidadeList } from './view/ocorrencias/ocorrenciasGravidadeList';
import { OcorrenciasStatusList } from './view/ocorrencias/ocorrenciasStatusList';
import { OcorrenciasAreasList } from './view/ocorrencias/ocorrenciasAreasList';
import { OcorrenciasAreasDetalhesList } from './view/ocorrencias/ocorrenciasAreasDetalhesList';
import { OcorrenciaEdit } from './view/ocorrencias/ocorrenciaEdit';
import { AtividadeChecklistEdit } from './view/AtividadeChecklistEdit';


const App = () => {

    const [layoutMode, setLayoutMode] = useState('static');
    const [layoutColorMode, setLayoutColorMode] = useState('dark')
    const [staticMenuInactive, setStaticMenuInactive] = useState(false);
    const [overlayMenuActive, setOverlayMenuActive] = useState(false);
    const [mobileMenuActive, setMobileMenuActive] = useState(false);
    const [inputStyle, setInputStyle] = useState('outlined');
    const [ripple, setRipple] = useState(false);
    const sidebar = useRef();
    const context = useContext(UserContext);



    const history = useHistory();

    let menuClick = false;

    useEffect(() => {
        if (mobileMenuActive) {
            addClass(document.body, 'body-overflow-hidden');
        }
        else {
            removeClass(document.body, 'body-overflow-hidden');
        }
    }, [mobileMenuActive]);



    const onInputStyleChange = (inputStyle) => {
        setInputStyle(inputStyle);
    }

    const onRipple = (e) => {
        PrimeReact.ripple = e.value;
        setRipple(e.value)
    }

    const onLayoutModeChange = (mode) => {
        setLayoutMode(mode)
    }

    const onColorModeChange = (mode) => {
        setLayoutColorMode(mode)
    }

    const onWrapperClick = (event) => {
        if (!menuClick) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
        menuClick = false;
    }

    const onToggleMenu = (event) => {
        menuClick = true;

        if (isDesktop()) {
            if (layoutMode === 'overlay') {
                setOverlayMenuActive(prevState => !prevState);
            }
            else if (layoutMode === 'static') {
                setStaticMenuInactive(prevState => !prevState);
            }
        }
        else {
            setMobileMenuActive(prevState => !prevState);
        }
        event.preventDefault();
    }

    const onSidebarClick = () => {
        menuClick = true;
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items) {
            setOverlayMenuActive(false);
            setMobileMenuActive(false);
        }
    }

    const menu = [
        { label: 'Início', icon: 'pi pi-fw pi-home', to: '/' },
        {
            label: 'Comercial', icon: 'pi pi-fw pi-briefcase',
            permissoes: [2, 8, 9],
            items: [
                { label: 'Clientes', to: '/clientes' },
            ]
        },
        {
            label: 'Engenharia', icon: 'pi pi-fw pi-paperclip',
            permissoes: [2, 8, 4, 13],
            items: [
                { label: 'Clientes', to: '/clientesEngenharia' },
                { label: 'Ocorrências', to: '/ocorrencias' },
            ]
        },
        {
            label: 'Financeiro', icon: 'pi pi-fw pi-dollar',
            permissoes: [2, 8, 10],
            items: [

            ]
        },
        {
            label: 'Cadastros Básicos', icon: 'pi pi-fw pi-file',
            permissoes: [2],
            items: [
                { label: 'Empresas Parceiras', to: '/empresa' },
                { label: 'Gravidade', to: '/gravidade' },
                { label: 'Itens/Atividades', to: '/itens' },
                { label: 'Prioridades', to: '/prioridade' },
                { label: 'Tipo de Área', to: '/tipoarea' },
                { label: 'Tipo de Atividade', to: '/tipoatividade' },
                { label: 'Tipo de Cliente', to: '/tipocliente' },
                { label: 'Tipo de Execução', to: '/tipoexecucao' },
                { label: 'Tipo de Executor', to: '/tipoexecutor' },
                { label: 'Tipo de Item', to: '/tipoitem' },
                { label: 'Tipo de Resposta do CheckList', to: '/tiporespostachecklist' },
                { label: 'Tipo Status', to: '/tipostatus' },
                { label: 'Tipo de Usuário', to: '/tipousuario' },
                { label: 'Sistema Construtivo', to: '/sistemaconstrutivo' },
                { label: 'Unidadas de Periodicidade', to: '/periodicidade' },

            ]
        },
        {
            label: 'Configurações', icon: 'pi pi-fw pi-cog',
            permissoes: [2, 8],
            items: [
                { label: 'Usuários', to: '/usuario' }
            ]
        },

        // { label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => { window.location = "#/documentation" } },
        // { label: 'View Source', icon: 'pi pi-fw pi-search', command: () => { window.location = "https://github.com/primefaces/sigma-react" } },
        // {
        //     label: 'Todos', icon: 'pi pi-fw pi-file',
        //     permissoes: [2],
        //     items: [
        //         { label: 'Área', to: '/area' },
        //         { label: 'Clientes', to: '/clientes' },
        //         { label: 'Cliente Edit', to: '/clienteedit' },
        //         // { label: 'Empresas', to: '/empresa' },
        //         // { label: 'Gravidade', to: '/gravidade' },
        //         // { label: 'Itens', to: '/itens' },
        //         { label: 'Item Atividade Edit', to: '/itensatividades/:id' },
        //         // { label: 'Prioridades', to: '/prioridade' },
        //         // { label: 'Sistema Construtivo', to: '/sistemaconstrutivo' },
        //         { label: 'Sistema Construtivo Edit', to: '/scedit' },
        //         //{ label: 'Tipo Área', to: '/tipoarea' },
        //         // { label: 'Tipo de Área', to: '/tipoarea' },
        //         // { label: 'Tipo Atividade', to: '/tipoatividade' },
        //         // { label: 'Tipo Cliente', to: '/tipocliente' },
        //         // { label: 'Tipo Execução', to: '/tipoexecucao' },
        //         // { label: 'Tipo Executor', to: '/tipoexecutor' },
        //         // { label: 'Tipo Item', to: '/tipoitem' },
        //         // { label: 'Tipo Resposta CheckList', to: '/tiporespostachecklist' },
        //         // { label: 'Tipo Status', to: '/tipostatus' },
        //         { label: 'Tipo Status Navegação', to: '/tipostatusnavegacao' },
        //         // { label: 'Tipo Usuário', to: '/tipousuario' },
        //         { label: 'Unid Periodicidade', to: '/periodicidade' },
        //         { label: 'Usuários', to: '/usuario' }
        //     ]
        // },
    ];

    const addClass = (element, className) => {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    const removeClass = (element, className) => {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    const isDesktop = () => {
        return window.innerWidth > 1024;
    }

    const isSidebarVisible = () => {
        if (isDesktop()) {
            if (layoutMode === 'static')
                return !staticMenuInactive;
            else if (layoutMode === 'overlay')
                return overlayMenuActive;
            else
                return true;
        }

        return true;
    }

    // const logo = layoutColorMode === 'dark' ? 'assets/layout/images/logo-white.svg' : 'assets/layout/images/logo.svg';
    const logo = layoutColorMode === 'dark' ? 'assets/simp/simp-branco94.png' : 'assets/simp/simp94.png';

    const wrapperClass = classNames('layout-wrapper', {
        'layout-overlay': layoutMode === 'overlay',
        'layout-static': layoutMode === 'static',
        'layout-static-sidebar-inactive': staticMenuInactive && layoutMode === 'static',
        'layout-overlay-sidebar-active': overlayMenuActive && layoutMode === 'overlay',
        'layout-mobile-sidebar-active': mobileMenuActive,
        'p-input-filled': inputStyle === 'filled',
        'p-ripple-disabled': ripple === false
    });

    const sidebarClassName = classNames('layout-sidebar', {
        'layout-sidebar-dark': layoutColorMode === 'dark',
        'layout-sidebar-light': layoutColorMode === 'light'
    });

    const exibirMenu = () => {
        if (context.isLogger) {
            return (
                <React.Fragment>
                    <AppTopbar onToggleMenu={onToggleMenu} />
                </React.Fragment>
            )
        }
    }

    const loginClass = 'layout-wrapper layout-static layout-static-sidebar-inactive';

    return (
        // <UserProvider>

        // <div className={wrapperClass} onClick={onWrapperClick}>
        <div className={context.isLogger ? wrapperClass : loginClass} onClick={onWrapperClick}>
            {/* <AppTopbar onToggleMenu={onToggleMenu} /> */}
            {exibirMenu()}
            {/* isSidebarVisible() */}
            <CSSTransition classNames="layout-sidebar" timeout={{ enter: 200, exit: 200 }} in={context.isLogger ? isSidebarVisible() : false} unmountOnExit>
                <div ref={sidebar} className={sidebarClassName} onClick={onSidebarClick}>
                    <div className="layout-logo" style={{ cursor: 'pointer' }} onClick={() => history.push('/')}>
                        <img alt="Logo" src={logo} />
                    </div>
                    <AppProfile />
                    <AppMenu model={menu} onMenuItemClick={onMenuItemClick} />
                </div>
            </CSSTransition>

            <AppConfig rippleEffect={ripple} onRippleEffect={onRipple} inputStyle={inputStyle} onInputStyleChange={onInputStyleChange}
                layoutMode={layoutMode} onLayoutModeChange={onLayoutModeChange} layoutColorMode={layoutColorMode} onColorModeChange={onColorModeChange} />

            <div className="layout-main">
                <Switch>
                    <PrivateRoute path="/" exact component={Dashboard} />
                    <Route path="/formlayout" component={FormLayoutDemo} />
                    <Route path="/input" component={InputDemo} />
                    <Route path="/floatlabel" component={FloatLabelDemo} />
                    <Route path="/invalidstate" component={InvalidStateDemo} />
                    <Route path="/button" component={ButtonDemo} />
                    <Route path="/table" component={TableDemo} />
                    <Route path="/list" component={ListDemo} />
                    <Route path="/tree" component={TreeDemo} />
                    <Route path="/panel" component={PanelDemo} />
                    <Route path="/overlay" component={OverlayDemo} />
                    <Route path="/menu" component={MenuDemo} />
                    <Route path="/messages" component={MessagesDemo} />
                    <Route path="/file" component={FileDemo} />
                    <Route path="/chart" component={ChartDemo} />
                    <Route path="/misc" component={MiscDemo} />
                    <Route path="/display" component={DisplayDemo} />
                    <Route path="/elevation" component={ElevationDemo} />
                    <Route path="/flexbox" component={FlexBoxDemo} />
                    <Route path="/icons" component={IconsDemo} />
                    <Route path="/grid" component={GridDemo} />
                    <Route path="/spacing" component={SpacingDemo} />
                    <Route path="/typography" component={TypographyDemo} />
                    <Route path="/text" component={TextDemo} />
                    <Route path="/calendar" component={Calendar} />
                    <Route path="/timeline" component={TimelineDemo} />
                    <Route path="/crud" component={Crud} />
                    <Route path="/empty" component={EmptyPage} />
                    <Route path="/documentation" component={Documentation} />

                   
                    <PrivateRoute path="/area/:id/:idcliente" component={Area} />
                    <PrivateRoute path="/clientes" component={Clientes} />
                    <PrivateRoute path="/cliente/:id" component={ClienteEdit} />
                    <PrivateRoute path="/empresa" component={Empresas} />
                    <PrivateRoute path="/gravidade" component={Gravidade} />                    
                    <PrivateRoute path="/itens" component={Itens} />
                    <PrivateRoute path="/item/:id" component={ItemDetalhes} />
                    <PrivateRoute path="/itensatividades/:id" component={AtividadeDetalhes} />
                    <PrivateRoute exact path="/checklistitens" component={AtividadeChecklist} />
                    <PrivateRoute path="/prioridade" component={Prioridades} />
                    <PrivateRoute path="/sistemaconstrutivo" component={SistemaConstrutivo} />
                    <PrivateRoute path="/sistemaconstrutivos/:id" component={SistemaConstrutivoItens} />
                    <PrivateRoute path="/tipoarea" component={TipoArea} />
                    {/* <Route path="/tipoareacliente" component={TipoArea} /> */}
                    <PrivateRoute path="/tipoatividade" component={TipoAtividade} />
                    <PrivateRoute path="/tipocliente" component={TipoCliente} />
                    <PrivateRoute path="/tipoexecucao" component={TipoExecucao} />
                    <PrivateRoute path="/tipoexecutor" component={TipoExecutor} />
                    <PrivateRoute path="/tipoitem" component={TipoItem} />
                    <PrivateRoute path="/tiporespostachecklist" component={TipoRespostaCheckList} />
                    <PrivateRoute path="/tipostatus" component={TipoStatus} />
                    <PrivateRoute exact path="/tipostatusdetalhes/:id" component={TipoStatusDetalhes} />
                    <PrivateRoute path="/tipostatusnavegacao" component={TipoStatusNavegacao} />
                    <PrivateRoute path="/tipousuario" component={TipoUsuario} />
                    <PrivateRoute path="/periodicidade" component={UndPeriodicidade} />
                    <PrivateRoute path="/usuario" component={Usuarios} />
                    <PrivateRoute path="/scedit" component={SistemaConstrutivoEdit} permissao={[2, 8]}/>
                    <PrivateRoute path="/clientesengenharia" component={ClientesEngenharia} />
                    <PrivateRoute path="/clienteengenharia/:id" component={ClienteEditEngenharia} />
                    <PrivateRoute path="/trocarsenha" component={TrocarSenha} />
                    <PrivateRoute exact path="/ocorrencias" component={OcorrenciasClientes} />
                    <PrivateRoute exact path="/ocorrencias/dashboard/:id" component={DashboardOcorrencias} />
                    <PrivateRoute path="/ocorrenciasgravidadelist/:id/:gravidade" component={OcorrenciasGravidadeList} />
                    <PrivateRoute path="/ocorrenciastipostatuslist/:id/:tipostatus" component={OcorrenciasStatusList} />
                    <PrivateRoute path="/ocorrenciasareaslist/:id/:area" component={OcorrenciasAreasList} />
                    <PrivateRoute path="/ocorrenciasareasdetalheslist/:id/:area" component={OcorrenciasAreasDetalhesList} />                    
                    <PrivateRoute exact path="/ocorrencia/:id" component={OcorrenciaEdit} />                    
                    <PrivateRoute exact path="/checklistitemedit/:id" component={AtividadeChecklistEdit} />                    

                    <Route path="/login" component={Login} />
                    <Route path="/teste" component={PageTest} />
                    <Route path="/teste2" component={PageTest2} />
                    <Route path="/teste3" component={TesteAlan} />
                </Switch>
            </div>

            <AppFooter />

        </div>
        // </UserProvider>
    );

}

export default App;
