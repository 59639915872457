import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Panel } from '../components/panel/Panel';
import { Toast } from '../components/toast/Toast';
import api from '../service/api';
import { Checkbox } from '../components/checkbox/Checkbox';

export const TipoStatusNavegacaoDetalhes = (props) => {

    let emptyTipoStatusNavegacao = {
        id: null,
        idtipostatus: null,
        idtipostatusnavegacao: null,
    };


    const [listaTipoStatus, setListaTipoStatus] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const [id, setId] = useState(props.valorId);

    useEffect(() => {
        api.get(`/tipostatus/navegacao/${id}`).then((res) => {
            setListaTipoStatus(res.data);
        });
    }, []);

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaTipoStatus.length; i++) {
            if (listaTipoStatus[i].idtipostatus === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const changeCheckBox = (value) => {
        if (value.checked) {
            let _newTipoStatus = emptyTipoStatusNavegacao;
            _newTipoStatus.id = 0;
            _newTipoStatus.idtipostatus = id;
            _newTipoStatus.idtipostatusnavegacao = value.value.idtipostatus;
            let _listaTipoStatus = [...listaTipoStatus];
            const index = findIndexById(_newTipoStatus.idtipostatusnavegacao);
            api.post('/tipostatusnavegacao', _newTipoStatus).then(res => {
                if (res.status === 200) {
                    _listaTipoStatus[index].checked = 1;
                    _listaTipoStatus[index].id = res.data.id;
                    setListaTipoStatus(_listaTipoStatus);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                    console.log(error);
                })
        }
        else {
            let _listaTipoStatus = [...listaTipoStatus];
            const index = findIndexById(value.value.idtipostatus);            
            api.delete(`/tipostatusnavegacao/${_listaTipoStatus[index].id}`).then(res => {
                if (res.status === 200) {
                    _listaTipoStatus[index].checked = 0;
                    setListaTipoStatus(_listaTipoStatus); 
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                    console.log(error);
                })
        }
    }


    return (
        <div>
            <Toast ref={toast} />
            <Panel header='Nagegação' >
                {
                    listaTipoStatus.map((status) => {
                        return (
                            <div key={status.idtipostatus} className="p-field-checkbox">
                                <Checkbox
                                    inputId={status.idtipostatus.toLocaleString()}
                                    name="status"
                                    value={status}
                                    onChange={changeCheckBox}
                                    checked={status.checked === 1} />
                                <label htmlFor={status.idtipostatus}>{status.tipostatus} </label>
                            </div>
                        )
                    })
                }
            </Panel>

        </div>

    );

}