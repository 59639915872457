import React, { useContext, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useHistory } from "react-router-dom";
import { UserContext } from '../context';
import { Toast } from 'primereact/toast';
import api from '../service/api';

export const TrocarSenha = () => {
    const history = useHistory();
    const toast = useRef(null);
    const context = useContext(UserContext);

    const [myUser, setUser] = useState();
    const [trocarSenha, setTrocarSenhar] = useState(false);

    const handleInputChange = ev => {
        let name = [ev.target.name];
        let value = ev.target.value;
        setUser(
            { ...myUser, [name]: value }
        )
    }


    const handleSubmitTrocarSenha = ev => {
        ev.preventDefault();

        if (myUser.senha2 === myUser.senha3) {
            let usuario = { 'idusuario': 0, 'senha': '' };
            usuario.idusuario = parseInt(sessionStorage.getItem('idusuario'));
            usuario.senha = myUser.senha2;
            console.log(usuario);
            console.log(myUser);
            api.post('/usuarios/trocarsenha', usuario)
                .then(res => {
                    if (res.status === 200) {
                        sessionStorage.removeItem('trocarsenha');
                        context.alterLogin(true);
                        history.push('/');
                        window.location.reload();

                    }
                })
        }
        else {
            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'As senhas digitadas não conferem', life: 3000 });
        }


    }

    return (


        <div>
            <Toast ref={toast} />
            <div className="p-p-4 p-d-flex p-jc-center">
                <div className="card p-mr-2">
                    <div className="p-d-flex p-jc-center">
                        <img className="photo" src={`assets/simp/logo-principal.png`}
                            style={{
                                width: '100px'
                            }}></img>
                    </div>
                    <div >
                        <h2 style={{ textAlign: "center" }}>Bem vindo, {sessionStorage.getItem('usuario')}.</h2>
                        <h4 style={{ textAlign: "center" }}>Digite uma nova senha para conectar-se à rede SIMP. </h4>
                        {/* <h4 style={{ textAlign: "center" }}>Sistema destinado aos parceiros. </h4> */}

                        <br></br>
                        <form onSubmit={handleSubmitTrocarSenha}>
                            <div className='p-ai-center'>
                                <div className="p-mr-2">
                                    {/* <span className="p-float-label"> */}
                                    {/* <label htmlFor="usuario">E-mail</label> */}
                                    <br></br>
                                    <InputText id="senha2" type="password" name="senha2" placeholder='Senha' onChange={handleInputChange}
                                        className="p-inputtext-lg p-d-block p-mx-auto" style={{ width: "370px" }} />
                                    {/* <br></br> */}
                                    {/* </span> */}
                                </div>

                                <div className="p-mr-2">
                                    {/* <span className="p-float-label"> */}
                                    {/* <label htmlFor="senha">Senha</label> */}
                                    <br></br>
                                    <InputText id="senha3" type="password" name="senha3" placeholder='Senha' onChange={handleInputChange}
                                        className="p-inputtext-lg p-d-block p-mx-auto" style={{ width: "370px" }} />

                                    {/* </span> */}

                                </div>
                            </div>
                            <div className="p-mr-2">
                                <br />
                                <br></br>
                                <Button type="submit" label="Entrar" className="p-button-lg p-d-block p-mx-auto" style={{ width: "370px" }} />
                                <br></br>
                            </div>

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}