import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { format, parseISO } from "date-fns";
import { Toast } from '../components/toast/Toast';
import api from '../service/api';
import classNames from 'classnames';
import { Panel } from '../components/panel/Panel';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Button } from '../components/button/Button';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import { Dropdown } from '../components/dropdown/Dropdown';
import { Checkbox } from '../components/checkbox/Checkbox';
import { Link } from 'react-router-dom';
import { InputNumber } from 'primereact/inputnumber';
import { InputTextarea } from '../components/inputtextarea/InputTextarea';

export const AtividadeChecklist = (props) => {

    let emptyChecklistItem = {
        idchecklistitens: null,
        idatividade: null,
        pergunta: '',
        respostapadrao: null,
        legendaocorrencia: '',
        ordem: '',
        idgravidadeocorrencia: null,
        dataatualizacao: null,
        ativo: true,
        tiporespostachecklist: '',
        recomendacoestecnicas: '',
        enquadramentostecnicos: '',
    };

    const [idAtividade, setIdAtividade] = useState(props.valorId);
    const [checklistItem, setChecklistItem] = useState(emptyChecklistItem);
    const [listaChecklistItens, setListaChecklistItens] = useState(null);
    const [checklistItemDialog, setchecklistItemDialog] = useState(false);
    const [deleteChecklistDialog, setDeleteChecklistItemDialog] = useState(false);
    const [listaGravidades, setListaGravidades] = useState(null);
    const [listaRespostas, setListaRespostas] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {

        // Lista com todos os Checklists da Atividade
        api.get(`/checklistitens/atividade/${idAtividade}`).then((res) => {
            setListaChecklistItens(res.data);
            //console.table(listaChecklistItens);
        });
        api.get(`/gravidade`).then((res) => {
            setListaGravidades(res.data);
        });
        api.get(`/tiporespostachecklist`).then((res) => {
            setListaRespostas(res.data);
        });


        /* api.get(`/tipoatividade`).then((res) => {
            setListaTiposAtividade(res.data);
        }); */
    }, []);

    const newChecklistItem = () => {

        setChecklistItem(emptyChecklistItem);
        setSubmitted(false);
        setchecklistItemDialog(true);
    }

    const saveChecklistItem = () => {

        setSubmitted(true);

        if (checklistItem.pergunta.trim() && checklistItem.respostapadrao && checklistItem.legendaocorrencia &&
            checklistItem.ordem && checklistItem.idgravidadeocorrencia && checklistItem.tiporespostachecklist) {
            let _listaChecklistItens = [...listaChecklistItens];
            let _checklistItem = { ...checklistItem };

            if (_checklistItem.idchecklistitens) {
                console.table(_checklistItem)
                const index = findIndexById(_checklistItem.idchecklistitens);
                api.put('/checklistitens', _checklistItem).then(res => {
                    if (res.status === 200) {
                        _listaChecklistItens[index] = _checklistItem;
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                        setListaChecklistItens(_listaChecklistItens);
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _checklistItem.idchecklistitens = 0;
                _checklistItem.idatividade = idAtividade;
                _checklistItem.dataatualizacao = format(new Date(), 'yyyy-MM-dd');
                console.table(_checklistItem)

                api.post('/checklistitens', _checklistItem).then(res => {
                    if (res.status === 200) {
                        _checklistItem.idchecklistitens = res.data.id;
                        _listaChecklistItens.push(_checklistItem);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                        setListaChecklistItens(_listaChecklistItens);
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            // setListaChecklistItens(_listaChecklistItens);
            setchecklistItemDialog(false);
            setChecklistItem(emptyChecklistItem);
        }
    }

    const deleteChecklistItem = () => {
        api.delete(`/checklistitens/${checklistItem.idchecklistitens}`).then(res => {
            if (res.status === 200) {
                let _checklistItem = listaChecklistItens.filter(val => val.idchecklistitens !== checklistItem.idchecklistitens);
                setListaChecklistItens(_checklistItem);
                setDeleteChecklistItemDialog(false);
                setChecklistItem(emptyChecklistItem);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaChecklistItens.length; i++) {
            if (listaChecklistItens[i].idchecklistitens === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const editChecklist = (checklistItem) => {
        setChecklistItem({ ...checklistItem });
        setchecklistItemDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setchecklistItemDialog(false);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div><Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={newChecklistItem} /></div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter({ globalFilter: e.target.value })} placeholder="Search..." />
                </span>
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editChecklist(rowData)} /> */}
                <Link to={`/checklistitemedit/${rowData[`idchecklistitens`]}`}><Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" /></Link>                
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteChecklistItem(rowData)} />
            </React.Fragment>
        );
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _checklistItem = { ...checklistItem };
        _checklistItem[`${name}`] = val;       
        setChecklistItem(_checklistItem);
    }

    const onRespostaChange = (e) => {
        let _checklistItem = { ...checklistItem };
        _checklistItem.respostapadrao = e.value;

        for (let i = 0; i < listaRespostas.length; i++) {
            if (listaRespostas[i].idtiporespostachecklist === e.value) {
                _checklistItem.tiporespostachecklist = listaRespostas[i].tiporespostachecklist;
                break;
            }
        }

        setChecklistItem(_checklistItem);
    }

    const onGravidadeChange = (e) => {
        let _checklistItem = { ...checklistItem };
        _checklistItem.idgravidadeocorrencia = e.value;
        setChecklistItem(_checklistItem);
    }

    const onAtivoChange = (e) => {
        let _checklistItem = { ...checklistItem };
        _checklistItem.ativo = e.checked;
        setChecklistItem(_checklistItem);
    }

    const confirmDeleteChecklistItem = (checklistItem) => {
        setChecklistItem(checklistItem);
        setDeleteChecklistItemDialog(true);
    }

    const hideDeleteDialog = () => {
        setDeleteChecklistItemDialog(false);
    }

    const checklistDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveChecklistItem} />
        </>
    );

    const deleteClientesDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteChecklistItem} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">

                <Toast ref={toast} />

                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                <Panel>
                    <DataTable
                        ref={dt} value={listaChecklistItens} dataKey="idatividade"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive p-datatable-customers"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Itens" globalFilter={globalFilter}
                        emptyMessage="Nenhum Item encontrado." sortMode="multiple" >
                        <Column field="idchecklistitens" header="Id" sortable style={{ width: '10%' }} />
                        <Column field="pergunta" header="Pergunta" sortable ></Column>
                        <Column field="tiporespostachecklist" header="Resposta Padrão" sortable style={{ textAlign: 'center' }} ></Column>
                        <Column field="ordem" header="Ordem" sortable style={{ width: '100px', textAlign: 'center' }}></Column>
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                    </DataTable>
                </Panel>

                <Dialog visible={checklistItemDialog} style={{ width: '550px' }} header="Checklist Item" modal className="p-fluid" footer={checklistDialogFooter} onHide={hideDialog}>
                    <div className="p-field">
                        <label htmlFor="pergunta">Pergunta</label>
                        <InputText id="pergunta" value={checklistItem.pergunta} onChange={(e) => onInputChange(e, 'pergunta')} required className={classNames({ 'p-invalid': submitted && !checklistItem.pergunta })} />
                        {submitted && !checklistItem.pergunta && <small className="p-invalid">Pergunta é requerido.</small>}
                    </div>
                    <div className="p-field" >
                        <label htmlFor="idtiporespostachecklist">Resposta Padrão</label>
                        <Dropdown
                            inputId="respostapadrao"
                            optionValue="idtiporespostachecklist"
                            optionLabel="tiporespostachecklist"
                            value={checklistItem.respostapadrao}
                            options={listaRespostas}
                            onChange={(e) => onRespostaChange(e, 'respostapadrao')}
                            placeholder="Selecione"
                            required
                            className={classNames({ 'p-invalid': submitted && !checklistItem.respostapadrao })} />
                        {submitted && !checklistItem.respostapadrao && <small className="p-invalid">Resposta Padrão é requerido.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="legendaocorrencia">Legenda da Ocorrência</label>
                        <InputText id="legendaocorrencia" value={checklistItem.legendaocorrencia} onChange={(e) => onInputChange(e, 'legendaocorrencia')} required className={classNames({ 'p-invalid': submitted && !checklistItem.legendaocorrencia })} />
                        {submitted && !checklistItem.legendaocorrencia && <small className="p-invalid">Legenda da Ocorrência é requerido.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="ordem">Ordem</label>
                        <InputText id="ordem" type='number' value={checklistItem.ordem} onChange={(e) => onInputChange(e, 'ordem')} required className={classNames({ 'p-invalid': submitted && !checklistItem.ordem })} />
                        {/* <InputNumber id="ordem" value={checklistItem.ordem} onChange={(e) => onInputChange(e, 'ordem')} required className={classNames({ 'p-invalid': submitted && !checklistItem.ordem })} /> */}
                        {submitted && !checklistItem.ordem && <small className="p-invalid">Ordem é requerido.</small>}
                    </div>
                    <div className="p-field" >
                        <label htmlFor="idgravidadeocorrencia">Gravidade</label>
                        <Dropdown
                            inputId="idgravidadeocorrencia"
                            optionValue="idgravidade"
                            optionLabel="gravidade"
                            value={checklistItem.idgravidadeocorrencia}
                            options={listaGravidades}
                            onChange={(e) => onGravidadeChange(e, 'idgravidadeocorrencia')}
                            placeholder="Selecione"
                            required
                            className={classNames({ 'p-invalid': submitted && !checklistItem.idgravidadeocorrencia })} />
                        {submitted && !checklistItem.idgravidadeocorrencia && <small className="p-invalid">Gravidade é requerido.</small>}
                    </div>
                    <div className="p-field">
                        <label htmlFor="recomendacoestecnicas">Recomendações Técnicas</label>
                        <InputTextarea id="recomendacoestecnicas"  value={checklistItem.recomendacoestecnicas} onChange={(e) => onInputChange(e, 'recomendacoestecnicas')} />
                    </div>                    
                    <div className="p-field">
                        <label htmlFor="enquadramentostecnicos">Enquadramento Técnico</label>
                        <InputTextarea id="enquadramentostecnicos"  value={checklistItem.enquadramentostecnicos} onChange={(e) => onInputChange(e, 'enquadramentostecnicos')} />
                    </div>                                        
                    <div className="p-d-flex" >
                        <Checkbox inputId="ativo" name="ativo" value={checklistItem.ativo}
                            checked={checklistItem.ativo} onChange={(e) => onAtivoChange(e)} />
                        <label htmlFor="ativo" className="p-ml-2 p-mr-2">Ativo</label>
                    </div>
                </Dialog>

                <Dialog visible={deleteChecklistDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteClientesDialogFooter} onHide={confirmDeleteChecklistItem}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {checklistItem && <span>Tem certeza que deseja excluir <b>{checklistItem.pergunta}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    );
}