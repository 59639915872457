import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { Panel } from '../components/panel/Panel';
import { InputMask } from '../components/inputmask/InputMask';


export const ClientesRepresentantes = (props) => {

    let emptyRepresentante = {
        idrepresentante: 0,
        idcliente: 0,
        nome: null,
        cpf: null,
        telefone: null,
        cargo: null,
        email: ""
    }

    const [idCliente, setIdCliente] = useState(props.valorId);
    const [edicao, setedicao] = useState(props.edicao);
    const [representante, setRepresentante] = useState(emptyRepresentante);
    const [listaRepresentantes, setListaRepresentantes] = useState(null);
    const toast = useRef(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [representanteDialog, setRepresentanteDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [deleteRepresentanteDialog, setDeleteRepresentanteDialog] = useState(false);

    useEffect(() => {

        api.get(`/clienterepresentantelegal/cliente/${idCliente}`).then((res) => {
            setListaRepresentantes(res.data);
        });


    }, []);

    const addRepresentante = () => {
        setSubmitted(true);

        if (representante.nome && representante.telefone && representante.cargo) {
            let _listaRepresentantes = [...listaRepresentantes];
            let _representante = { ...representante };
            if (!representante.idrepresentante) { //se não possui id
                _representante.idrepresentante = 0;
                _representante.idcliente = idCliente;

                api.post('/clienterepresentantelegal', _representante).then(res => {
                    if (res.status === 200) {
                        _representante.idrepresentante = res.data.id;
                        setRepresentante(_representante);
                        console.log('id ' + res.data.id);
                        _listaRepresentantes.push(_representante);
                        setListaRepresentantes(_listaRepresentantes);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                const index = findIndexById(_representante.idrepresentante);
                api.put('/clienterepresentantelegal', _representante).then(res => {
                    if (res.status === 200) {
                        _listaRepresentantes[index] = _representante;
                        setListaRepresentantes(_listaRepresentantes);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            // setListaAtividades(_listaAtividades);
            setRepresentanteDialog(false);
            setRepresentante(emptyRepresentante);
        }
    }


    const newRepresentante = () => {
        setRepresentanteDialog(true);
    }


    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaRepresentantes.length; i++) {
            if (listaRepresentantes[i].idrepresentante === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div><Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={newRepresentante} /></div>
            </React.Fragment>
        )
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) =>setGlobalFilter(e.target.value)} placeholder="Procurar..." />
                </span>
            </React.Fragment>
        )
    }
   
    const hideAddRepresentanteDialog = () => {
        setSubmitted(false);
        setRepresentanteDialog(false);
    }

    const representanteDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideAddRepresentanteDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={addRepresentante} />
        </>
    );
       
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _representante = { ...representante };
        _representante[`${name}`] = val;
        setRepresentante(_representante);
    }

    const editRepresentante = (representanteItem) => {
        setRepresentante({ ...representanteItem });
        setRepresentanteDialog(true);
    }

    const confirmDeleteRepresentante = (representanteItem) => {
        setRepresentante(representanteItem);
        setDeleteRepresentanteDialog(true);
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                {/* <Link to={`/itensatividades/${rowData['idatividade']}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link> */}
                <div hidden={!edicao}>
                    <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editRepresentante(rowData)} />
                    <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteRepresentante(rowData)} />
                </div>
            </React.Fragment>
        );
    }

    const hideDeleteDialog = () => {
        setDeleteRepresentanteDialog(false);
    }

    const deleteRepresentante = () => {
        api.delete(`/clienterepresentantelegal/${representante.idrepresentante}`).then(res => {
            console.log('status'+ res.status);
            if (res.status === 200) {
                let _representante = listaRepresentantes.filter(val => val.idrepresentante !== representante.idrepresentante);
                setListaRepresentantes(_representante);                
                setDeleteRepresentanteDialog(false);                
                setRepresentante(emptyRepresentante);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
               
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);

            })
    }

    const deleteRepresentanteDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteRepresentante} />
        </>
    );

    // const confirmDeleteRepresentante = (checklistItem) => {
    //     setChecklistItem(checklistItem);
    //     setDeleteChecklistItemDialog(true);
    // }

   



    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">

                <Toast ref={toast} />
                <div hidden={!edicao}>
                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                </div>             
                <Panel header="Representantes" toggleable collapsed={!edicao}>
                    <DataTable
                        ref={dt} value={listaRepresentantes} dataKey="idrepresentante"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Representantes" globalFilter={globalFilter}
                        emptyMessage="Nenhum Representante encontrado." /*header={'Representantes'}*/ sortMode="multiple" >
                        {/* <Column field="idrepresentante" header="Id" style={{ width: '6%' }} /> */}
                        <Column field="nome" header="Nome" ></Column>
                        <Column field="cargo" header="Cargo" style={{ width: '20%' }} ></Column>
                        <Column field="telefone" header="Telefone" style={{ width: '12%' }} ></Column>                        
                        <Column field="email" header="E-mail" style={{ width: '20%' }}  ></Column>                                         
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '100px' }} ></Column>
                    </DataTable>
                </Panel>

                <Dialog visible={representanteDialog} style={{ width: '450px' }} header="Representante" modal className="p-fluid" footer={representanteDialogFooter} onHide={hideAddRepresentanteDialog}>
                    <div className="p-field">
                        <label htmlFor="nome">Nome</label>
                        <InputText id="nome" value={representante.nome} onChange={(e) => onInputChange(e, 'nome')} required className={classNames({ 'p-invalid': submitted && !representante.nome })} />
                        {submitted && !representante.nome && <small className="p-invalid">Nome é requerido.</small>}
                    </div>

                    <div className="p-field">
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-6">
                                <label htmlFor="telefone" className="p-mr-2">Telefone</label>
                                {/* <InputText id="telefone" value={representante.telefone} onChange={(e) => onInputChange(e, 'telefone')} required className={classNames({ 'p-invalid': submitted && !representante.telefone })} /> */}
                                <InputMask id="telefone" value={representante.telefone} onChange={(e) => onInputChange(e, 'telefone')}
                                    required className={classNames({ 'p-invalid': submitted && !representante.telefone })}
                                    disabled={!edicao} mask={"(99)99999-9999"}
                                    onFocus={(e) => e.target.select()} readonly={!edicao} />
                            </div>
                            <div className="p-field p-col-6">
                                <label htmlFor="cpf" className="p-mr-2">CPF</label>
                                {/* <InputText id="cpf" value={representante.cpf} onChange={(e) => onInputChange(e, 'cpf')} /> */}
                                <InputMask id="cpf" value={representante.cpf} onChange={(e) => onInputChange(e, 'cpf')}
                                    disabled={!edicao} mask={"999.999.999-99"}
                                    onFocus={(e) => e.target.select()} readonly={!edicao} />
                            </div>
                        </div>
                    </div>
                    <div className="p-field">
                        <label htmlFor="cargo">Cargo</label>
                        <InputText id="cargo" value={representante.cargo} onChange={(e) => onInputChange(e, 'cargo')} required className={classNames({ 'p-invalid': submitted && !representante.cargo })} />
                    </div>
                    <div className="p-field">
                        <label htmlFor="email">E-mail</label>
                        <InputText id="email" value={representante.email} onChange={(e) => onInputChange(e, 'email')} />
                    </div>

                </Dialog>

                <Dialog visible={deleteRepresentanteDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteRepresentanteDialogFooter} onHide={confirmDeleteRepresentante}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {representante && <span>Tem certeza que deseja excluir <b>{representante.nome}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        </div>
    )
}
