import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import classNames from 'classnames';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import { Button } from '../components/button/Button';
import { Dropdown } from '../components/dropdown/Dropdown';
import { InputMask } from '../components/inputmask/InputMask';
import { Calendar } from '../components/calendar/Calendar';

export const Clientes = () => {

    let emptyCliente = {
        idcliente: null,
        idempresa: null,
        razaosocial: '',
        nomefantasia: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        // cidade: '',
        // uf: '',
        idtipocliente: 0,
        cnpj: '',
        datahabitese: null,
        cep: '',
        idestado: 0,
        idcidade: 0
    };

    const [cliente, setCliente] = useState(emptyCliente);
    const [listaClientes, setListaClientes] = useState(null);
    const [clientesDialog, setClienteDialog] = useState(false);
    const [deleteClientesDialog, setDeleteClientesDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [listaTiposClientes, setListaTiposCliente] = useState(null);
    const [listaEstados, setListaEstados] = useState(null);
    const [listaCidades, setListaCidades] = useState(null);
    const [edicao, setEdicao] = useState(false);


    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/estados`).then((res) => {
            setListaEstados(res.data);
        });

        api.get(`/clientes/empresa/${sessionStorage.getItem('idempresa')}`).then((res) => {
            setListaClientes(res.data);
        });
    }, []);

    const carregarDropdowns = () => {
        api.get('/tipocliente').then(res => {
            setListaTiposCliente(res.data);
        })
    }
    const openNew = () => {
        carregarDropdowns();
        setCliente(emptyCliente);
        setSubmitted(false);
        setClienteDialog(true);
    }

    const saveCliente = () => {
        setSubmitted(true);
        if (cliente.razaosocial && cliente.nomefantasia && cliente.idcidade && cliente.idestado &&
            cliente.cnpj && cliente.datahabitese) {
            let _listaClientes = [...listaClientes];
            let _cliente = { ...cliente };

            if (_cliente.idcliente) {
                const index = findIndexById(_cliente.idcliente);
                api.put('/clientes', _cliente).then(res => {
                    if (res.status === 200) {
                        _listaClientes[index] = _cliente;
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                        setListaClientes(_listaClientes);
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _cliente.idcliente = 0;
                _cliente.idempresa = sessionStorage.getItem('idempresa');
                console.log(_cliente)
                api.post('/clientes', _cliente).then(res => {
                    if (res.status === 200) {
                        _cliente.idcliente = res.data.id;
                        _listaClientes.push(_cliente);
                        setCliente(_cliente);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                        setListaClientes(_listaClientes);
                        setEdicao(true);
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }

            setClienteDialog(false);
            setCliente(emptyCliente);
        }
    }

    const editCliente = (cliente) => {
        setCliente({ ...cliente });
        setClienteDialog(true);
    }

    const deleteCliente = () => {
        api.delete(`/clientes/${cliente.idcliente}`).then(res => {
            if (res.status === 200) {
                let _cliente = listaClientes.filter(val => val.idcliente !== cliente.idcliente);
                setListaClientes(_cliente);
                setDeleteClientesDialog(false);
                setCliente(emptyCliente);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteCliente = (cliente) => {
        setCliente(cliente);
        setDeleteClientesDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setClienteDialog(false);
    }

    const confirmDeleteClientehideDeleteClienteDialog = () => {
        setDeleteClientesDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaClientes.length; i++) {
            if (listaClientes[i].idcliente === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _cliente = { ...cliente };
        _cliente[`${name}`] = val;
        setCliente(_cliente);
    }

    const onDropDownChange = (e) => {
        let _cliente = { ...cliente };
        _cliente.idtipocliente = e.value;
        setCliente(_cliente);
    }

    const onCalendarChange = (e) => {
        let _cliente = { ...cliente };
        _cliente.datahabitese = e.value;
        setCliente(_cliente);
    }

    const onDropDownCidade = (e) => {
        let _cliente = { ...cliente };
        _cliente.idcidade = e.value;
        setCliente(_cliente);
    }

    const onDropDownEstado = (e) => {
        let _cliente = { ...cliente };
        _cliente.idestado = e.value;


        api.get(`/cidades/uf/` + _cliente.idestado).then((res) => {
            setListaCidades(res.data);
        });
        setCliente(_cliente);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaClientes);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaClientes');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Link to={`/cliente/${rowData[`idcliente`]}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link>
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteCliente(rowData)} />
            </React.Fragment>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Clientes</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const clientesDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveCliente} />
        </>
    );

    const deleteClientesDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={confirmDeleteClientehideDeleteClienteDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteCliente} />
        </>
    );

    const consultaCEP = () => {
        let cep = cliente.cep;
        if (cep.trim()) {
            let cepAux = cep.replace('.', '');
            cepAux = cepAux.replace('-', '');
            if (cepAux.length === 8) {

                api.get(`consultacep/${cepAux}`)
                    .then(res => {
                        if (res.status === 200) {
                            if (typeof res.data.erro === "undefined") {
                                let _cliente = { ...cliente };
                                let ibge = res.data.ibge;
                                api.get(`/cidades/ibge/${ibge}`)
                                    .then(cidade => {
                                        api.get(`/cidades/uf/${cidade.data.idestado}`)
                                            .then(res => {
                                                setListaCidades(res.data);
                                            });
                                        _cliente.idcidade = cidade.data.idcidade;
                                        _cliente.idestado = cidade.data.idestado;
                                    })
                                _cliente.logradouro = res.data.logradouro.toUpperCase();
                                _cliente.bairro = res.data.bairro.toUpperCase();
                                // _cliente.cidades = res.data.ibge;
                                setCliente(_cliente);
                            }
                            else {
                                this.toast.show({ severity: 'error', summary: 'Erro', detail: `O CEP ${cep} não foi localizado`, life: 3000 });
                            }
                        }
                    })
                    .catch(error => {
                        this.toast.show({ severity: 'error', summary: 'Erro', detail: `Ocorreu algum problema ao tentar consultar o CEP ${cep}`, life: 3000 });
                        console.log(error.data);
                    })
            }
            else {
                alert(`CEP ${cep} inválido`)
            }
        }
    }

    if (edicao) {
        return <Redirect push to={`/cliente/${cliente.idcliente}`} />;
    }
    else {
        return (
            <div className="p-grid crud-demo">
                <div className="p-col-12">
                    <div className="card">
                        <Toast ref={toast} />
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                        <DataTable
                            ref={dt} value={listaClientes} dataKey="idcliente"
                            paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Clientes" globalFilter={globalFilter}
                            emptyMessage="Nenhum Cliente encontrado." header={header} sortMode="multiple" >
                            <Column field="idcliente" header="Id" style={{ width: '6%' }} sortable />
                            <Column field="nomefantasia" header="Nome Fantasia" sortable ></Column>
                            <Column field="cidade" header="Cidade" style={{ width: '12%' }} sortable ></Column>
                            <Column field="uf" header="UF" style={{ width: '5%' }} sortable ></Column>
                            <Column field="cnpj" header="CNPJ" style={{ width: '140px' }} ></Column>
                            <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '100px' }} ></Column>
                        </DataTable>
                        <Dialog visible={clientesDialog} style={{ width: '550px' }} header="Clientes" modal className="p-fluid" footer={clientesDialogFooter} onHide={hideDialog}>
                            <div className="p-field">
                                <label htmlFor="razaosocial">Razão Social</label>
                                <InputText id="razaosocial" value={cliente.razaosocial} onChange={(e) => onInputChange(e, 'razaosocial')} required className={classNames({ 'p-invalid': submitted && !cliente.razaosocial })} />
                                {submitted && !cliente.razaosocial && <small className="p-invalid">Razão Social é requerido.</small>}
                            </div>
                            <div className="p-field">
                                <label htmlFor="nomefantasia">Nome Fantasia</label>
                                <InputText id="nomefantasia" value={cliente.nomefantasia} onChange={(e) => onInputChange(e, 'nomefantasia')} required className={classNames({ 'p-invalid': submitted && !cliente.nomefantasia })} />
                                {submitted && !cliente.nomefantasia && <small className="p-invalid">Nome Fantasia é requerido.</small>}
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-8">
                                    <label htmlFor="cnpj">CNPJ</label>
                                    <InputMask id="cnpj" value={cliente.cnpj} onChange={(e) => onInputChange(e, 'cnpj')} mask="99.999.999/9999-99" className={classNames({ 'p-invalid': submitted && !cliente.cnpj })} ></InputMask>
                                    {submitted && !cliente.cnpj && <small className="p-invalid">CNPJ é requerido.</small>}
                                </div>
                                <div className="p-field p-col-4">
                                    <label htmlFor="cep">CEP</label>
                                    <div className="p-inputgroup">
                                        <InputMask id="cep" value={cliente.cep} onChange={(e) => onInputChange(e, 'cep')}
                                            mask="99.999-999" onFocus={(e) => e.target.select()} />
                                        <Button icon="pi pi-search" className="p-button" onClick={consultaCEP} />
                                    </div>
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field  p-col-10">
                                    <label htmlFor="logradouro">Logradouro</label>
                                    <InputText id="logradouro" value={cliente.logradouro} onChange={(e) => onInputChange(e, 'logradouro')} required className={classNames({ 'p-invalid': submitted && !cliente.logradouro })} />
                                    {submitted && !cliente.logradouro && <small className="p-invalid">Logradouro é requerido.</small>}
                                </div>
                                <div className="p-field  p-col-2">
                                    <label htmlFor="numero">Número</label>
                                    <InputText id="numero" value={cliente.numero} onChange={(e) => onInputChange(e, 'numero')} required className={classNames({ 'p-invalid': submitted && !cliente.numero })} />
                                    {submitted && !cliente.numero && <small className="p-invalid">Número é requerido.</small>}
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-6">
                                    <label htmlFor="bairro">Bairro</label>
                                    <InputText id="bairro" value={cliente.bairro} onChange={(e) => onInputChange(e, 'bairro')} required className={classNames({ 'p-invalid': submitted && !cliente.bairro })} />
                                    {submitted && !cliente.bairro && <small className="p-invalid">Bairro é requerido.</small>}
                                </div>
                                <div className="p-field  p-col-6">
                                    <label htmlFor="complemento">Complemento</label>
                                    <InputText id="complemento" value={cliente.complemento} onChange={(e) => onInputChange(e, 'complemento')} />
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-6">
                                    <label htmlFor="idestado">Estado</label>
                                    <Dropdown id="idestado" value={cliente.idestado} options={listaEstados} onChange={(e) => onDropDownEstado(e)}
                                        optionLabel="estado" optionValue='idestado' placeholder="Selecione" filter showClear required className={classNames({ 'p-invalid': submitted && !cliente.idestado })} />
                                    {submitted && !cliente.idestado && <small className="p-invalid">Estado é requerido.</small>}
                                </div>
                                <div className="p-field p-col-6">
                                    <label htmlFor="idcidade">Cidade</label>
                                    {/* <InputText id="idcidade" value={cliente.idcidade} onChange={(e) => onInputChange(e, 'cidade')} required className={classNames({ 'p-invalid': submitted && !cliente.cidade })} /> */}
                                    <Dropdown id="idcidade" value={cliente.idcidade} options={listaCidades} onChange={(e) => onDropDownCidade(e)}
                                        optionLabel="cidade" optionValue='idcidade' placeholder="Selecione" filter showClear required className={classNames({ 'p-invalid': submitted && !cliente.idcidade })} />
                                    {submitted && !cliente.idcidade && <small className="p-invalid">Cidade é requerido.</small>}
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-12">
                                    <label htmlFor="idtipocliente">Tipo de cliente</label>
                                    <Dropdown id="idtipocliente" value={cliente.idtipocliente} options={listaTiposClientes} onChange={(e) => onDropDownChange(e)}
                                        optionLabel="tipocliente" optionValue='idtipocliente' placeholder="Selecione" required className={classNames({ 'p-invalid': submitted && !cliente.idtipocliente })} />
                                    {submitted && !cliente.idtipocliente && <small className="p-invalid">Tipo de cliente é requerido.</small>}
                                </div>
                            </div>
                            <div className="p-fluid p-formgrid p-grid">
                                <div className="p-field p-col-4">
                                    <label htmlFor="datahabitese">Data de Habite-se</label>
                                    {/* <InputMask id="datahabitese" value={cliente.datahabitese} onChange={(e) => onInputChange(e, 'datahabitese')} mask="99/99/9999" slotChar="mm/dd/aaaa" className={classNames({ 'p-invalid': submitted && !cliente.datahabitese })} ></InputMask> */}
                                    <Calendar id="datahabitese" showIcon dateFormat="dd/mm/yy" value={cliente.datahabitese} onChange={(e) => onCalendarChange(e)} > </Calendar>
                                    {submitted && !cliente.datahabitese && <small className="p-invalid">Data de Habite-se é requerido.</small>}
                                </div>
                            </div>
                        </Dialog>
                        <Dialog visible={deleteClientesDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteClientesDialogFooter} onHide={confirmDeleteClientehideDeleteClienteDialog}>
                            <div className="confirmation-content">
                                <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                {cliente && <span>Tem certeza que deseja excluir <b>{cliente.nomefantasia}</b>?</span>}
                            </div>
                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}
