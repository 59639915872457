import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { Dropdown } from '../components/dropdown/Dropdown';
import validator from 'validator'

export const Usuarios = () => {

    let emptyUsuarios = {
        idusuario: null,
        nome: '',
        email: '',
        senha: null,
        trocarsenha: true
    };

    let emptyUsuariosEmpresa = {
        idempresasusuarios: 0,
        idempresa: 0,
        idusuario: 0,
        idtipousuario: null,
        ativo: true
    };

    let emptyUsarioComposto = {
        idusuario: null,
        nome: '',
        email: '',
        idtipousuario: null,
        idempresasusuarios: null,
        ativo: true,
        idempresa: sessionStorage.getItem('idempresa')
    }


    const [usuarios, setUsuarios] = useState(emptyUsuarios);
    const [usuarioComposto, setUsuarioComposto] = useState(emptyUsarioComposto);
    const [usuarioEmpresa, setUsuarioEmpresa] = useState(emptyUsuariosEmpresa);
    const [listaUsuarios, setListaUsuarios] = useState(null);
    const [listaTipoUsuario, setListaTipoUsuario] = useState(null);
    const [usuariosDialog, setUsuariosDialog] = useState(false);
    const [deleteUsuariosDialog, setDeleteUsuariosDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [EmailInvalido, setEmailInvalido] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/empresasusuarios/${sessionStorage.getItem('idempresa')}`).then((res) => {
            setListaUsuarios(res.data);
        });

        api.get(`/tipousuario/tipo/e`).then((res) => {
            setListaTipoUsuario(res.data);
        });
    }, []);

    const openNew = () => {
        setUsuarioComposto(emptyUsarioComposto);
        setSubmitted(false);
        setUsuariosDialog(true);
    }

    const saveUsuarios = () => {
        setSubmitted(true);


        if (usuarioComposto.nome.trim() && usuarioComposto.email.trim() && (validator.isEmail(usuarioComposto.email)) && usuarioComposto.idtipousuario) {
            // console.log(validator.isEmail(usuarios.email))
            // if (validator.isEmail(usuarios.email)) {
            // setEmailInvalido(false);
            let _listaUsuarios = [...listaUsuarios];
            let _usuarios = { ...usuarios };
            let _usuarioEmpresa = { ...usuarioEmpresa };
            let _usuarioComposto = { ...usuarioComposto };
            console.log(usuarioComposto);
            // if (_usuarios.idusuario) {
            if (usuarioComposto.idusuario) {
                // const index = findIndexById(_usuarios.idusuario);
                const index = findIndexById(usuarioComposto.idusuario);
                _usuarios.idusuario = usuarioComposto.idusuario;
                _usuarios.nome = usuarioComposto.nome;
                _usuarios.email = usuarioComposto.email;
                api.put('/usuarios', _usuarios).then(res => {
                    if (res.status === 200) {
                        // _listaUsuarios[index] = _usuarios;
                        // setListaUsuarios(_listaUsuarios);
                        _usuarioEmpresa.idempresasusuarios = usuarioComposto.idempresasusuarios;
                        _usuarioEmpresa.idusuario = usuarioComposto.idusuario;
                        _usuarioEmpresa.idtipousuario = usuarioComposto.idtipousuario;
                        _usuarioEmpresa.ativo = true;
                        _usuarioEmpresa.idempresa = sessionStorage.getItem('idempresa');

                        _listaUsuarios[index] = _usuarioComposto;
                        setListaUsuarios(_listaUsuarios);

                        api.put('/empresasusuarios', _usuarioEmpresa).then(res => {
                            setUsuarioEmpresa(_usuarioEmpresa);                                                        
                        })
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _usuarios.idusuario = 0;
                _usuarios.nome = usuarioComposto.nome;
                _usuarios.email = usuarioComposto.email;

                api.post('/usuarios', _usuarios).then(res => {
                    if (res.status === 200) {
                        _usuarios.idusuario = res.data.id;
                        _usuarioComposto.idusuario = res.data.id;
                        
                        // _listaUsuarios.push(_usuarios);
                        // setListaUsuarios(_listaUsuarios);

                        _usuarioEmpresa.idusuario = _usuarios.idusuario;
                        _usuarioEmpresa.idempresa = sessionStorage.getItem('idempresa');
                        _usuarioEmpresa.idtipousuario = usuarioComposto.idtipousuario;
                        _listaUsuarios.push(_usuarioComposto);
                        setListaUsuarios(_listaUsuarios);
                        
                        api.post('/empresasusuarios', _usuarioEmpresa).then(res => {
                            setUsuarioEmpresa(_usuarioEmpresa);                           
                        })
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            setListaUsuarios(_listaUsuarios);
            setUsuariosDialog(false);
            setUsuarios(emptyUsuarios);
            // }
            // else {
            //     setEmailInvalido(true)
            //     alert('Email inválido')
            // }
        }
    }

    const editUsuarios = (usuarios) => {
        //    let _usuario = { ...usuarios };
        let _usuarioComposto = { ...usuarioComposto };
        _usuarioComposto.idtipousuario = usuarios.idtipousuario;
        _usuarioComposto.nome = usuarios.nome;
        _usuarioComposto.email = usuarios.email;
        _usuarioComposto.idusuario = usuarios.idusuario;
        _usuarioComposto.idempresasusuarios = usuarios.idempresasusuarios;
        setUsuarioComposto(_usuarioComposto);
        // setUsuarios({ ...usuarios });

        setUsuariosDialog(true);
    }

    const deleteUsuarios = () => {
        api.delete(`/usuarios/${usuarios.idusuario}`).then(res => {
            if (res.status === 200) {
                let _usuarios = listaUsuarios.filter(val => val.idusuario !== usuarios.idusuario);
                setListaUsuarios(_usuarios);
                setDeleteUsuariosDialog(false);
                setUsuarios(emptyUsuarios);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteUsuarios = (usuarios) => {
        setUsuarios(usuarios);
        setDeleteUsuariosDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setUsuariosDialog(false);
    }


    const hideDeleteUsuariosDialog = () => {
        setDeleteUsuariosDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaUsuarios.length; i++) {
            if (listaUsuarios[i].idusuario === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        // let _usuarios = { ...usuarios };
        // _usuarios[`${name}`] = val;
        // setUsuarios(_usuarios);
        let _usuarios = { ...usuarioComposto };
        _usuarios[`${name}`] = val;
        setUsuarioComposto(_usuarios);
    }

    const onInputChangeEmail = (e, name) => {
        const val = (e.target && e.target.value) || '';

        // let _usuarios = { ...usuarios };
        // _usuarios[`${name}`] = val;
        // setUsuarios(_usuarios);
        let _usuarios = { ...usuarioComposto };
        _usuarios[`${name}`] = val;
        setUsuarioComposto(_usuarios);

        if (validator.isEmail(val)) {
            setEmailInvalido(false)
        }
        else {
            if (val.length > 0) {
                setEmailInvalido(true)
            }
            else {
                setEmailInvalido(false)
            }
        }
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaUsuarios);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaUsuarios');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editUsuarios(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteUsuarios(rowData)} />
            </div>
        );
    }

    const trocarSenhaTemplate = (rowData) => {
        var trueValue = rowData.trocarSenhaTemplate && "SIM"
        var falseValue = !rowData.trocarSenhaTemplate && "NÃO"
        return (rowData.finalizaatividade ? trueValue : falseValue)
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Usuários</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const usuariosDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveUsuarios} />
        </>
    );

    const deleteUsuariosDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteUsuariosDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteUsuarios} />
        </>
    );

    const onTipoUsuarioChange = (e) => {
        // let _usuario = { ...usuarioEmpresa };
        // _usuario.idtipousuario = e.value;
        // setUsuarioEmpresa(_usuario);
        // console.log(_usuario);
        let _usuario = { ...usuarioComposto };
        _usuario.idtipousuario = e.value;
        setUsuarioComposto(_usuario);
        // console.log(_usuario);
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt} value={listaUsuarios} dataKey="idusuario"
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Usuários" globalFilter={globalFilter}
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                        emptyMessage="Nenhuma Usuarios encontrada." header={header}>
                        <Column field="idusuario" header="Id" sortable style={{ width: '6%' }} />
                        <Column field="nome" header="Nome" sortable ></Column>
                        <Column field="email" header="E-mail" ></Column>
                        {/* <Column field="senha" header="senha" ></Column>
                        <Column field="trocarsenha" header="Trocar Senha" body={trocarSenhaTemplate} ></Column> */}
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                    </DataTable>
                    <Dialog visible={usuariosDialog} style={{ width: '450px' }} header="Usuários" modal className="p-fluid" footer={usuariosDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="tipousuario">Tipo de Usuário</label>
                            <Dropdown id="idtipousuario" optionValue="idtipousuario" optionLabel="tipousuario" value={usuarioComposto.idtipousuario} options={listaTipoUsuario}
                                onChange={(e) => onTipoUsuarioChange(e)} placeholder="Selecione" filter style={{ position: 'relative !important' }}
                                required className={classNames({ 'p-invalid': submitted && !usuarioComposto.idtipousuario })}
                                menuPortalTarget={document.body} />
                            {submitted && !usuarioComposto.idtipousuario && <small className="p-invalid">Tipo de Usuário é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="nome">Nome</label>
                            <InputText id="nome" value={usuarioComposto.nome} onChange={(e) => onInputChange(e, 'nome')} required className={classNames({ 'p-invalid': submitted && !usuarioComposto.nome })} />
                            {submitted && !usuarioComposto.nome && <small className="p-invalid">Nome é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="email">E-mail</label>
                            <InputText id="email" value={usuarioComposto.email} onChange={(e) => onInputChangeEmail(e, 'email')} required className={classNames({ 'p-invalid': submitted && (!usuarioComposto.email || EmailInvalido) })} />
                            {submitted && !usuarioComposto.email && <small className="p-invalid">E-mail é requerido.</small>}
                            {EmailInvalido && <small className="p-invalid">E-mail inválido.</small>}
                        </div>

                        {/* <div className="p-field">
                            <label htmlFor="trocarsenha">trocarsenha</label>
                            <InputText id="trocarsenha" value={usuarios.trocarsenha} onChange={(e) => onInputChange(e, 'trocarsenha')} required className={classNames({ 'p-invalid': submitted && !usuarios.trocarsenha })} />
                            {submit
                                ted && !usuarios.trocarsenha && <small className="p-invalid">TrocarSenha é requerido.</small>}
                        </div> */}
                    </Dialog>
                    <Dialog visible={deleteUsuariosDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteUsuariosDialogFooter} onHide={hideDeleteUsuariosDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {usuarios && <span>Tem certeza que deseja excluir <b>{usuarios.nome}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
