import React from 'react';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { GraficoOcorrenciasAreasDetalhes } from './ocorrenciasAreasDetalhes';

export const OcorrenciasAreasDetalhesList = (props) => {

    const getGrafico = () => {
        return (
            <React.Fragment>
                <GraficoOcorrenciasAreasDetalhes idCliente={sessionStorage.getItem("idcliente")}
                    idArea={props.match.params.id} />
            </React.Fragment>
        )
    }


    const leftToolbarTemplate = (
        <React.Fragment>
            <h5>{sessionStorage.getItem('cliente')}</h5>
        </React.Fragment >
    );

    return (
        <div>
            <Toolbar className="p-toolbar p-mb-2" left={leftToolbarTemplate} ></Toolbar>
            <div className="content-section introduction">
                <h3> Ocorrências por Grupo de Área </h3>
            </div>

            <div className="content-section implementation">
                <div className="card">
                    <h4> Grupo de Área: {props.match.params.area} </h4>
                    <div className="card">
                        {getGrafico()};
                    </div>
                </div>
            </div>
        </div>
    )
}

