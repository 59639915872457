import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';

export const Empresas = () => {

  let emptyEmpresa = {
    idempresa: null,
    razaosocial: '',
  };

  const [empresa, setEmpresa] = useState(emptyEmpresa);
  const [listaEmpresas, setListaEmpresas] = useState(null);
  const [empresaDialog, setEmpresaDialog] = useState(false);
  const [deleteEmpresaDialog, setDeleteEmpresaDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
      api.get(`/empresas`).then((res) => {
          setListaEmpresas(res.data);
      });
  }, []);

  const openNew = () => {
    setEmpresa(emptyEmpresa);
    setSubmitted(false);
    setEmpresaDialog(true);
  }

  const saveEmpresa = () => {
    setSubmitted(true);
    if (empresa.razaosocial.trim()) {
        let _listaEmpresas = [...listaEmpresas];
        let _empresa = { ...empresa };

        if (_empresa.idempresa){
            const index = findIndexById(_empresa.idempresa);
            api.put('/empresas', _empresa).then(res => {
                if (res.status === 200) {
                    _listaEmpresas[index] = _empresa;
                    setListaEmpresas(_listaEmpresas);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                })
        }
        else {
            console.log("... adicionando ...")
            _empresa.idempresa = 0;
            console.log("idempresa: " + _empresa.idempresa)
            console.log("nome: " + _empresa.razaosocial)
            api.post('/empresas',_empresa).then(res => {
                if (res.status === 200) {
                    _empresa.idempresa = res.data.id;
                    _listaEmpresas.push(_empresa);
                    setListaEmpresas(_listaEmpresas);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                console.log(error);
            })
        }
        // setListaEmpresas(_listaEmpresas);
        setEmpresaDialog(false);
        setEmpresa(emptyEmpresa);
    }
  }

  const editEmpresa = (empresa) => {
      setEmpresa({ ...empresa});
      setEmpresaDialog(true);
  }

  const deleteEmpresa= () => {
      api.delete(`/empresas/${empresa.idempresa}`).then(res => {
          if (res.status === 200){
              let _empresa= listaEmpresas.filter(val => val.idempresa !== empresa.idempresa);
              setListaEmpresas(_empresa);
              setDeleteEmpresaDialog(false);
              setEmpresa(emptyEmpresa);
              toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
          }
      })
      .catch(error => {
          toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
          console.log(error);
      })
  }

  const confirmDeleteEmpresa= (empresa) => {
      setEmpresa(empresa);
      setDeleteEmpresaDialog(true);
  }

  const hideDialog = () => {
      setSubmitted(false);
      setEmpresaDialog(false);
  }

  const hideDeleteEmpresaDialog = () => {
      setDeleteEmpresaDialog(false);
  }

  const findIndexById = (n) => {
      let index = -1;
      for (let i = 0; i < listaEmpresas.length; i++) {
          if (listaEmpresas[i].idempresa === n) {
              index = i;
              break;
          }
      }
      return index;
  }

  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';
      let _empresa = { ...empresa};
      _empresa[`${name}`] = val;
      setEmpresa(_empresa);
  }

  const leftToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
          </React.Fragment>
      )
  }

  const exportExcel = () => {
      import('xlsx').then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(listaEmpresas);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          saveAsExcelFile(excelBuffer, 'listaEmpresas');
      });
  }

  const saveAsExcelFile = (buffer, fileName) => {
      import('file-saver').then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  const rightToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
          </React.Fragment>
      )
  }

  const actionBodyTemplate = (rowData) => {
      return (
          <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editEmpresa(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteEmpresa(rowData)} />
          </div>
      );
  }

  const header = (
      <div className="table-header">
          <h5 className="p-m-0 p-text-bold">Empresas</h5>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
          </span>
      </div>
  );

  const empresasDialogFooter = (
      <>
          <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
          <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveEmpresa} />
      </>
  );

  const deleteEmpresasDialogFooter = (
      <>
          <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEmpresaDialog} />
          <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteEmpresa} />
      </>
  );

  return (
      <div className="p-grid crud-demo">
          <div className="p-col-12">
              <div className="card">
                  <Toast ref={toast} />
                  <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                  <DataTable
                      ref={dt} value={listaEmpresas} dataKey="idempresa"
                      paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Empresas" globalFilter={globalFilter}
                      emptyMessage="Nenhuma Empresas encontrada." header={header}>
                      <Column field="idempresa" header="Id" sortable style={{ width: '6%' }} />
                      <Column field="razaosocial" header="Razão Social" sortable ></Column>
                      <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }}></Column>
                  </DataTable>
                  <Dialog visible={empresaDialog} style={{ width: '450px' }} header="Empresa" modal className="p-fluid" footer={empresasDialogFooter} onHide={hideDialog}>
                      <div className="p-field">
                          <label htmlFor="razaosocial">Razão Social</label>
                          <InputText id="razaosocial" value={empresa.razaosocial} onChange={(e) => onInputChange(e, 'razaosocial')} required autoFocus className={classNames({ 'p-invalid': submitted && !empresa.razaosocial})} />
                          {submitted && !empresa.razaosocial && <small className="p-invalid">Razão Social é requerido.</small>}
                      </div>
                  </Dialog>
                  <Dialog visible={deleteEmpresaDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteEmpresasDialogFooter} onHide={hideDeleteEmpresaDialog}>
                      <div className="confirmation-content">
                          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                          {empresa && <span>Tem certeza que deseja excluir <b>{empresa.razaosocial}</b>?</span>}
                      </div>
                  </Dialog>
              </div>
          </div>
      </div>
  );
}
