import { Skeleton } from 'primereact/skeleton';
import React, { useState, useEffect } from 'react';
import apiCli from '../../service/apiCli';

export const CardsOcorrencia = (props) => {

    const emptyInfo = {
        Concluidas: null,
        Total: null,
        TotalAbertas: null
    }

    const [info, setInfo] = useState(emptyInfo);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        apiCli.get(`/ocorrencias/info/id/${props.idCliente}`)
            .then(res => {
                setInfo(res.data);
                setLoading(false);
            });
    }, [props.idCliente]);

    const getEficacia = () => {
        if (info.Total !== null) {
            let Valor = (info.Concluidas / info.Total);
            return Valor.toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                style: 'percent'
            });
        }
    }

    return (
        <>
            {!loading && (
                <>
                    <div className="p-col-12 p-lg-3">
                        <div className="card summary">
                            <span className="title">Total</span>
                            <span className="detail">Quantidade total</span>
                            <span className="count revenue">{info.Total}</span>
                            {/* revenue = azul */}
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3">
                        <div className="card summary">
                            <span className="title">Abertas</span>
                            <span className="detail">Quantidade aberta</span>
                            <span className="count purchases">{info.TotalAbertas}</span>
                            {/* visitors = verde */}
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3">
                        <div className="card summary">
                            <span className="title">Concluídas</span>
                            <span className="detail">Quantidade concluída</span>
                            <span className="count visitors">{info.Concluidas}</span>
                            {/* purchases = amarelo */}
                        </div>
                    </div>
                    <div className="p-col-12 p-lg-3">
                        <div className="card summary">
                            <span className="title">Eficácia</span>
                            <span className="detail">Percentual de eficácia</span>
                            <span className="count visitors">{getEficacia()}</span>
                            {/* purchases = amarelo */}
                        </div>
                    </div>
                </>
            )}
            {loading && (
                <>
                    <div className="p-col-12 p-lg-4">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <Skeleton height="5rem" />
                    </div>
                    <div className="p-col-12 p-lg-4">
                        <Skeleton height="5rem" />
                    </div>
                </>
            )}
        </>
    );
}