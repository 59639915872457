import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import classNames from 'classnames';
import { Toast } from '../components/toast/Toast';
import { InputText } from '../components/inputtext/InputText';
import { Button } from '../components/button/Button';
import { Panel } from '../components/panel/Panel';
import { Dropdown } from '../components/dropdown/Dropdown';
import { Voltar } from './Componentes_Detalhes/Voltar';
import { ItemAtividades } from './ItemAtividades';
import { Dialog } from '../components/dialog/Dialog';
import { useHistory } from "react-router-dom";


export const ItemDetalhes = (props) => {

    let emptyItem = {
        iditem: null,
        item: '',
        descricao: '',
        tag: '',
        idtipoitem: null,
        idsistemaconstrutivo: null
    };

    let emptyItemClonado = {
        id: null,
        descricao: null
    }

    const history = useHistory();
    const [item, setItem] = useState(emptyItem);                            // Item que está sendo editado
    const [listaTiposItem, setListaTiposItem] = useState(null);             // Lista com todos os Tipos de itens
    const [listaSistemaConstrutivo, setListaSistemaConstrutivo] = useState(null); 
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [id, setId] = useState(props.match.params.id);
    const [clonarDialog, setClonarDialog] = useState(false);
    const [descClonada, setDescClonada] = useState(null);
    const [itemClonado, setItemClonado] = useState(emptyItemClonado);

    useEffect(() => {
        // Item que está sendo editado
        api.get(`/itens/${id}`).then((res) => {
            setItem(res.data);
        });

        // Lista com todos os Tipos de itens 
        api.get(`/tipoitem`).then((res) => {
            setListaTiposItem(res.data);
        });

        api.get(`/sistemaconstrutivo`).then((res) => {
            setListaSistemaConstrutivo(res.data);
        });        
    }, []);

    const saveItem = () => {
        setSubmitted(true);
        if (item.item.trim() && item.idsistemaconstrutivo && item.idtipoitem) {
            let _item = { ...item };
            api.put('/itens', _item).then(res => {
                if (res.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                })
        }
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _item = { ...item };
        _item[`${name}`] = val;
        setItem(_item);
    }
    const onInputChangeDesc = (e) => {
        setDescClonada(e.target.value);
    }

    const clickClonar = () => {
        setClonarDialog(true);
        setDescClonada(item.descricao);
    }

    const hideClonarDialog = () => {
        setClonarDialog(false);
    }

    const clonar = () => {
        let _itemClonado = { ...itemClonado };
        _itemClonado.id = item.iditem;
        _itemClonado.descricao = descClonada;

        api.post('/clonaritem', _itemClonado).then(res => {
            if (res.status === 200) {
                _itemClonado.id = res.data.result;

                history.push(`/item/${_itemClonado.id}`);
                window.location.reload();
            }
        })
    }

    const clonarDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideClonarDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={clonar} />
        </>
    );



    return (
        <div className="p-grid crud-demo">
            <div className="content-section introduction">
                <h5>Item - {item.item}</h5>
            </div>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <Panel header={'Item'} >
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-2" >
                                <label htmlFor="iditem">Id</label>
                                <InputText id="iditem" value={item.iditem} readOnly={true} disabled={true} />
                            </div>
                            <div className="p-field p-col-5" >
                                <label htmlFor="tipoitem">Tipo de Item</label>
                                <Dropdown inputId="idtipoitem" optionValue="idtipoitem" optionLabel="tipoitem" value={item.idtipoitem}
                                    options={listaTiposItem} onChange={(e) => onInputChange(e, 'idtipoitem')}
                                    filter showClear filterBy="tipoitem" placeholder="Selecione"
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !item.idtipoitem })} />
                                {submitted && !item.idtipoitem && <small className="p-invalid">Tipo de Item é requerido.</small>}
                            </div>
                            <div className="p-field p-col-5" >
                                <label htmlFor="tipoitem">Sistema Construtivo</label>
                                <Dropdown inputId="idsistemaconstrutivo" optionValue="idsistemaconstrutivo" optionLabel="sistemaconstrutivo" value={item.idsistemaconstrutivo}
                                    options={listaSistemaConstrutivo} onChange={(e) => onInputChange(e, 'idsistemaconstrutivo')}
                                    filter showClear filterBy="sistemaconstrutivo" placeholder="Selecione"
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !item.idsistemaconstrutivo })} />
                                {submitted && !item.idsistemaconstrutivo && <small className="p-invalid">Sistema Construtivo é requerido.</small>}
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="item">Item</label>
                                <InputText id="item" value={item.item} onChange={(e) => onInputChange(e, 'item')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.item })} />
                                {submitted && !item.item && <small className="p-invalid">Item é requerido.</small>}
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="descricao">Descrição</label>
                                <InputText id="descricao" value={item.descricao} onChange={(e) => onInputChange(e, 'descricao')} required className={classNames({ 'p-invalid': submitted && !item.descricao })} />
                                {submitted && !item.descricao && <small className="p-invalid">Descricao é requerido.</small>}
                            </div>
                            <div><Button label="Clonar" icon="pi pi-copy" className="p-button" onClick={clickClonar} /></div>
                        </div>
                    </Panel>
                    <br />
                    <ItemAtividades valorId={id}></ItemAtividades>
                    <br />
                    <div>
                        <React.Fragment>
                            <div className="p-d-flex p-justify-end">
                                <div>
                                    <Voltar page={"itens"} ></Voltar>
                                    <Button label="Salvar" icon="pi pi-check" className="p-button-success" onClick={saveItem} />
                                </div>
                            </div>
                        </React.Fragment>
                    </div>

                    <Dialog visible={clonarDialog} style={{ width: '40%' }} header="Clonar" modal className="p-fluid" footer={clonarDialogFooter} onHide={hideClonarDialog}>
                        <div className="p-field p-col-12">
                            <label htmlFor="desc">Descrição</label>
                            <InputText id="desc" value={descClonada}
                                onChange={(e) => onInputChangeDesc(e)}
                            />
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
