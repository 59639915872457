import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Toast } from '../components/toast/Toast';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Button } from '../components/button/Button';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { FileUpload } from '../components/fileupload/FileUpload';
import { ProgressBar } from '../components/progressbar/ProgressBar';


export const AtividadeChecklistAnexos = (props) => {

    const [anexo, setAnexo] = useState({});
    const [listaAnexos, setListaAnexos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [deleteAnexoDialog, setDeleteAnexoDialog] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    const getAnexos = () => {
        api.get(`/checklistitensanexos/item/${props.id}`).then((res) => {
            setListaAnexos(res.data);
            setLoading(false);
        });
    }

    useEffect(() => {
        getAnexos();
    }, [props.id]);

    const downloadAnexo = (nome, id) => {
        setLoading(true);
        api.get(`/checklistitensanexos/${id}`, { responseType: 'blob' })
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', nome);
                document.body.appendChild(link);
                link.click();
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu fazer o download do aqruivo', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteAnexo = (rowData) => {
        setAnexo(rowData);
        setDeleteAnexoDialog(true);
    }

    const hideDeleteAnexoDialog = () => {
        setDeleteAnexoDialog(false);
    }

    const deleteAnexo = () => {
        api.delete(`/checklistitensanexos/${anexo.idchecklistitensanexos}`).then(res => {
            if (res.status === 200) {
                let _anexos = listaAnexos.filter(val => val.idchecklistitensanexos !== anexo.idchecklistitensanexos);
                setListaAnexos(_anexos);
                setDeleteAnexoDialog(false);
                setAnexo({});
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const deleteAnexoDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteAnexoDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteAnexo} />
        </>
    );

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button
                    icon="pi pi-download"
                    className="p-button-rounded p-button-warning p-mr-2"
                    onClick={() => downloadAnexo(rowData.nomeanexo, rowData.idchecklistitensanexos)}
                    tooltip="Download"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
                <Button
                    icon="pi pi-trash"
                    className="p-button-rounded p-button-danger"
                    onClick={() => confirmDeleteAnexo(rowData)}
                    tooltip="Excluir"
                    tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
            </React.Fragment>
        );
    }

    const url = api.defaults.baseURL + '/checklistitensanexos/' + props.id;

    const onUpload = () => {
        getAnexos();
        toast.current.show({ severity: 'success', summary: 'Successo', detail: 'Anexo inserido' });
    }

    const onBeforeSend = (event) => {
        event.xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("simpemptoken"));
        setLoading(true);
    }

    const onValidationFail = (event) => {
        toast.current.show({
            severity: 'error',
            summary: 'Erro',
            detail: 'Verifique se o arquivo possui no máximo 1Mb de tamanho'
        });
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <FileUpload
                    className='p-mt-2'
                    mode="basic"
                    name="demo[]"
                    url={url}
                    //accept="image/jpg, .jpg, .jpeg"
                    maxFileSize={1000000}
                    onUpload={onUpload}
                    onBeforeSend={onBeforeSend}
                    onValidationFail={onValidationFail}
                    auto
                    chooseLabel="Incluir"
                    disabled={loading} />
            </React.Fragment>
        )
    }

    return (
        <>
            <Toast ref={toast} />
            <Toolbar left={leftToolbarTemplate} />
            <div className='p-mt-2' hidden={!loading}>
                <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
            </div>
            <DataTable
                ref={dt} value={listaAnexos} dataKey="idchecklistitensanexos"
                paginator rows={10} rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive p-datatable-customers"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Itens"
                //globalFilter={globalFilter}
                emptyMessage="Nenhum Item encontrado." sortMode="multiple" >
                <Column field="idchecklistitensanexos" header="Id" sortable style={{ width: '10%' }} />
                <Column field="nomeanexo" header="Nome" sortable ></Column>
                <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
            </DataTable>

            <Dialog visible={deleteAnexoDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteAnexoDialogFooter} onHide={hideDeleteAnexoDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                    {anexo && <span>Tem certeza que deseja excluir <b>{anexo.nomeanexo}</b>?</span>}
                </div>
            </Dialog>
        </>
    );
}