import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from '../../components/carousel/Carousel';
import '../../csspersonalizado/carousel.css';
import api from '../../service/api';
import { Button } from '../../components/button/Button';
import { FileUpload } from '../../components/fileupload/FileUpload';
import { Toast } from '../../components/toast/Toast';
import { ConfirmPopup, confirmPopup } from '../../components/confirmpopup/ConfirmPopup';
import { ProgressBar } from '../../components/progressbar/ProgressBar';

export const OcorrenciaEditImagens = (props) => {

    const emptyOcorrenciaFotos = {
        idocorrenciasstatusfotos: null,
        idocorrenciasstatus: null,
        foto: null,
        fotothumb: null,
    }

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 3,
            numScroll: 3
        },
        {
            breakpoint: '600px',
            numVisible: 2,
            numScroll: 2
        },
        {
            breakpoint: '480px',
            numVisible: 1,
            numScroll: 1
        }
    ];

    const [ocorrenciaFotos, setOcorrenciaFotos] = useState(emptyOcorrenciaFotos);
    const [listaOcorrenciaFotos, setListaOcorrenciaFotos] = useState([]);
    const [loading, setLoading] = useState(false);
    const toast = useRef(null);

    const getFotos = () => {
        setLoading(true);
        api.get(`/ocorrenciasstatusfotos/ocorrenciastatus/${props.idocorrenciasstatus}`)
            .then((res) => {
                setListaOcorrenciaFotos(res.data);
                setLoading(false);
            })
    }

    useEffect(() => {
        getFotos();
    }, [props.idocorrenciasstatus]);

    const excluirFoto = (id) => {
        api.delete(`/ocorrenciasstatusfotos/${id}`)
            .then((res) => {
                if (res.status === 200) {
                    let _fotos = listaOcorrenciaFotos.filter(val => val.idocorrenciasstatusfotos !== id);
                    setListaOcorrenciaFotos(_fotos);
                    toast.current.show({ severity: 'success', summary: 'Successo', detail: 'Foto excluída' });
                }
            })
            .catch(error => {
                toast.show({ severity: 'error', summary: 'Erro', detail: 'Ocorreu algum problema ao tentar excluir a foto', life: 3000 });
                console.log(error.data);
            })
    }

    // const reject = () => {
    //     toast.current.show({ severity: 'info', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    // };

    const confirm = (event, id) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Confirma a exclusão da foto?',
            icon: 'pi pi-trash',
            acceptClassName: 'p-button-danger',
            accept: () => { excluirFoto(id) },
            acceptLabel: 'Sim',
            // reject,
            rejectLabel: 'Não'
        });
    };

    const fotoTemplate = (fotoStatus) => {
        return (
            <div className="product-item">
                <div className="product-item-content">
                    <div className="p-mb-1">
                        <img height={'200px'} src={`data:image/png;base64,${fotoStatus.foto}`} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} className="product-image" />
                    </div>
                    <div>
                        {/* <h4 className="p-mb-1">{fotoStatus.idocorrenciasstatusfotos}</h4> */}
                        {/*<h6 className="p-mt-0 p-mb-3">${product.price}</h6>
                        <span className={`product-badge status-${product.inventoryStatus.toLowerCase()}`}>{product.inventoryStatus}</span> */}
                        <div className="car-buttons p-mt-1">
                            <Button
                                tooltip={'Excluir foto'}
                                icon="pi pi-trash"
                                className="p-button-danger p-mr-2"
                                onClick={(e) => { confirm(e, fotoStatus.idocorrenciasstatusfotos) }} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    const leftToolbarTemplate = (
        <React.Fragment>
            <h5>{'Fotos'}</h5>
        </React.Fragment >
    );

    const url = api.defaults.baseURL + '/ocorrenciasstatusfotos/' + props.idocorrenciasstatus;

    const onUpload = () => {
        getFotos();
        toast.current.show({ severity: 'success', summary: 'Successo', detail: 'Foto inserida' });
    }

    const onBeforeSend = (event) => {
        event.xhr.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("simpemptoken"));
        setLoading(true);
    }

    const onValidationFail = (event) => {
        toast.current.show({
            severity: 'error',
            summary: 'Erro',
            detail: 'Verifique se o arquivo é um jpeg e se possui no máximo 1Mb de tamanho'
        });
    }

    const rightToolbarTemplate = (
        <React.Fragment>
            <FileUpload
                mode="basic"
                name="demo[]"
                url={url}
                accept="image/jpg, .jpg, .jpeg"
                maxFileSize={1000000}
                onUpload={onUpload}
                onBeforeSend={onBeforeSend}
                onValidationFail={onValidationFail}
                auto
                chooseLabel="Incluir" />    
        </React.Fragment>
    );

    return (
        <>
            <Toast ref={toast} />
            {/* <Toolbar className="p-toolbar p-mb-2" right={rightToolbarTemplate} ></Toolbar> */}
            <div>
            <Carousel
                value={listaOcorrenciaFotos}
                numVisible={2} numScroll={2}
                responsiveOptions={responsiveOptions}
                itemTemplate={fotoTemplate}
            // header={'Fotos'} 
            />
            </div>
            <div>
            <FileUpload
                className='p-mt-2'
                mode="basic"
                name="demo[]"
                url={url}
                accept="image/jpg, .jpg, .jpeg"
                maxFileSize={1000000}
                onUpload={onUpload}
                onBeforeSend={onBeforeSend}
                onValidationFail={onValidationFail}
                auto
                chooseLabel="Incluir" 
                disabled={loading} />  
            </div>
            <div className='p-mt-2' hidden={!loading}>
                <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
            </div>
        </>
    )
}