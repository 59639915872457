import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../../service/api';
import { DataTable } from '../../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../../components/toast/Toast';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { InputText } from '../../components/inputtext/InputText';
import { Button } from '../../components/button/Button';
import { Link } from 'react-router-dom';

export const OcorrenciasClientes = () => {

    const [listaOcorrenciasClientes, setListaOcorrenciasClientes] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [exibirDashBoard, setExibirDashBoard] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/ocorrencias/clientes/empresa/${sessionStorage.getItem('idempresa')}`)
            .then((res) => {
                setListaOcorrenciasClientes(res.data);
            });
    }, []);

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaOcorrenciasClientes);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaOcorrenciasClientes');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
       
        return (
            <div className="actions">
                <Link to={`/ocorrencias/dashboard/${rowData['idcliente']}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link>                
            </div>
        );
    }


    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Ocorrências</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt} value={listaOcorrenciasClientes} dataKey="idarea"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Áreas" globalFilter={globalFilter}
                        emptyMessage="Nenhum Tipo de Área encontrado." header={header}>
                        <Column field="idcliente" header="Id" sortable style={{ width: '6%' }}></Column>
                        <Column field="nomefantasia" header="Cliente" sortable ></Column>
                        <Column field="abertas" header="Abertas" sortable ></Column>
                        <Column field="encerradas" header="Encerradas" sortable ></Column>                                                
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }}></Column>
                    </DataTable>
                </div>
            </div>
        </div>
    );
}
