import React, {useState, createContext} from 'react';

export const UserContext = createContext();

const UserProvider = ({ children }) => {

    console.log('contexto: ' + sessionStorage.getItem('trocarsenha'));
    const [data, setData] = useState({
        // logger : !!sessionStorage.getItem('simpemptoken') 
        logger:    (sessionStorage.getItem('simpemptoken') && !sessionStorage.getItem('trocarsenha'))
    });

    
    const isLogger = data.logger;

    const alterLogin = value => {
        setData({...data, logger : value})
    }

    return(
        <UserContext.Provider value={{isLogger, alterLogin}}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider;
