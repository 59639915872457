import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { format, parseISO } from "date-fns";
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { RadioButton } from '../components/radiobutton/RadioButton'

export const TipoUsuario = () => {

    let emptyTipoUsuario = {
        idtipousuario: null,
        tipousuario: '',
        dataatualizacao: null,
        filtro: null,
    };

    const [tipousuario, setTipoUsuario] = useState(emptyTipoUsuario);
    const [listaTipoUsuario, setListaTipoUsuario] = useState(null);
    const [tipousuarioDialog, setTipoUsuarioDialog] = useState(false);
    const [deleteTipoUsuarioDialog, setDeleteTipoUsuarioDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/tipousuario`).then((res) => {
            setListaTipoUsuario(res.data);
        });
    }, []);

    const openNew = () => {
        setTipoUsuario(emptyTipoUsuario);
        setSubmitted(false);
        setTipoUsuarioDialog(true);
    }

    const saveTipoUsuario = () => {
        setSubmitted(true);
        if (tipousuario.tipousuario.trim() && tipousuario.filtro) {
            let _listaTipoUsuario = [...listaTipoUsuario];
            let _tipousuario = { ...tipousuario };
            if (_tipousuario.idtipousuario) {
                const index = findIndexById(_tipousuario.idtipousuario);
                api.put('/tipousuario', _tipousuario).then(res => {
                    if (res.status === 200) {
                        _listaTipoUsuario[index] = _tipousuario;
                        setListaTipoUsuario(_listaTipoUsuario);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _tipousuario.idtipousuario = 0;
                api.post('/tipousuario', _tipousuario).then(res => {
                    if (res.status === 200) {
                        _tipousuario.idtipousuario = res.data.id;
                        _listaTipoUsuario.push(_tipousuario);
                        setListaTipoUsuario(_listaTipoUsuario);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            // setListaTipoUsuario(_listaTipoUsuario);
            setTipoUsuarioDialog(false);
            setTipoUsuario(emptyTipoUsuario);
        }
    }

    const editTipoUsuario = (tipousuario) => {
        setTipoUsuario({ ...tipousuario });
        setTipoUsuarioDialog(true);
    }

    const deleteTipoUsuario = () => {
        api.delete(`/tipousuario/${tipousuario.idtipousuario}`).then(res => {
            if (res.status === 200) {
                let _tipousuario = listaTipoUsuario.filter(val => val.idtipousuario !== tipousuario.idtipousuario);
                setListaTipoUsuario(_tipousuario);
                setDeleteTipoUsuarioDialog(false);
                setTipoUsuario(emptyTipoUsuario);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteTipoUsuario = (tipousuario) => {
        setTipoUsuario(tipousuario);
        setDeleteTipoUsuarioDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTipoUsuarioDialog(false);
    }

    const hideDeleteTipoUsuarioDialog = () => {
        setDeleteTipoUsuarioDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaTipoUsuario.length; i++) {
            if (listaTipoUsuario[i].idtipousuario === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _tipousuario = { ...tipousuario };
        _tipousuario[`${name}`] = val;
        setTipoUsuario(_tipousuario);
    }

    const setFiltro = (e) => {
        const val = e;
        console.log(val, e);
        let _tipousuario = { ...tipousuario };
        _tipousuario.filtro = val;
        setTipoUsuario(_tipousuario);
    }

    const dateTemplate = (rowData) => {
        let data = rowData;
        //console.log(data)
        const Data = parseISO(rowData['dataatualizacao']);
        ; return format(Data, "dd/MM/yyyy");
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaTipoUsuario);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaTipoUsuario');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editTipoUsuario(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteTipoUsuario(rowData)} />
            </div>
        );
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Tipo de Usuário</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const tipousuarioDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveTipoUsuario} />
        </>
    );

    const deleteTipoUsuarioDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTipoUsuarioDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteTipoUsuario} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt} value={listaTipoUsuario} dataKey="idtipousuario"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Tipos de Usuário" globalFilter={globalFilter}
                        emptyMessage="Nenhum Tipo de Usuário encontrado." header={header}>
                        <Column field="idtipousuario" header="Id" sortable style={{ width: '6%' }} />
                        <Column field="tipousuario" header="Descrição" sortable ></Column>
                        <Column field="filtro" header="Tipo" sortable style={{ width: '6%' }} ></Column>
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                    </DataTable>
                    <Dialog visible={tipousuarioDialog} style={{ width: '450px' }} header="Tipo de Usuário" modal className="p-fluid" footer={tipousuarioDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="tipousuario">Descrição</label>
                            <InputText id="tipousuario" value={tipousuario.tipousuario} onChange={(e) => onInputChange(e, 'tipousuario')} required autoFocus className={classNames({ 'p-invalid': submitted && !tipousuario.tipousuario })} />
                            {submitted && !tipousuario.tipousuario && <small className="p-invalid">Descrição é requerido.</small>}
                        </div>
                        <div className="p-field">
                        <label htmlFor="filtro">Tipo</label>
                            <div className='p-grid'>
                                <div className='p-col-3'>
                                  
                                    {/* <InputText id="filtro" value={tipousuario.filtro} onChange={(e) => onInputChange(e, 'tipousuario')} required autoFocus className={classNames({ 'p-invalid': submitted && !tipousuario.tipousuario })} /> */}
                                    <div className="p-field-radiobutton">
                                        <RadioButton inputId="filtro1" name="filtroCliente" value={tipousuario.filtro} onChange={(e) => setFiltro('C')} checked={tipousuario.filtro === 'C'}
                                            required className={classNames({ 'p-invalid': submitted && !tipousuario.filtro })} />
                                        <label htmlFor="filtro1">Cliente</label>
                                    </div>
                                </div>
                                <div className='p-col-3'>
                                    <div className="p-field-radiobutton">
                                        <RadioButton inputId="filtro2" name="filtroEmpresa" value={tipousuario.filtro} onChange={(e) => setFiltro('E')} checked={tipousuario.filtro === 'E'}
                                            required className={classNames({ 'p-invalid': submitted && !tipousuario.filtro })} />
                                        <label htmlFor="filtro2">Empresa</label>
                                    </div>
                                </div>
                                {submitted && !tipousuario.filtro && <small className="p-invalid">Tipo é requerido.</small>}
                            </div>
                        </div>
                        {/* <div className="p-field">
                            <label htmlFor="dataatualizacao">Data de Atualização</label>
                            <InputText id="dataatualizacao" value={tipousuario.dataatualizacao} onChange={(e) => onInputChange(e, 'dataatualizacao')} required className={classNames({ 'p-invalid': submitted && !tipousuario.dataatualizacao })} />
                            {submitted && !tipousuario.dataatualizacao && <small className="p-invalid">Data de Atualização é requerido.</small>}
                        </div> */}
                    </Dialog>
                    <Dialog visible={deleteTipoUsuarioDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteTipoUsuarioDialogFooter} onHide={hideDeleteTipoUsuarioDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {tipousuario && <span>Tem certeza que deseja excluir <b>{tipousuario.tipousuario}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
