import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import classNames from 'classnames';
import { Toast } from '../components/toast/Toast';
import { InputText } from '../components/inputtext/InputText';
import { Button } from '../components/button/Button';
import { Panel } from '../components/panel/Panel';
import { Dropdown } from '../components/dropdown/Dropdown';
import { Voltar } from './Componentes_Detalhes/Voltar';
import { ChromePicker } from 'react-color';
import { Checkbox } from '../components/checkbox/Checkbox';
import { TipoStatusNavegacaoDetalhes } from './TipoStatusNavegacaoDetalhes';

export const TipoStatusDetalhes = (props) => {

    let emptyTipoStatus = {
        idtipostatus: null,
        tipostatus: '',
        cor: '#30a2c0',
        corfonte: '#30a2c0',
        finalizaocorrencia: false,
        corhexa: '#30a2c0',
    };


    const [tipoStatus, setTipoStatus] = useState(emptyTipoStatus);                            // Item que está sendo editado
    // const [listaTiposStatus, setListaTipoStatus] = useState(null);             // Lista com todos os Tipos de itens 
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);
    const dt = useRef(null);
    const [id, setId] = useState(props.match.params.id);

    useEffect(() => {
        // Item que está sendo editado
        api.get(`/tipostatus/${id}`).then((res) => {
            setTipoStatus(res.data);
        });

        // Lista com todos os Tipos de itens --acho que não precisa, é pro combo
        // api.get(`/tipoitem`).then((res) => {
        //     setListaTiposItem(res.data);
        // });
    }, []);

    const saveTipoStatus = () => {
        setSubmitted(true);
        if (tipoStatus.tipostatus.trim()) {
            let _tipoStatus = { ...tipoStatus };
            api.put('/tipostatus', _tipoStatus).then(res => {
                if (res.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                })
        }
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _tipostatus = { ...tipoStatus };
        _tipostatus[`${name}`] = val;
        setTipoStatus(_tipostatus);
    }

    const handleChangeComplete3 = (color) => {
        let _tipostatus = { ...tipoStatus };
        _tipostatus.cor = '$FF' + color.hex.substring(1, 7);
        setTipoStatus(_tipostatus);
    };

    const handleChangeComplete2 = (color) => {
        let _tipostatus = { ...tipoStatus };
        _tipostatus.corfonte = '$FF' + color.hex.substring(1, 7);
        setTipoStatus(_tipostatus);
    };

    const handleChangeComplete = (color) => {
        let _tipostatus = { ...tipoStatus };
        _tipostatus.corhexa = color.hex;
        console.log(color.hex);
        setTipoStatus(_tipostatus);
    };

    const onCheckChange = (value) => {
        let _tipostatus = { ...tipoStatus };
        _tipostatus['finalizaocorrencia'] = value;
        setTipoStatus(_tipostatus);
    }

    return (
        <div className="p-grid crud-demo">
            <div className="content-section introduction">
                <h5>Tipo de Status - {tipoStatus.tipostatus}</h5>
            </div>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <Panel header={'Status'} >
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-2" >
                                <label htmlFor="idtipostatus">Id</label>
                                <InputText id="idtipostatus" value={tipoStatus.idtipostatus} readOnly={true} disabled={true} />
                            </div>
                            <div className="p-field p-col-8" >
                                <label htmlFor="tipostatus">Status</label>
                                {/* <Dropdown inputId="idtipoitem" optionValue="idtipoitem" optionLabel="tipoitem" value={item.idtipoitem}
                                    options={listaTiposItem} onChange={(e) => onInputChange(e, 'idtipoitem')}
                                    optionLabel="tipoitem" filter showClear filterBy="tipoitem" placeholder="Selecione"
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !item.idtipoitem })} />
                                {submitted && !item.idtipoitem && <small className="p-invalid">Tipo de Item é requerido.</small>} */}
                                <InputText id="tipostatus" value={tipoStatus.tipostatus} onChange={(e) => onInputChange(e, 'tipostatus')}/>
                            </div>
                            <div className="p-field p-col-2" >
                                <div className= 'p-mt-5' >
                                <div className="p-field-checkbox ">
                                    <Checkbox inputId="finaliza" checked={tipoStatus.finalizaocorrencia} onChange={(e) => onCheckChange(e.checked)} />
                                    <label htmlFor="finaliza"> Finaliza ocorrência</label>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="p-field p-col-3" >
                           
                            </div> */}


                            <div className="p-field p-col-3" >
                                <label htmlFor="cor">Cor</label>
                                <ChromePicker id="cor"
                                    disableAlpha
                                    color={'#' + tipoStatus.cor.substring(3, 9)}
                                    onChangeComplete={handleChangeComplete3}
                                />
                            </div>
                            <div className="p-field p-col-3" >
                                <label htmlFor="corfonte">Cor da Fonte</label>
                                <ChromePicker id="corfonte"
                                    disableAlpha
                                    color={'#' + tipoStatus.corfonte.substring(3, 9)}
                                    onChangeComplete={handleChangeComplete2}
                                />
                            </div>
                            <div className="p-field p-col-3" >
                                <label htmlFor="corhexa">Cor - Gráficos</label>
                                {/* <InputText id="corhexa" value={tipostatus.corhexa} onChange={(e) => onInputChange(e, 'corhexa')} className={classNames({ 'p-invalid': submitted && !tipostatus.corhexa })} />
                            {submitted && !tipostatus.corhexa && <small className="p-invalid">Cor Hexadecimal é requerido.</small>} */}
                                <ChromePicker id="corhexa"
                                    disableAlpha
                                    color={tipoStatus.corhexa}
                                    onChangeComplete={handleChangeComplete}
                                />
                            </div>

                            {/* <div className="p-field p-col-12">
                                <label htmlFor="item">Item</label>
                                <InputText id="item" value={item.item} onChange={(e) => onInputChange(e, 'item')} required autoFocus className={classNames({ 'p-invalid': submitted && !item.item })} />
                                {submitted && !item.item && <small className="p-invalid">Item é requerido.</small>}
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="descricao">Descrição</label>
                                <InputText id="descricao" value={item.descricao} onChange={(e) => onInputChange(e, 'descricao')} required className={classNames({ 'p-invalid': submitted && !item.descricao })} />
                                {submitted && !item.descricao && <small className="p-invalid">Descricao é requerido.</small>}
                            </div>                             */}
                        </div>
                    </Panel>
                    <br />
                    {/* <ItemAtividades valorId={id}></ItemAtividades> */}
                    <TipoStatusNavegacaoDetalhes valorId={id} />

                    <br />
                    <div>
                        <React.Fragment>
                            <div className="p-d-flex p-justify-end">
                                <div>
                                    <Voltar page={"tipostatus"} ></Voltar>
                                    <Button label="Salvar" icon="pi pi-check" className="p-button-success" onClick={saveTipoStatus} />
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        </div>
    );
}
