import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { Toast } from '../components/toast/Toast';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { Panel } from '../components/panel/Panel';
import { Dropdown } from '../components/dropdown/Dropdown';
import { Checkbox } from '../components/checkbox/Checkbox';
import { Voltar } from './Componentes_Detalhes/Voltar';
import { AtividadeChecklist } from './AtividadeChecklist';

export const AtividadeDetalhes = (props) => {

    let emptyAtividade = {
        idatividade: null,
        atividade: '',
        iditem: null,
        idtipoatividade: null,
        idprioridade: null,
        idtipoexecutor: null,
        avisodeimportancia: false,
        ajustededata: false,
        requeraprovacaobaixa: false,
        idtipousuarioaprovador: null,
        periodicidade: null,
        idundperiodicidade: null,
        doctecapoio: false,
        ativo: '',
        manutencaogarantia: '',
        compoelaudo: '',
        tag: null
    };

    const [atividade, setAtividade] = useState(emptyAtividade);             // Atividade que está sendo editada
    const [listaTiposAtividades, setListaTiposAtividades] = useState(null); // Lista dos Tipos de Atividades
    const [listaPeriodicidade, setListaPeriodicidade] = useState(null);     // Lista das Periodicidades 
    const [listaPrioridade, setListaPrioridade] = useState(null);           // Lista das Prioridades 
    const [listaTipoExecutor, setListaTipoExecutor] = useState(null);       // Lista dos Tipos de Executores 
    const [listaTipoUsuario, setListaTipoUsuario] = useState(null);         // Lista dos Tipos de Usuarios 
    const [submitted, setSubmitted] = useState(false);
    //const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    //const dt = useRef(null);
    const [id, setId] = useState(props.match.params.id);

    useEffect(() => {
        const idatividade = id;
        // Atividade que está sendo editada
        api.get(`/itensatividades/${idatividade}`).then((res) => { setAtividade(res.data); });

        api.get(`/tipoatividade`).then((res) => { setListaTiposAtividades(res.data); });
        api.get(`/undperiodicidade`).then((res) => { setListaPeriodicidade(res.data); });
        api.get(`/prioridades`).then((res) => { setListaPrioridade(res.data); });
        api.get(`/tipoexecutor`).then((res) => { setListaTipoExecutor(res.data); });
        api.get(`/tipousuario`).then((res) => { setListaTipoUsuario(res.data); });
    }, []);

    /********************************************************************* */

    /* CRUD */
    const saveItem = () => {
        setSubmitted(true);
        if (atividade.atividade.trim()) {
            api.put('/itensatividades', atividade).then(res => {
                if (res.status === 200) {
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                })
        }
    }

    /********************************************************************* */

    /** AUXILIARES */
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _atividade = { ...atividade };
        _atividade[`${name}`] = val;
        setAtividade(_atividade);
    }

    const onTipoAtividadeChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.idtipoatividade = e.value;
        setAtividade(_atividade);
    }

    const onPeriodicidadeChange = (e) => {
        let _atividade = { ...atividade };
        let id = e.value;
        _atividade.idundperiodicidade = id;

        for (let i = 0; i < listaPeriodicidade.length; i++) {
            if (listaPeriodicidade[i].idundperiodicidade === id) {
                _atividade.periodicidade = listaPeriodicidade[i].qtddias;
                break;
            }
        }

        setAtividade(_atividade);
    }

    const onPrioridadeChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.idprioridade = e.value;
        setAtividade(_atividade);
    }
    const onTipoExecutorChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.idtipoexecutor = e.value;
        setAtividade(_atividade);
    }
    const onTipoUsuarioAprovadorChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.idtipousuarioaprovador = e.value;
        setAtividade(_atividade);
    }

    const onAjusteDataChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.ajustededata = e.checked;
        setAtividade(_atividade);
    }
    const onAvisoImportanciaChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.avisodeimportancia = e.checked;
        setAtividade(_atividade);
    }
    const onDocTecApoioChange = (e) => {
        let _atividade = { ...atividade };
        _atividade.doctecapoio = e.checked;
        setAtividade(_atividade);
    }
    const onAprovacaoBaixaChange = (e) => {
        let _atividade = { ...atividade };
        if (e.checked) {
            _atividade.requeraprovacaobaixa = true;
        } else {
            _atividade.requeraprovacaobaixa = false;
        }
        setAtividade(_atividade);
    }
    /********************************************************************* */

    /** ESTETICAS */

    /********************************************************************* */
    return (
        <div className="p-grid crud-demo">
            <div className="content-section introduction">
                <h5>Atividade - {atividade.atividade}</h5>
            </div>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <Panel header={'Atividade'} >
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-2" >
                                <label htmlFor="idatividade">Id</label>
                                <InputText id="idatividade" value={atividade.idatividade} readOnly={true} disabled={true} />
                            </div>
                            <div className="p-field p-col-10" >
                                <label htmlFor="atividade">Atividade</label>
                                <InputText id="atividade" value={atividade.atividade} onChange={(e) => onInputChange(e, 'atividade')} required autoFocus
                                    className={classNames({ 'p-invalid': submitted && !atividade.atividade })} />
                                {submitted && !atividade.atividade && <small className="p-invalid">Atividade é requerido.</small>}
                            </div>
                            <div className="p-field p-col-4" >
                                <label htmlFor="idtipoatividade">Tipo de Atividade</label>
                                <Dropdown inputId="idtipoatividade" optionValue="idtipoatividade" optionLabel="tipoatividade" value={atividade.idtipoatividade}
                                    options={listaTiposAtividades} onChange={(e) => onInputChange(e, 'idtipoatividade')}
                                    filter showClear filterBy="tipoatividade" placeholder="Selecione"
                                    required autoFocus className={classNames({ 'p-invalid': submitted && !atividade.idtipoatividade })} />
                                {submitted && !atividade.idtipoatividade && <small className="p-invalid">Tipo de Atividade é requerido.</small>}
                            </div>
                            <div className="p-field p-col-4" >
                                <label htmlFor="idundperiodicidade">Periodicidade</label>
                                <Dropdown inputId="idundperiodicidade" optionValue="idundperiodicidade" optionLabel="undperiodicidade" value={atividade.idundperiodicidade} options={listaPeriodicidade}
                                    onChange={(e) => onPeriodicidadeChange(e)} placeholder="Selecione"
                                    required className={classNames({ 'p-invalid': submitted && !atividade.idundperiodicidade })} />
                                {submitted && !atividade.idundperiodicidade && <small className="p-invalid">Periodicidade é requerido.</small>}
                            </div>
                            <div className="p-field p-col-4" >
                                <label htmlFor="idprioridade">Prioridade</label>
                                <Dropdown inputId="idprioridade" optionValue="idprioridade" optionLabel="prioridade" value={atividade.idprioridade} options={listaPrioridade}
                                    onChange={(e) => onPrioridadeChange(e)} placeholder="Selecione"
                                    required className={classNames({ 'p-invalid': submitted && !atividade.idprioridade })} />
                                {submitted && !atividade.idprioridade && <small className="p-invalid">Prioridade é requerido.</small>}
                            </div>                            
                            <div className="p-field p-col-4" >
                                <label htmlFor="idtipoexecutor">Tipo de  Executor</label>
                                <Dropdown inputId="idtipoexecutor" optionValue="idtipoexecutor" optionLabel="tipoexecutor" value={atividade.idtipoexecutor} options={listaTipoExecutor}
                                    onChange={(e) => onTipoExecutorChange(e)} placeholder="Selecione"
                                    required className={classNames({ 'p-invalid': submitted && !atividade.idtipoexecutor })} />
                                {submitted && !atividade.idtipoexecutor && <small className="p-invalid">Tipo de Executor é requerido.</small>}
                            </div>                            
                            <div className="p-field p-col-4" >
                                <label htmlFor="idtipoexecutor">Tipo de  Executor</label>
                                <Dropdown inputId="idtipoexecutor" optionValue="idtipoexecutor" optionLabel="tipoexecutor" value={atividade.idtipoexecutor} options={listaTipoExecutor}
                                    onChange={(e) => onTipoExecutorChange(e)} placeholder="Selecione"
                                    required className={classNames({ 'p-invalid': submitted && !atividade.idtipoexecutor })} />
                                {submitted && !atividade.idtipoexecutor && <small className="p-invalid">Tipo de Executor é requerido.</small>}
                            </div>
                            <div className="p-field p-col-4" >
                                <label htmlFor="idtipousuarioaprovador">Tipo de Usuário</label>
                                <Dropdown inputId="idtipousuarioaprovador" optionValue="idtipousuario" optionLabel="tipousuario" value={atividade.idtipousuarioaprovador} options={listaTipoUsuario}
                                    onChange={(e) => onTipoUsuarioAprovadorChange(e)} placeholder="Selecione"
                                    required className={classNames({ 'p-invalid': submitted && !atividade.idtipoexecutor })} />
                                {submitted && !atividade.idtipousuarioaprovador && <small className="p-invalid">Tipo de Usuário é requerido.</small>}
                            </div>                            
                        </div>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field-checkbox p-col-3">
                                <Checkbox inputId="ajustededata" name="ajustededata" value={atividade.ajustededata} checked={atividade.ajustededata}
                                    onChange={e => onAjusteDataChange(e, 'ajustededata')} className="p-mr-2 p-mt-2 p-as-center" />
                                <label htmlFor="ajustededata" className="p-mr-3 p-mt-2 p-as-center" >Ajuste de Data</label>
                            </div>
                            <div className="p-field-checkbox p-col-3">
                                <Checkbox inputId="avisodeimportancia" name="avisodeimportancia" value={atividade.avisodeimportancia}
                                    checked={atividade.avisodeimportancia} onChange={e => onAvisoImportanciaChange(e, 'avisodeimportancia')}
                                    className="p-mr-2 p-mt-2 p-as-center" />
                                <label htmlFor="avisodeimportancia" className="p-mr-3 p-mt-2 p-as-center" >Aviso de Importância</label>
                            </div>
                            <div className="p-field-checkbox p-col-3">
                                <Checkbox inputId="doctecapoio" name="doctecapoio" value={atividade.doctecapoio} checked={atividade.doctecapoio}
                                    onChange={(e) => onDocTecApoioChange(e, 'doctecapoio')} />
                                <label htmlFor="doctecapoio" className="p-mr-3">Doc. Téc. Apoio</label>
                            </div>
                            <div className="p-field-checkbox p-col-3">
                                <Checkbox inputId="requeraprovacaobaixa" name="requeraprovacaobaixa" value={atividade.requeraprovacaobaixa}
                                    checked={atividade.requeraprovacaobaixa} onChange={(e) => onAprovacaoBaixaChange(e, 'requeraprovacaobaixa')} />
                                <label htmlFor="requeraprovacaobaixa" className="p-mr-2">Requer Aprovação Baixa</label>
                            </div>                            
                        </div>
                    </Panel>
                    <br />
                    <AtividadeChecklist valorId={id}></AtividadeChecklist>
                    <br />
                    <div>
                        <React.Fragment>
                            <div className="p-d-flex p-justify-end">
                                <div>
                                    <Voltar page={"itens"} ></Voltar>
                                    <Button label="Salvar" icon="pi pi-check" className="p-button-success" onClick={saveItem} />
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </div>
            </div>
        </div>
    );
}
