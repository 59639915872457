import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import apiCli from '../../service/apiCli';
import '../../csspersonalizado/container.css';
import { Redirect } from 'react-router';
import { Chart } from '../../components/chart/Chart';

export const GraficoOcorrenciasAreas = (props) => {

    const [grafico, setGrafico] = useState(null);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [areaClicada, setAreaClicada] = useState(null);
    const [descricaoAreaClicada, setDescricaoAreaClicada] = useState(null);
    const [qtdAreas, setQtdAreas] = useState(null);
    const [IdClienteArea, setIdClienteArea] = useState(null);
    const [loading, setLoading] = useState(true);

    const detalhes = (evt, item) => {
        if (item.length > 0) {
            setAreaClicada(grafico.Ids[item[0]._index]);
            setDescricaoAreaClicada(grafico.labels[item[0]._index]);
            setQtdAreas(grafico.QtdCA[item[0]._index]);
            setIdClienteArea(grafico.IdCA[item[0]._index]);
            setExibirDetalhes(true);            
        }
        
    };

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        }

    };

    const darkOptions = {
        legend: {
            labels: {
                fontColor: '#ebedef'
            }
        },
        responsive: true
    };

    const optionExibirDetalhes = {
        legend: {
            display: false,
            labels: {
                fontColor: '#495057'
            }
        },
        responsive: true,
        hover: {
            onHover: function(e) {
               var point = this.getElementAtEvent(e);
               if (point.length) e.target.style.cursor = 'pointer';
               else e.target.style.cursor = 'default';
            }
        },
        'onClick': detalhes,
        maintainAspectRatio: false
    };

    useEffect(() => {
        setLoading(true);
        apiCli.get(`/ocorrencias/grupoarea/id/${props.idCliente}`)
            .then(res => {
                setGrafico(res.data);
                setLoading(false);
            })
    }, [props.idCliente]);


    if (exibirDetalhes) {

        if (qtdAreas > 1) {
            return <Redirect push to={`/ocorrenciasareasdetalheslist/${areaClicada}/${descricaoAreaClicada}`} />
        }
        else {
            return <Redirect push to={`/ocorrenciasareaslist/${IdClienteArea}/${descricaoAreaClicada}`} />
        }
    }
    return (
        <>
            <Chart type="horizontalBar" data={grafico} options={optionExibirDetalhes}  height="500px" />
        </>
    )
}