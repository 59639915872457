import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';

export const TipoItem = () => {

  let emptyTipoItem = {
    idtipoitem: null,
    tipoitem: '',
  };

  const [tipoitem, setTipoItem] = useState(emptyTipoItem);
  const [listaTipoItem, setListaTipoItem] = useState(null);
  const [tipoitemDialog, setTipoItemDialog] = useState(false);
  const [deleteTipoItemDialog, setDeleteTipoItemDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
      api.get(`/tipoitem`).then((res) => {
          setListaTipoItem(res.data);
      });
  }, []);

  const openNew = () => {
    setTipoItem(emptyTipoItem);
    setSubmitted(false);
    setTipoItemDialog(true);
  }

  const saveTipoItem = () => {
    setSubmitted(true);
    if (tipoitem.tipoitem.trim()) {
        let _listaTipoItem = [...listaTipoItem];
        let _tipoitem = { ...tipoitem };
        if (_tipoitem.idtipoitem){
            const index = findIndexById(_tipoitem.idtipoitem);
            api.put('/tipoitem', _tipoitem).then(res => {
                if (res.status === 200) {
                    _listaTipoItem[index] = _tipoitem;
                    setListaTipoItem(_listaTipoItem);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                })
        }
        else {
            _tipoitem.idtipoitem = 0;
            api.post('/tipoitem',_tipoitem).then(res => {
                if (res.status === 200) {
                    _tipoitem.idtipoitem = res.data.id;
                    _listaTipoItem.push(_tipoitem);
                    setListaTipoItem(_listaTipoItem);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                console.log(error);
            })
        }
        // setListaTipoItem(_listaTipoItem);
        setTipoItemDialog(false);
        setTipoItem(emptyTipoItem);
    }
  }

  const editTipoItem = (tipoitem) => {
      setTipoItem({ ...tipoitem});
      setTipoItemDialog(true);
  }

  const deleteTipoItem= () => {
      api.delete(`/tipoitem/${tipoitem.idtipoitem}`).then(res => {
          if (res.status === 200){
              let _tipoitem= listaTipoItem.filter(val => val.idtipoitem !== tipoitem.idtipoitem);
              setListaTipoItem(_tipoitem);
              setDeleteTipoItemDialog(false);
              setTipoItem(emptyTipoItem);
              toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
          }
      })
      .catch(error => {
          toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
          console.log(error);
      })
  }

  const confirmDeleteTipoItem= (tipoitem) => {
      setTipoItem(tipoitem);
      setDeleteTipoItemDialog(true);
  }

  const hideDialog = () => {
      setSubmitted(false);
      setTipoItemDialog(false);
  }

  const hideDeleteTipoItemDialog = () => {
      setDeleteTipoItemDialog(false);
  }

  const findIndexById = (n) => {
      let index = -1;
      for (let i = 0; i < listaTipoItem.length; i++) {
          if (listaTipoItem[i].idtipoitem === n) {
              index = i;
              break;
          }
      }
      return index;
  }

  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';
      let _tipoitem = { ...tipoitem};
      _tipoitem[`${name}`] = val;
      setTipoItem(_tipoitem);
  }

  const leftToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
          </React.Fragment>
      )
  }

  const exportExcel = () => {
      import('xlsx').then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(listaTipoItem);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          saveAsExcelFile(excelBuffer, 'listaTipoItem');
      });
  }

  const saveAsExcelFile = (buffer, fileName) => {
      import('file-saver').then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  const rightToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
          </React.Fragment>
      )
  }

  const actionBodyTemplate = (rowData) => {
      return (
          <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editTipoItem(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteTipoItem(rowData)} />
          </div>
      );
  }

  const header = (
      <div className="table-header">
          <h5 className="p-m-0 p-text-bold">Tipo de Item</h5>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
          </span>
      </div>
  );

  const tipoitemDialogFooter = (
      <>
          <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
          <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveTipoItem} />
      </>
  );

  const deleteTipoItemDialogFooter = (
      <>
          <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTipoItemDialog} />
          <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteTipoItem} />
      </>
  );

  return (
      <div className="p-grid crud-demo">
          <div className="p-col-12">
              <div className="card">

                  <Toast ref={toast} />
                  <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                  <DataTable
                      ref={dt} value={listaTipoItem} dataKey="idtipoitem"
                      paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Tipos de Item" globalFilter={globalFilter}
                      emptyMessage="Nenhum Tipo de Item encontrado." header={header}>
                      <Column field="idtipoitem" header="Id" sortable style={{ width: '6%' }} />
                      <Column field="tipoitem" header="Descrição" sortable ></Column>
                      <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                  </DataTable>
                  <Dialog visible={tipoitemDialog} style={{ width: '450px' }} header="Tipo de Item" modal className="p-fluid" footer={tipoitemDialogFooter} onHide={hideDialog}>
                      <div className="p-field">
                          <label htmlFor="tipoitem">Descrição</label>
                          <InputText id="tipoitem" value={tipoitem.tipoitem} onChange={(e) => onInputChange(e, 'tipoitem')} required autoFocus className={classNames({ 'p-invalid': submitted && !tipoitem.tipoitem})} />
                          {submitted && !tipoitem.tipoitem && <small className="p-invalid">Descrição é requerido.</small>}
                      </div>
                  </Dialog>
                  <Dialog visible={deleteTipoItemDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteTipoItemDialogFooter} onHide={hideDeleteTipoItemDialog}>
                      <div className="confirmation-content">
                          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                          {tipoitem && <span>Tem certeza que deseja excluir <b>{tipoitem.tipoitem}</b>?</span>}
                      </div>
                  </Dialog>
              </div>
          </div>
      </div>
  );
}
