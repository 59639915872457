import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { ChromePicker } from 'react-color';
import { Panel } from '../components/panel/Panel';

export const SistemaConstrutivoItens = (props) => {

    let emptySistemaConstrutivo = {
        idsistemaconstrutivo: null,
        sistemaconstrutivo: '',
        tag: '',
        cor: '',
    };

    const [sistemaconstrutivo, setSistemaConstrutivo] = useState(emptySistemaConstrutivo);
    const [listaSistemaConstrutivo, setListaSistemaConstrutivo] = useState(null);
    const [sistemaconstrutivoDialog, setSistemaConstrutivoDialog] = useState(false);
    const [deleteSistemaConstrutivoDialog, setDeleteSistemaConstrutivoDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [id, setId] = useState(props.match.params.id);

    useEffect(() => {
        api.get(`/sistemaconstrutivo/${id}`).then((res) => {
            setSistemaConstrutivo(res.data);
        });
    }, []);

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _sistemaconstrutivo = { ...sistemaconstrutivo};
        _sistemaconstrutivo[`${name}`] = val;
        setSistemaConstrutivo(_sistemaconstrutivo);
    }
  

    return (
        <div className="p-grid crud-demo">
            <div className="content-section introduction">
                <h5>Sistema Constru - {sistemaconstrutivo.sistemaconstrutivo}</h5>
            </div>
            <div className="p-col-12">
                <div className="card">
                    <Toast ref={toast} />

                    <Panel header={'Itens'} ></Panel>
                    <div className="p-field">
                        <label htmlFor="sistemaconstrutivo">Sistema Construtivo</label>
                        <InputText id="sistemaconstrutivo" value={sistemaconstrutivo.sistemaconstrutivo} onChange={(e) => onInputChange(e, 'sistemaconstrutivo')} required autoFocus className={classNames({ 'p-invalid': submitted && !sistemaconstrutivo.sistemaconstrutivo })} />
                        {submitted && !sistemaconstrutivo.sistemaconstrutivo && <small className="p-invalid">Sistema Construtivo é requerido.</small>}
                    </div>
                </div>
            </div>
        </div>
    )
}