import { Panel } from 'primereact/panel';
import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toolbar } from '../components/toolbar/Toolbar';
import { InputText } from '../components/inputtext/InputText';
import { Button } from '../components/button/Button';
import { Dialog } from '../components/dialog/Dialog';
import { Dropdown } from '../components/dropdown/Dropdown';
import classNames from 'classnames';
import { Toast } from '../components/toast/Toast';
import validator from 'validator'
import { confirmPopup } from '../components/confirmpopup/ConfirmPopup';
import { ProgressBar } from '../components/progressbar/ProgressBar';
import { set } from 'date-fns';

export const ClienteUsuario = (props) => {

    let emptyUsuario = {
        idusuario: null,
        nome: '',
        email: '',
        idtipousuario: null,
        tipousuario: ''
    };

    let emptyItemLista = {
        idclientesusuarios: null,
        idclienteativacao: null,
        idusuario: null,
        datadesativacao: null,
        dataatualizacao: null,
        email: '',
        nome: '',
        idtipousuario: null,
        tipousuario: ''
    }

    const [usuario, setUsuario] = useState(emptyUsuario);
    const [usuarioAux, setUsuarioAux] = useState(emptyUsuario);
    const [listaUsuario, setListaUsuario] = useState([]);
    const [listaTipoUsuario, setListaTipoUsuario] = useState([]);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [usuarioDialog, setUsuarioDialog] = useState(false);
    const [edicao, setEdicao] = useState(false);
    const [EmailInvalido, setEmailInvalido] = useState(false);
    const [cadastroVisible, setCadastroVisible] = useState(false);
    const [deleteUsuarioDialog, setDeleteUsuarioDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const dt = useRef(null);
    const toast = useRef(null);

    useEffect(() => {
        api.get(`/usuarios/cliente/${props.valorId}`)
            .then((res) => {
                setListaUsuario(res.data);
            });
        api.get(`/tipousuario/tipo/c`).then((res) => {
            setListaTipoUsuario(res.data);
        });
    }, [props.valorId]);


    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;
        setUsuario(_usuario);
    }

    const onInputChangeEmail = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _usuario = { ...usuario };
        _usuario[`${name}`] = val;
        setUsuario(_usuario);

        if (validator.isEmail(val)) {
            setEmailInvalido(false)
        }
        else {
            setEmailInvalido(val.length > 0 ? true : false)
        }
    }

    const findIndexById = (lista, campo, n) => {
        let index = -1;
        for (let i = 0; i < lista.length; i++) {
            if (lista[i][campo] === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onTipoUsuarioChange = (e) => {
        let _usuario = { ...usuario };
        _usuario.idtipousuario = e.value;
        let index = findIndexById(listaTipoUsuario, 'idtipousuario', e.value);
        _usuario.tipousuario = listaTipoUsuario[index]['tipousuario'];
        setUsuario(_usuario);
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
                </span>
            </React.Fragment>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div><Button label="Novo" icon="pi pi-plus" className="p-button p-mr-2" onClick={novoUsuario} /></div>
            </React.Fragment>
        )
    }

    const confirmDeleteUsuario = (usuarioItem) => {
        setUsuario(usuarioItem);
        setDeleteUsuarioDialog(true);
    }

    const novoUsuario = () => {
        setUsuario(emptyUsuario)
        setSubmitted(false);
        setCadastroVisible(false);
        setUsuarioDialog(true);
    }

    const editUsuarios = (usuarios) => {
        let _usuario = { ...usuario };
        _usuario.idtipousuario = usuarios.idtipousuario;
        _usuario.nome = usuarios.nome;
        _usuario.email = usuarios.email;
        _usuario.idusuario = usuarios.idusuario;
        _usuario.tipousuario = usuarios.tipousuario;
        setUsuario(_usuario);
        setUsuarioAux(_usuario);
        setSubmitted(false);
        setCadastroVisible(true);
        setUsuarioDialog(true);
    }

    const saveUsuarios = () => {
        setLoading(true);
        setSubmitted(true);
        if (usuario.nome.trim() && usuario.email.trim() && (validator.isEmail(usuario.email)) && usuario.idtipousuario) {
            let _listaUsuarios = [...listaUsuario];
            let _usuario = { ...usuario };

            if (_usuario.idusuario) {
                const index = findIndexById(listaUsuario, 'idusuario', _usuario.idusuario);
                if (_usuario.nome !== usuarioAux.nome || _usuario.email !== usuarioAux.email) {
                    api.put('/usuarios', _usuario)
                        .then(res => {
                            if (res.status === 200) {
                                let item = { ...emptyItemLista };
                                item.idusuario = _usuario.idusuario;
                                item.nome = _usuario.nome;
                                item.email = _usuario.email;
                                item.idtipousuario = _usuario.idtipousuario;
                                item.tipousuario = _usuario.tipousuario;
                                if (index !== -1) {
                                    _listaUsuarios[index] = item;
                                    setListaUsuario(_listaUsuarios);
                                    setLoading(false);
                                    setUsuarioDialog(false);
                                    setUsuario(emptyUsuario);                                    
                                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                                }
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                            console.log(error);
                        })
                }
                if (_usuario.idtipousuario !== usuarioAux.idtipousuario) {
                    let ativacao = { idcliente: props.valorId, idusuario: _usuario.idusuario, idtipousuario: _usuario.idtipousuario };
                    api.post('/clientesativacao', ativacao)
                        .then((res) => {
                            if (res.status === 200) {
                                let item = { ...emptyItemLista };
                                item.idusuario = _usuario.idusuario;
                                item.nome = _usuario.nome;
                                item.email = _usuario.email;
                                item.idtipousuario = _usuario.idtipousuario;
                                item.tipousuario = _usuario.tipousuario;
                                if (index === -1) {
                                    _listaUsuarios.push(item);
                                }
                                else {
                                    _listaUsuarios[index] = item;
                                }
                                setListaUsuario(_listaUsuarios);
                                setLoading(false);
                                setUsuarioDialog(false);
                                setUsuario(emptyUsuario);
                                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                            }
                        })
                        .catch(error => {
                            setLoading(false);
                            toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                            console.log(error);
                        })
                }
            }
            else {
                _usuario.idusuario = 0;
                api.post('/usuarios', _usuario).then(res => {
                    if (res.status === 200) {
                        let ativacao = { idcliente: props.valorId, idusuario: res.data.id, idtipousuario: _usuario.idtipousuario };
                        api.post('/clientesativacao', ativacao).then((res) => {
                            if (res.status === 200) {
                                let item = { ...emptyItemLista };
                                item.idusuario = _usuario.idusuario;
                                item.nome = _usuario.nome;
                                item.email = _usuario.email;
                                item.idtipousuario = _usuario.idtipousuario;
                                item.tipousuario = _usuario.tipousuario;
                                _listaUsuarios.push(item);
                                setListaUsuario(_listaUsuarios);
                            }
                        })
                        setLoading(false);
                        setUsuarioDialog(false);
                        setUsuario(emptyUsuario);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                    }
                })
                    .catch(error => {
                        setLoading(false);
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
        }
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div >
                    <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editUsuarios(rowData)} />
                    <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteUsuario(rowData)} />
                </div>
            </React.Fragment>
        );
    }

    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDialog(false);
    }

    const hideDeleteDialog = () => {
        setDeleteUsuarioDialog(false);
    }

    const deleteUsuario = () => {
        api.delete(`/usuarios/${usuario.idusuario}`)
            .then(res => {
                if (res.status === 200) {
                    let _usuarios = listaUsuario.filter(val => val.idusuario !== usuario.idusuario);
                    setListaUsuario(_usuarios);
                    setDeleteUsuarioDialog(false);
                    setUsuario(emptyUsuario);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }


    const consultarUsuario = () => {
        if (usuario.email) {
            if (validator.isEmail(usuario.email)) {
                api.get(`/usuarios/email/${usuario.email}`)
                    .then((res) => {
                        if (res.status === 200 && res.data.idusuario) {
                            let usuario = emptyUsuario;
                            usuario.idusuario = res.data.idusuario;
                            usuario.nome = res.data.nome;
                            usuario.email = res.data.email;
                            setUsuario(usuario);
                            setUsuarioAux(usuario);
                        }
                    });
                setCadastroVisible(true);
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O email informado não é válido.', life: 3000 });
            }
        }
    }

    const deleteUsuarioDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteUsuario} />
        </>
    );

    const resetarSenha = () => {
        setLoading(true);
        api.put('/usuarios/resetarsenha', usuario)
            .then((res) => {
                if (res.status === 200) {
                    setLoading(false);
                    setUsuarioDialog(false);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Senha resetada. O usuário receberá um e-mail contendo a nova senha.', life: 3000 });
                }
            })
            .catch(error => {
                setLoading(false);
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu resetar a senha do usuário.', life: 3000 });
                console.log(error);
            })
    }

    const confirm = (event) => {
        confirmPopup({
            target: event.currentTarget,
            message: 'Confirma o reset?',
            icon: 'pi pi-replay',
            acceptClassName: 'p-button-success',
            accept: () => { resetarSenha() },
            acceptLabel: 'Sim',
            // reject,
            rejectLabel: 'Não'
        });
    };


    const usuarioDialogFooter = () => {
        if (cadastroVisible) {
            return (
                <div className="p-d-flex p-jc-between">
                    <div>
                        <Button label="Resetar senha" icon="pi pi-replay" className="p-button-text" onClick={(e) => { confirm(e) }} disabled={!usuario.idusuario || loading} />
                    </div>
                    <div>
                        <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} disabled={loading} />
                        <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveUsuarios} disabled={loading} />
                    </div>
                </div>
            )
        }
        else {
            return (
                <>
                    <Button label="Pesquisar" icon="pi pi-search" className="p-button-text" onClick={consultarUsuario} />
                </>
            )
        }
    };

    if (edicao) {
        // return <Redirect push to={`/area/${clienteArea.idclientesareas}/${idCliente}`} />;
    }
    else {
        return (
            <div>
                <Toast ref={toast} />
                <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} />
                <br></br>
                <DataTable
                    ref={dt} value={listaUsuario} dataKey="idusuario"
                    paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Áreas"
                    globalFilter={globalFilter}
                    emptyMessage="Nenhum registro encontrado."
                    // header={'Usuários'} 
                    sortMode="multiple" >
                    <Column field="nome" header="Nome" ></Column>
                    <Column field="email" header="E-mail" ></Column>
                    <Column field="tipousuario" header="Tipo de Usuário" ></Column>
                    <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                </DataTable>

                <Dialog visible={usuarioDialog} style={{ width: '450px' }} header="Usuários" modal className="p-fluid" footer={usuarioDialogFooter} onHide={hideDialog}>
                    <div hidden={cadastroVisible}>
                        <InputText id="pesquisarEmail" value={usuario.email} onChange={(e) => onInputChangeEmail(e, 'email')} />
                    </div>
                    <div hidden={!cadastroVisible}>
                        <div className="p-field">
                            <label htmlFor="tipousuario">Tipo de Usuário</label>
                            <Dropdown id="idtipousuario" optionValue="idtipousuario" optionLabel="tipousuario" value={usuario.idtipousuario} options={listaTipoUsuario}
                                onChange={(e) => onTipoUsuarioChange(e)} placeholder="Selecione" filter style={{ position: 'relative !important' }}
                                required className={classNames({ 'p-invalid': submitted && !usuario.idtipousuario })}
                                menuPortalTarget={document.body} />
                            {submitted && !usuario.idtipousuario && <small className="p-invalid">Tipo de Usuário é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="nome">Nome</label>
                            <InputText id="nome" value={usuario.nome} onChange={(e) => onInputChange(e, 'nome')} required className={classNames({ 'p-invalid': submitted && !usuario.nome })} />
                            {submitted && !usuario.nome && <small className="p-invalid">Nome é requerido.</small>}
                        </div>
                        <div className="p-field">
                            <label htmlFor="email">E-mail</label>
                            <InputText id="email" value={usuario.email} onChange={(e) => onInputChangeEmail(e, 'email')} required className={classNames({ 'p-invalid': submitted && (!usuario.email || EmailInvalido) })} />
                            {submitted && !usuario.email && <small className="p-invalid">E-mail é requerido.</small>}
                            {EmailInvalido && <small className="p-invalid">E-mail inválido.</small>}
                        </div>
                        <div className='p-mt-2' hidden={!loading}>
                            <ProgressBar mode="indeterminate" style={{ height: '6px' }} />
                        </div>

                        {/* <div className="p-field">
                            <label htmlFor="trocarsenha">trocarsenha</label>
                            <InputText id="trocarsenha" value={usuarios.trocarsenha} onChange={(e) => onInputChange(e, 'trocarsenha')} required className={classNames({ 'p-invalid': submitted && !usuarios.trocarsenha })} />
                            {submit
                                ted && !usuarios.trocarsenha && <small className="p-invalid">TrocarSenha é requerido.</small>}
                        </div> */}
                    </div>
                </Dialog>

                <Dialog visible={deleteUsuarioDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteUsuarioDialogFooter} onHide={hideDeleteDialog}>
                    <div className="confirmation-content">
                        <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                        {usuario && <span>Tem certeza que deseja excluir <b>{usuario.nome}</b>?</span>}
                    </div>
                </Dialog>
            </div>
        )
    }
}