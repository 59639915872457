import React, { useState, useEffect } from 'react';
import { Chart } from '../../components/chart/Chart';
import apiCli from '../../service/apiCli';
import { Redirect } from 'react-router';
import { Skeleton } from 'primereact/skeleton';


export const GraficoOcorrenciasGravidade = (props) => {

    const lightOptions = {
        legend: {
            labels: {
                fontColor: '#495057'
            }
        },
        'onClick': function (evt, item) {
            console.log('legend onClick', evt);
            console.log(this.state.grafico);
            // console.log('legd item', this.state.grafico.datasets[0].data[item[0]._index]);
        }

    };

    const darkOptions = {
        legend: {
            labels: {
                fontColor: '#ebedef'
            },
            position: 'bottom'
        },
        responsive: true,
        'onClick': function (evt, item) {
            console.log('legend onClick', evt);
            // console.log('legd item', this.state.grafico.datasets[0].data[item[0]._index]);
        }
    };

    const [loading, setLoading] = useState(true);
    const [grafico, setGrafico] = useState(null);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [gravidadeClicada, setGravidadeClicada] = useState(null);
    const [gravidadeDescricaoClicada, setGravidadeDescricaoClicada] = useState(null);

    const opa = (evt, item) => {
        if (item.length > 0) {
            setGravidadeClicada(grafico.Ids[item[0]._index]);
            setGravidadeDescricaoClicada(grafico.labels[item[0]._index]);
            setExibirDetalhes(true);
        }
    };

    const teste = {
        legend: {
            labels: {
                fontColor: '#495057'
            },
            position: 'bottom'
        },
        hover: {
            onHover: function(e) {
               var point = this.getElementAtEvent(e);
               if (point.length) e.target.style.cursor = 'pointer';
               else e.target.style.cursor = 'default';
            }
        },
        'onClick': opa
    };

    useEffect(() => {
        setLoading(true);
        apiCli.get(`/ocorrencias/gravidade/id/${props.idCliente}`)
            .then(res => {
                setGrafico(res.data);
                setLoading(false);
            });
    }, [props.idCliente]);


    if (exibirDetalhes) {
        return <Redirect push to={`/ocorrenciasgravidadelist/${gravidadeClicada}/${gravidadeDescricaoClicada}`} />;
    }
    else {
        return (
            <>
                {!loading && (
                    <Chart type="doughnut" data={grafico} options={teste} />
                )}
                {loading && (
                    <>
                        <div className="p-col-12 p-md-6 p-xl-12">
                            <Skeleton height="18rem" />
                        </div>
                    </>
                )}
            </>
        )
    }
}