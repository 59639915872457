import React from 'react';
import api from '../service/api';
import classNames from 'classnames';
import { useState, useEffect, useRef } from 'react';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import { Button } from '../components/button/Button';
import { RadioButton } from '../components/radiobutton/RadioButton';
import { Checkbox } from '../components/checkbox/Checkbox';
import { Badge } from '../components/badge/Badge';
import { ChromePicker, SketchPicker } from 'react-color';

export const TipoExecucao = () => {

    let emptyTipoExecucao = {
        idtipoexecucao: null,
        tipoexecucao: '',
        finalizaatividade: false,
        cor: '#30a2c0',
    };

    const [tipoexecucao, setTipoExecucao] = useState(emptyTipoExecucao);
    const [listaTipoExecucao, setListaTipoExecucao] = useState(null);
    const [tipoexecucaoDialog, setTipoExecucaoDialog] = useState(false);
    const [deleteTipoExecucaoDialog, setDeleteTipoExecucaoDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);

    useEffect(() => {
        api.get(`/tipoexecucao`).then((res) => {
            setListaTipoExecucao(res.data);
        });
    }, []);

    const openNew = () => {
        setTipoExecucao(emptyTipoExecucao);
        setSubmitted(false);
        setTipoExecucaoDialog(true);
    }

    const saveTipoExecucao = () => {
        setSubmitted(true);
        if (tipoexecucao.tipoexecucao.trim() && tipoexecucao.cor) {
            let _listaTipoExecucao = [...listaTipoExecucao];
            let _tipoexecucao = { ...tipoexecucao };

            if (_tipoexecucao.idtipoexecucao) {
                const index = findIndexById(_tipoexecucao.idtipoexecucao);
                api.put('/tipoexecucao', _tipoexecucao).then(res => {
                    if (res.status === 200) {
                        _listaTipoExecucao[index] = _tipoexecucao;
                        setListaTipoExecucao(_listaTipoExecucao);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _tipoexecucao.idtipoexecucao = 0;
                api.post('/tipoexecucao', _tipoexecucao).then(res => {
                    if (res.status === 200) {
                        _tipoexecucao.idtipoexecucao = res.data.id;
                        _listaTipoExecucao.push(_tipoexecucao);
                        setListaTipoExecucao(_listaTipoExecucao);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            // setListaTipoExecucao(_listaTipoExecucao);
            setTipoExecucaoDialog(false);
            setTipoExecucao(emptyTipoExecucao);
        }
    }

    const editTipoExecucao = (tipoexecucao) => {
        setTipoExecucao({ ...tipoexecucao });
        setTipoExecucaoDialog(true);
    }

    const deleteTipoExecucao = () => {
        api.delete(`/tipoexecucao/${tipoexecucao.idtipoexecucao}`).then(res => {
            if (res.status === 200) {
                let _tipoexecucao = listaTipoExecucao.filter(val => val.idtipoexecucao !== tipoexecucao.idtipoexecucao);
                setListaTipoExecucao(_tipoexecucao);
                setDeleteTipoExecucaoDialog(false);
                setTipoExecucao(emptyTipoExecucao);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteTipoExecucao = (tipoexecucao) => {
        setTipoExecucao(tipoexecucao);
        setDeleteTipoExecucaoDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTipoExecucaoDialog(false);
    }

    const hideDeleteTipoExecucaoDialog = () => {
        setDeleteTipoExecucaoDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaTipoExecucao.length; i++) {
            if (listaTipoExecucao[i].idtipoexecucao === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _tipoexecucao = { ...tipoexecucao };
        _tipoexecucao[`${name}`] = val;
        setTipoExecucao(_tipoexecucao);
    }

    const onCheckChange = (value) => {
        let _tipoexecucao = {...tipoexecucao};
        _tipoexecucao['finalizaatividade'] = value;
        setTipoExecucao(_tipoexecucao);
    }

    const handleChangeComplete = (color) => {
        let _tipoexecucao = {...tipoexecucao};
        _tipoexecucao.cor = color.hex;
        setTipoExecucao(_tipoexecucao);
      };

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaTipoExecucao);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaTipoExecucao');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editTipoExecucao(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteTipoExecucao(rowData)} />
            </div>
        );
    }

    const finalizaAtividadeTemplate = (rowData) => {
        var trueValue = <span className="product-badge" style={{ background: '#c8e6c9', color: '#256029' }} >{rowData.finalizaatividade && "sim"}</span>
        var falseValue = <span className="product-badge" style={{ background: '#ffcdd2', color: '#c63737' }} >{!rowData.finalizaatividade && "não"}</span>
        return (rowData.finalizaatividade ? trueValue : falseValue)
    }

    const corTemplate = (rowData) => {
        return (<Badge className="product-badge" size="large" style={{ background: `${rowData.cor}` }} ></Badge>);
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Tipo de Execução</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const tipoexecucaoDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveTipoExecucao} />
        </>
    );

    const deleteTipoExecucaoDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTipoExecucaoDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteTipoExecucao} />
        </>
    );

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className="card">

                    <Toast ref={toast} />
                    <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                    <DataTable
                        ref={dt} value={listaTipoExecucao} dataKey="idtipoexecucao"
                        paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Tipos de Execução" globalFilter={globalFilter}
                        emptyMessage="Nenhum Tipo de Execução encontrado." header={header}>
                        <Column field="idtipoexecucao" header="Id" sortable style={{ width: '6%' }} />
                        <Column field="tipoexecucao" header="Descrição" sortable ></Column>
                        <Column field="finalizaatividade" header="Finaliza Atividade" body={finalizaAtividadeTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                        <Column field="cor" header="Cor" body={corTemplate} style={{ width: '6%' }} ></Column>
                        <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                    </DataTable>
                    <Dialog visible={tipoexecucaoDialog} style={{ width: '450px' }} header="Tipo de Execução" modal className="p-fluid" footer={tipoexecucaoDialogFooter} onHide={hideDialog}>
                        <div className="p-field">
                            <label htmlFor="tipoexecucao">Tipo de Execução</label>
                            <InputText id="tipoexecucao" value={tipoexecucao.tipoexecucao} onChange={(e) => onInputChange(e, 'tipoexecucao')} required autoFocus className={classNames({ 'p-invalid': submitted && !tipoexecucao.tipoexecucao })} />
                            {submitted && !tipoexecucao.tipoexecucao && <small className="p-invalid">Tipo de Execução é requerido.</small>}
                        </div>
                        {/* <div className="p-field">
                            <label htmlFor="finalizaatividade">Finaliza Atividade</label>
                            <div className="p-grid">
                                <div className="p-col-12 p-md-3">
                                    <div className="p-field-radiobutton">
                                        <RadioButton inputId="option1" name="finalizaatividade" value="true" checked={tipoexecucao.finalizaatividade === 'true'} onChange={(e) => onInputChange(e, 'finalizaatividade')} />
                                        <label htmlFor="option1">Sim</label>
                                    </div>
                                </div>
                                <div className="p-col-12 p-md-3">
                                    <div className="p-field-radiobutton">
                                        <RadioButton inputId="option2" name="finalizaatividade" value="false" checked={tipoexecucao.finalizaatividade === 'false'} onChange={(e) => onInputChange(e, 'finalizaatividade')} />
                                        <label htmlFor="option2">Não</label>
                                    </div>
                                </div>
                            </div>
                            {submitted && !tipoexecucao.finalizaatividade && <small className="p-invalid">Finaliza Atividade é requerido.</small>}
                        </div> */}
                        <div className="p-field">
                            <div className="p-field-checkbox">
                                <Checkbox inputId="finaliza" checked={tipoexecucao.finalizaatividade} onChange={(e) => onCheckChange(e.checked)} />
                                <label htmlFor="finaliza"> Finaliza atividade</label>
                            </div>
                        </div>
                        <div className="p-field">
                            <label htmlFor="cor">Cor</label>
                            {/* <InputText id="cor" value={tipoexecucao.cor} onChange={(e) => onInputChange(e, 'cor')} required className={classNames({ 'p-invalid': submitted && !tipoexecucao.cor })} />
                            {submitted && !tipoexecucao.cor && <small className="p-invalid">Cor é requerido.</small>} */}
                             <ChromePicker id="corhexa"
                            disableAlpha  
                            color={tipoexecucao.cor}
                            onChangeComplete={handleChangeComplete}
                            />
                        </div>
                    </Dialog>
                    <Dialog visible={deleteTipoExecucaoDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteTipoExecucaoDialogFooter} onHide={hideDeleteTipoExecucaoDialog}>
                        <div className="confirmation-content">
                            <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                            {tipoexecucao && <span>Tem certeza que deseja excluir <b>{tipoexecucao.tipoexecucao}</b>?</span>}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    );
}
