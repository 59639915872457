import React, { useState, useEffect } from 'react';
import { GraficoOcorrenciasAreas } from './ocorrenciasAreas';
import { GraficoOcorrenciasGravidade } from './ocorrenciasGravidade';
import { GraficoOcorrenciasStatus } from './ocorrenciasStatus';
import { CardsOcorrencia } from './cardsOcorrencia';
import { Panel } from '../../components/panel/Panel';
import api from '../../service/api';
import { Toolbar } from '../../components/toolbar/Toolbar';

export const DashboardOcorrencias = (props) => {

    const idCliente = props.match.params.id;
    sessionStorage.setItem('idcliente', idCliente);

    const [cliente, setCliente] = useState(null);

    useEffect(() => {
        api.get(`/clientes/${idCliente}`)
            .then((res) => {
                if (res.status === 200) {
                    sessionStorage.setItem('cliente', res.data.nomefantasia);
                    setCliente(res.data);
                }
            });
    }, [idCliente]);

    const getGrafico = (tipo) => {
        if (tipo === "OcorrenciasAreas") {
            return (
                <React.Fragment>
                    <GraficoOcorrenciasAreas idCliente={idCliente} />
                </React.Fragment>
            )
        }
        if (tipo === "OcorrenciasGravidade") {
            return (
                <React.Fragment>
                    <GraficoOcorrenciasGravidade idCliente={idCliente} />
                </React.Fragment>
            )
        }
        if (tipo === "OcorrenciasStatus") {
            return (
                <React.Fragment>
                    <GraficoOcorrenciasStatus idCliente={idCliente} />
                </React.Fragment>
            )
        }
    }

    const getCliente = () => {

        if (cliente) {
            return cliente.nomefantasia;
        }
    }

    const leftToolbarTemplate = (
        <React.Fragment>
            <h5>{getCliente()}</h5>
        </React.Fragment >
    );

    return (
        <div>
            <Toolbar className="p-toolbar p-mb-2" left={leftToolbarTemplate} ></Toolbar>
            <div className="content-section introduction">
                <h3>Ocorrências</h3>
            </div>

            <div className="p-grid p-fluid dashboard">
                <CardsOcorrencia idCliente={idCliente} />
            </div>

            <Panel header={'Grupo de Área'}>
                {getGrafico('OcorrenciasAreas')}
            </Panel>

            <div className="p-grid p-mt-3">
                <div className="p-col-6">
                    <Panel header={'Gravidade'}>
                        {getGrafico('OcorrenciasGravidade')}
                    </Panel>
                </div>
                <div className="p-col-6">
                    <Panel header={'Status'}>
                        {getGrafico('OcorrenciasStatus')}
                    </Panel>
                </div>
            </div>
        </div>
    )
}