import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';
import { RadioButton } from '../components/radiobutton/RadioButton';
import { Checkbox } from '../components/checkbox/Checkbox';
import { ChromePicker, SketchPicker } from 'react-color';
import { Badge } from '../components/badge/Badge';
import { stream } from 'xlsx';
import { Link, Redirect } from 'react-router-dom';
import { parseWithOptions } from 'date-fns/fp';

export const TipoStatus = () => {

    let emptyTipoStatus = {
        idtipostatus: null,
        tipostatus: '',
        cor: '#30a2c0',
        corfonte: '#30a2c0',
        finalizaocorrencia: false,
        corhexa: '#30a2c0',
    };

    const [tipostatus, setTipoStatus] = useState(emptyTipoStatus);
    const [listaTipoStatus, setListaTipoStatus] = useState(null);
    const [tipostatusDialog, setTipoStatusDialog] = useState(false);
    const [deleteTipoStatusDialog, setDeleteTipoStatusDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [edicao, setEdicao] = useState(false);


    useEffect(() => {
        api.get(`/tipostatus`).then((res) => {
            setListaTipoStatus(res.data);
        });
    }, []);

    const openNew = () => {
        setTipoStatus(emptyTipoStatus);
        setSubmitted(false);
        setTipoStatusDialog(true);
    }


    const saveTipoStatus = () => {
        setSubmitted(true);
        if (tipostatus.tipostatus.trim()) {
            let _listaTipoStatus = [...listaTipoStatus];
            let _tipostatus = { ...tipostatus };
            if (_tipostatus.idtipostatus) {
                const index = findIndexById(_tipostatus.idtipostatus);
                api.put('/tipostatus', _tipostatus).then(res => {
                    if (res.status === 200) {
                        _listaTipoStatus[index] = _tipostatus;
                        setListaTipoStatus(_listaTipoStatus);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            else {
                _tipostatus.idtipostatus = 0;
                api.post('/tipostatus', _tipostatus).then(res => {
                    if (res.status === 200) {
                        _tipostatus.idtipostatus = res.data.id;
                        setTipoStatus(_tipostatus);
                        _listaTipoStatus.push(_tipostatus);
                        setListaTipoStatus(_listaTipoStatus);
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                        setEdicao(true);
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                        console.log(error);
                    })
            }
            // setListaTipoStatus(_listaTipoStatus);
            setTipoStatusDialog(false);
            setTipoStatus(emptyTipoStatus);
        }
    }

    const editTipoStatus = (tipostatus) => {
        setTipoStatus({ ...tipostatus });
        setTipoStatusDialog(true);
    }

    const deleteTipoStatus = () => {
        api.delete(`/tipostatus/${tipostatus.idtipostatus}`).then(res => {
            if (res.status === 200) {
                let _tipostatus = listaTipoStatus.filter(val => val.idtipostatus !== tipostatus.idtipostatus);
                setListaTipoStatus(_tipostatus);
                setDeleteTipoStatusDialog(false);
                setTipoStatus(emptyTipoStatus);
                toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
            }
        })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
                console.log(error);
            })
    }

    const confirmDeleteTipoStatus = (tipostatus) => {
        setTipoStatus(tipostatus);
        setDeleteTipoStatusDialog(true);
    }

    const hideDialog = () => {
        setSubmitted(false);
        setTipoStatusDialog(false);
    }

    const hideDeleteTipoStatusDialog = () => {
        setDeleteTipoStatusDialog(false);
    }

    const findIndexById = (n) => {
        let index = -1;
        for (let i = 0; i < listaTipoStatus.length; i++) {
            if (listaTipoStatus[i].idtipostatus === n) {
                index = i;
                break;
            }
        }
        return index;
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _tipostatus = { ...tipostatus };
        _tipostatus[`${name}`] = val;
        setTipoStatus(_tipostatus);
    }

    const handleChangeComplete = (color) => {
        let _tipostatus = { ...tipostatus };
        _tipostatus.corhexa = color.hex;
        console.log(color.hex);
        setTipoStatus(_tipostatus);
    };

    const handleChangeComplete2 = (color) => {
        let _tipostatus = { ...tipostatus };
        _tipostatus.corfonte = '$FF' + color.hex.substring(1, 7);
        setTipoStatus(_tipostatus);
    };

    const handleChangeComplete3 = (color) => {
        let _tipostatus = { ...tipostatus };
        _tipostatus.cor = '$FF' + color.hex.substring(1, 7);
        setTipoStatus(_tipostatus);
    };

    const onCheckChange = (value) => {
        let _tipostatus = { ...tipostatus };
        _tipostatus['finalizaocorrencia'] = value;
        setTipoStatus(_tipostatus);
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
            </React.Fragment>
        )
    }

    const exportExcel = () => {
        import('xlsx').then(xlsx => {
            const worksheet = xlsx.utils.json_to_sheet(listaTipoStatus);
            const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            saveAsExcelFile(excelBuffer, 'listaTipoStatus');
        });
    }

    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then(FileSaver => {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
            </React.Fragment>
        )
    }

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                {/* <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editTipoStatus(rowData)} /> */}
                <Link to={`/tipostatusdetalhes/${rowData['idtipostatus']}`}><Button icon="pi pi-pencil" className="p-button p-mr-2" /></Link>
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteTipoStatus(rowData)} />
            </div>
        );
    }

    const finalizaOcorrenciaTemplate = (rowData) => {
        var trueValue = <span className="product-badge" style={{ background: '#c8e6c9', color: '#256029' }} >{rowData.finalizaocorrencia && "sim"}</span>
        var falseValue = <span className="product-badge" style={{ background: '#ffcdd2', color: '#c63737' }} >{!rowData.finalizaocorrencia && "não"}</span>
        return (rowData.finalizaocorrencia ? trueValue : falseValue)
    }

    const corTemplate = (rowData) => {
        return (<Badge className="product-badge" size="large" style={{ background: `${rowData.corhexa}` }} ></Badge>);
    }

    const corTemplate2 = (rowData) => {
        return (<Badge className="product-badge" size="large" style={{ background: '#' + `${rowData.corfonte.substring(3, 9)}` }} ></Badge>);
    }

    const corTemplate3 = (rowData) => {
        return (<Badge className="product-badge" size="large" style={{ background: '#' + `${rowData.cor.substring(3, 9)}` }} ></Badge>);
    }

    const header = (
        <div className="table-header">
            <h5 className="p-m-0 p-text-bold">Status</h5>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
            </span>
        </div>
    );

    const tipostatusDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveTipoStatus} />
        </>
    );

    const deleteTipoStatusDialogFooter = (
        <>
            <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteTipoStatusDialog} />
            <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteTipoStatus} />
        </>
    );

    if (edicao) {
        return <Redirect push to={`/tipostatusdetalhes/${tipostatus.idtipostatus}`} />;
    }
    else {
        return (
            <div>
                <div className="p-grid crud-demo">
                    <div className="p-col-12">
                        <div className="card">

                            <Toast ref={toast} />
                            <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                            <DataTable
                                ref={dt} value={listaTipoStatus} dataKey="idtipostatus"
                                paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Tipos de Status" globalFilter={globalFilter}
                                emptyMessage="Nenhuma TipoStatus encontrada." header={header}>
                                <Column field="idtipostatus" header="Id" sortable style={{ width: '6%' }} />
                                <Column field="tipostatus" header="Descrição" sortable ></Column>
                                <Column field="cor" header="Cor - App" body={corTemplate3} className="p-text-center" style={{ width: '10%' }}  ></Column>
                                <Column field="corfonte" header="Cor da Fonte - App" body={corTemplate2} className="p-text-center" style={{ width: '10%' }}  ></Column>
                                <Column field="finalizaocorrencia" header="Finaliza Ocorrencia" body={finalizaOcorrenciaTemplate} className="p-text-center" style={{ width: '6%' }} ></Column>
                                <Column field="corhexa" header="Cor - Gráficos" body={corTemplate} style={{ width: '10%' }} className="p-text-center"  ></Column>
                                <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                            </DataTable>
                            <Dialog visible={tipostatusDialog} style={{ width: '750px' }} header="Status" modal className="p-fluid" footer={tipostatusDialogFooter} onHide={hideDialog}>
                                <div className="p-field">
                                    <div className='p-grid' >
                                        <div className='p-col-6' >
                                            <label htmlFor="tipostatus">Status</label>
                                            <InputText id="tipostatus" value={tipostatus.tipostatus} onChange={(e) => onInputChange(e, 'tipostatus')} required autoFocus className={classNames({ 'p-invalid': submitted && !tipostatus.tipostatus })} />
                                            {submitted && !tipostatus.tipostatus && <small className="p-invalid">Status é requerido.</small>}
                                        </div>
                                        <div className="p-col-6 p-mt-5">
                                            <div className="p-field-checkbox">
                                                <Checkbox inputId="finaliza" checked={tipostatus.finalizaocorrencia} onChange={(e) => onCheckChange(e.checked)} />
                                                <label htmlFor="finaliza"> Finaliza ocorrência</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-field">
                                    <div className='p-grid'>
                                        <div className='p-col-4'>
                                            <label htmlFor="cor">Cor</label>
                                            <ChromePicker id="cor"
                                                disableAlpha
                                                color={'#' + tipostatus.cor.substring(3, 9)}
                                                onChangeComplete={handleChangeComplete3}
                                            />
                                        </div>
                                        <div className='p-col-4'>
                                            <label htmlFor="corfonte">Cor da Fonte</label>
                                            <ChromePicker id="corfonte"
                                                disableAlpha
                                                color={'#' + tipostatus.corfonte.substring(3, 9)}
                                                onChangeComplete={handleChangeComplete2}
                                            />
                                        </div>
                                        <div className="p-col-4">
                                            <label htmlFor="corhexa">Cor - Gráficos</label>
                                            <ChromePicker id="corhexa"
                                                disableAlpha
                                                color={tipostatus.corhexa}
                                                onChangeComplete={handleChangeComplete}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                            <Dialog visible={deleteTipoStatusDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteTipoStatusDialogFooter} onHide={hideDeleteTipoStatusDialog}>
                                <div className="confirmation-content">
                                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                                    {tipostatus && <span>Tem certeza que deseja excluir <b>{tipostatus.tipostatus}</b>?</span>}
                                </div>
                            </Dialog>
                        </div>
                    </div >
                </div >
            </div>
        );
    }
}
