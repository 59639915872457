import React from 'react';
import { useState, useEffect, useRef } from 'react';
import api from '../service/api';
import { DataTable } from '../components/datatable/DataTable';
import { Column } from 'primereact/column';
import { Toast } from '../components/toast/Toast';
import { Toolbar } from '../components/toolbar/Toolbar';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import classNames from 'classnames';
import { Button } from '../components/button/Button';

export const SistemaConstrutivoEdit = () => {

  let emptySistemaConstrutivo = {
    idsistemaconstrutivo: null,
    sistemaconstrutivo: '',
    tag: '',
    cor: '',
  };

  const [sistemaconstrutivo, setSistemaConstrutivo] = useState(emptySistemaConstrutivo);
  const [listaSistemaConstrutivo, setListaSistemaConstrutivo] = useState(null);
  const [sistemaconstrutivoDialog, setSistemaConstrutivoDialog] = useState(false);
  const [deleteSistemaConstrutivoDialog, setDeleteSistemaConstrutivoDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
      api.get(`/sistemaconstrutivo`).then((res) => {
          setListaSistemaConstrutivo(res.data);
      });
  }, []);

  const openNew = () => {
    setSistemaConstrutivo(emptySistemaConstrutivo);
    setSubmitted(false);
    setSistemaConstrutivoDialog(true);
  }

  const saveSistemaConstrutivo = () => {
    setSubmitted(true);
    if (sistemaconstrutivo.sistemaconstrutivo.trim()) {
        let _listaSistemaConstrutivo = [...listaSistemaConstrutivo];
        let _sistemaconstrutivo = { ...sistemaconstrutivo };
        if (_sistemaconstrutivo.idsistemaconstrutivo){
            const index = findIndexById(_sistemaconstrutivo.idsistemaconstrutivo);
            api.put('/sistemaconstrutivo', _sistemaconstrutivo).then(res => {
                if (res.status === 200) {
                    _listaSistemaConstrutivo[index] = _sistemaconstrutivo;
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                }
            })
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                    console.log(error);
                })
        }
        else {
            _sistemaconstrutivo.idsistemaconstrutivo = 0;
            api.post('/sistemaconstrutivo',_sistemaconstrutivo).then(res => {
                if (res.status === 200) {
                    _sistemaconstrutivo.idsistemaconstrutivo = res.data.idsistemaconstrutivo;
                    _listaSistemaConstrutivo.push(_sistemaconstrutivo);
                    toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro inserido', life: 3000 });
                }
            })
            .catch(error => {
                toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu inserir o registro', life: 3000 });
                console.log(error);
            })
        }
        setListaSistemaConstrutivo(_listaSistemaConstrutivo);
        setSistemaConstrutivoDialog(false);
        setSistemaConstrutivo(emptySistemaConstrutivo);
    }
  }

  const editSistemaConstrutivo = (sistemaconstrutivo) => {
      setSistemaConstrutivo({ ...sistemaconstrutivo});
      setSistemaConstrutivoDialog(true);
  }

  const deleteSistemaConstrutivo= () => {
      api.delete(`/sistemaconstrutivo/${sistemaconstrutivo.idsistemaconstrutivo}`).then(res => {
          if (res.status === 200){
              let _sistemaconstrutivo= listaSistemaConstrutivo.filter(val => val.idsistemaconstrutivo !== sistemaconstrutivo.idsistemaconstrutivo);
              setListaSistemaConstrutivo(_sistemaconstrutivo);
              setDeleteSistemaConstrutivoDialog(false);
              setSistemaConstrutivo(emptySistemaConstrutivo);
              toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro excluído', life: 3000 });
          }
      })
      .catch(error => {
          toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu excluir o registro', life: 3000 });
          console.log(error);
      })
  }

  const confirmDeleteSistemaConstrutivo= (sistemaconstrutivo) => {
      setSistemaConstrutivo(sistemaconstrutivo);
      setDeleteSistemaConstrutivoDialog(true);
  }

  const hideDialog = () => {
      setSubmitted(false);
      setSistemaConstrutivoDialog(false);
  }

  const hideDeleteSistemaConstrutivoDialog = () => {
      setDeleteSistemaConstrutivoDialog(false);
  }

  const findIndexById = (n) => {
      let index = -1;
      for (let i = 0; i < listaSistemaConstrutivo.length; i++) {
          if (listaSistemaConstrutivo[i].idsistemaconstrutivo === n) {
              index = i;
              break;
          }
      }
      return index;
  }

  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value) || '';
      let _sistemaconstrutivo = { ...sistemaconstrutivo};
      _sistemaconstrutivo[`${name}`] = val;
      setSistemaConstrutivo(_sistemaconstrutivo);
  }

  const leftToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Novo" icon="pi pi-plus" className="p-button-primary p-mr-2" onClick={openNew} />
          </React.Fragment>
      )
  }

  const exportExcel = () => {
      import('xlsx').then(xlsx => {
          const worksheet = xlsx.utils.json_to_sheet(listaSistemaConstrutivo);
          const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
          const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
          saveAsExcelFile(excelBuffer, 'listaSistemaConstrutivo');
      });
  }

  const saveAsExcelFile = (buffer, fileName) => {
      import('file-saver').then(FileSaver => {
          let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          let EXCEL_EXTENSION = '.xlsx';
          const data = new Blob([buffer], {
              type: EXCEL_TYPE
          });
          FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
      });
  }

  const rightToolbarTemplate = () => {
      return (
          <React.Fragment>
              <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportExcel} />
          </React.Fragment>
      )
  }

  const actionBodyTemplate = (rowData) => {
      return (
          <div className="actions">
                <Button icon="pi pi-pencil" className="p-button p-button-primary p-mr-2" onClick={() => editSistemaConstrutivo(rowData)} />
                <Button icon="pi pi-trash" className="p-button p-button-danger" onClick={() => confirmDeleteSistemaConstrutivo(rowData)} />
          </div>
      );
  }

  const header = (
      <div className="table-header">
          <h5 className="p-m-0 p-text-bold">SistemaConstrutivo</h5>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Procurar..." />
          </span>
      </div>
  );

  const sistemaconstrutivoDialogFooter = (
      <>
          <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
          <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveSistemaConstrutivo} />
      </>
  );

  const deleteSistemaConstrutivoDialogFooter = (
      <>
          <Button label="Não" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSistemaConstrutivoDialog} />
          <Button label="Sim" icon="pi pi-check" className="p-button-text" onClick={deleteSistemaConstrutivo} />
      </>
  );

  return (
      <div className="p-grid crud-demo">
          <div className="p-col-12">
              <div className="card">

                  <Toast ref={toast} />
                  <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

                  <DataTable
                      ref={dt} value={listaSistemaConstrutivo} dataKey="idsistemaconstrutivo"
                      paginator rows={10} rowsPerPageOptions={[5, 10, 25]} className="datatable-responsive"
                      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                      currentPageReportTemplate="Exibindo de {first} a {last} de {totalRecords} Sistemas Construtivos" globalFilter={globalFilter}
                      emptyMessage="Nenhuma SistemaConstrutivo encontrada." header={header}>
                      <Column field="idsistemaconstrutivo" header="Id" sortable style={{ width: '6%' }} />
                      <Column field="sistemaconstrutivo" header="Descrição" sortable ></Column>
                      <Column body={actionBodyTemplate} className="p-text-center" style={{ width: '12%' }} ></Column>
                  </DataTable>
                  <Dialog visible={sistemaconstrutivoDialog} style={{ width: '450px' }} header="Sistema Construtivo" modal className="p-fluid" footer={sistemaconstrutivoDialogFooter} onHide={hideDialog}>
                      <div className="p-field">
                          <label htmlFor="sistemaconstrutivo">Sistema Construtivo</label>
                          <InputText id="sistemaconstrutivo" value={sistemaconstrutivo.sistemaconstrutivo} onChange={(e) => onInputChange(e, 'sistemaconstrutivo')} required autoFocus className={classNames({ 'p-invalid': submitted && !sistemaconstrutivo.sistemaconstrutivo})} />
                          {submitted && !sistemaconstrutivo.sistemaconstrutivo && <small className="p-invalid">Sistema Construtivo é requerido.</small>}
                      </div>
                      <div className="p-field">
                          <label htmlFor="tag">TAG</label>
                          <InputText id="tag" value={sistemaconstrutivo.tag} onChange={(e) => onInputChange(e, 'tag')} required className={classNames({ 'p-invalid': submitted && !sistemaconstrutivo.tag})} />
                          {submitted && !sistemaconstrutivo.tag && <small className="p-invalid">TAG é requerido.</small>}
                      </div>
                      <div className="p-field">
                          <label htmlFor="cor">Cor</label>
                          <InputText id="cor" value={sistemaconstrutivo.cor} onChange={(e) => onInputChange(e, 'cor')} required className={classNames({ 'p-invalid': submitted && !sistemaconstrutivo.cor})} />
                          {submitted && !sistemaconstrutivo.cor && <small className="p-invalid">Cor é requerido.</small>}
                      </div>
                  </Dialog>
                  <Dialog visible={deleteSistemaConstrutivoDialog} style={{ width: '450px' }} header="Confirmação" modal footer={deleteSistemaConstrutivoDialogFooter} onHide={hideDeleteSistemaConstrutivoDialog}>
                      <div className="confirmation-content">
                          <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: '2rem' }} />
                          {sistemaconstrutivo && <span>Tem certeza que deseja excluir <b>{sistemaconstrutivo.sistemaconstrutivo}</b>?</span>}
                      </div>
                  </Dialog>
              </div>
          </div>
      </div>
  );
}
