import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Toast } from '../components/toast/Toast';
import api from '../service/api';
import classNames from 'classnames';
import { Panel } from '../components/panel/Panel';
import { Button } from '../components/button/Button';
import { Dialog } from '../components/dialog/Dialog';
import { InputText } from '../components/inputtext/InputText';
import { Dropdown } from '../components/dropdown/Dropdown';
import { Checkbox } from '../components/checkbox/Checkbox';
import { InputTextarea } from '../components/inputtextarea/InputTextarea';
import { useHistory } from 'react-router-dom';
import { AtividadeChecklistAnexos } from './AtividadeChecklistAnexos';

export const AtividadeChecklistEdit = (props) => {

    let emptyChecklistItem = {
        idchecklistitens: null,
        idatividade: null,
        pergunta: '',
        respostapadrao: null,
        legendaocorrencia: '',
        ordem: '',
        idgravidadeocorrencia: null,
        dataatualizacao: null,
        ativo: true,
        tiporespostachecklist: '',
        recomendacoestecnicas: '',
        enquadramentostecnicos: '',
    };

    const history = useHistory();

    const [checklistItem, setChecklistItem] = useState(emptyChecklistItem);
    const [listaGravidades, setListaGravidades] = useState(null);
    const [listaRespostas, setListaRespostas] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
        api.get(`/checklistitens/${props.match.params.id}`).then((res) => {
            setChecklistItem(res.data);
        });
        api.get(`/gravidade`).then((res) => {
            setListaGravidades(res.data);
        });
        api.get(`/tiporespostachecklist`).then((res) => {
            setListaRespostas(res.data);
        });
    }, [props.match.params.id]);

    const saveChecklistItem = () => {

        setSubmitted(true);

        

        if (checklistItem.pergunta.trim() && checklistItem.respostapadrao && checklistItem.legendaocorrencia &&
            checklistItem.ordem && checklistItem.idgravidadeocorrencia && checklistItem.respostapadrao) {

            let _checklistItem = { ...checklistItem };
            if (_checklistItem.idchecklistitens) {
                api.put('/checklistitens', _checklistItem).then(res => {
                    if (res.status === 200) {
                        toast.current.show({ severity: 'success', summary: 'Sucesso', detail: 'Registro atualizado', life: 3000 });
                    }
                })
                    .catch(error => {
                        toast.current.show({ severity: 'error', summary: 'Erro', detail: 'O sistema não conseguiu atualizar o registro', life: 3000 });
                        console.log(error);
                    })
            }
            //setChecklistItem(emptyChecklistItem);
        }
    }

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _checklistItem = { ...checklistItem };
        _checklistItem[`${name}`] = val;
        setChecklistItem(_checklistItem);
    }

    const onRespostaChange = (e) => {
        let _checklistItem = { ...checklistItem };
        _checklistItem.respostapadrao = e.value;

        for (let i = 0; i < listaRespostas.length; i++) {
            if (listaRespostas[i].idtiporespostachecklist === e.value) {
                _checklistItem.tiporespostachecklist = listaRespostas[i].tiporespostachecklist;
                break;
            }
        }

        setChecklistItem(_checklistItem);
    }

    const onGravidadeChange = (e) => {
        let _checklistItem = { ...checklistItem };
        _checklistItem.idgravidadeocorrencia = e.value;
        setChecklistItem(_checklistItem);
    }

    const onAtivoChange = (e) => {
        let _checklistItem = { ...checklistItem };
        _checklistItem.ativo = e.checked;
        setChecklistItem(_checklistItem);
    }

    return (
        <div className="p-grid crud-demo">
            <div className="p-col-12">
                <div className='card'>
                    <Toast ref={toast} />
                    <Panel header={`Checklist - ${checklistItem.idchecklistitens}`}>
                        <div className="p-fluid p-formgrid p-grid">
                            <div className="p-field p-col-12">
                                <label htmlFor="pergunta">Pergunta</label>
                                <InputText id="pergunta" value={checklistItem.pergunta} onChange={(e) => onInputChange(e, 'pergunta')} required className={classNames({ 'p-invalid': submitted && !checklistItem.pergunta })} />
                                {submitted && !checklistItem.pergunta && <small className="p-invalid">Pergunta é requerido.</small>}
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="legendaocorrencia">Legenda da Ocorrência</label>
                                <InputText id="legendaocorrencia" value={checklistItem.legendaocorrencia} onChange={(e) => onInputChange(e, 'legendaocorrencia')} required className={classNames({ 'p-invalid': submitted && !checklistItem.legendaocorrencia })} />
                                {submitted && !checklistItem.legendaocorrencia && <small className="p-invalid">Legenda da Ocorrência é requerido.</small>}
                            </div>
                            <div className="p-field p-col-4" >
                                <label htmlFor="idtiporespostachecklist">Resposta Padrão</label>
                                <Dropdown
                                    inputId="respostapadrao"
                                    optionValue="idtiporespostachecklist"
                                    optionLabel="tiporespostachecklist"
                                    value={checklistItem.respostapadrao}
                                    options={listaRespostas}
                                    onChange={(e) => onRespostaChange(e, 'respostapadrao')}
                                    placeholder="Selecione"
                                    required
                                    className={classNames({ 'p-invalid': submitted && !checklistItem.respostapadrao })} />
                                {submitted && !checklistItem.respostapadrao && <small className="p-invalid">Resposta Padrão é requerido.</small>}
                            </div>

                            <div className="p-field p-col-4">
                                <label htmlFor="ordem">Ordem</label>
                                <InputText id="ordem" type='number' value={checklistItem.ordem} onChange={(e) => onInputChange(e, 'ordem')} required className={classNames({ 'p-invalid': submitted && !checklistItem.ordem })} />
                                {/* <InputNumber id="ordem" value={checklistItem.ordem} onChange={(e) => onInputChange(e, 'ordem')} required className={classNames({ 'p-invalid': submitted && !checklistItem.ordem })} /> */}
                                {submitted && !checklistItem.ordem && <small className="p-invalid">Ordem é requerido.</small>}
                            </div>
                            <div className="p-field p-col-4" >
                                <label htmlFor="idgravidadeocorrencia">Gravidade</label>
                                <Dropdown
                                    inputId="idgravidadeocorrencia"
                                    optionValue="idgravidade"
                                    optionLabel="gravidade"
                                    value={checklistItem.idgravidadeocorrencia}
                                    options={listaGravidades}
                                    onChange={(e) => onGravidadeChange(e, 'idgravidadeocorrencia')}
                                    placeholder="Selecione"
                                    required
                                    className={classNames({ 'p-invalid': submitted && !checklistItem.idgravidadeocorrencia })} />
                                {submitted && !checklistItem.idgravidadeocorrencia && <small className="p-invalid">Gravidade é requerido.</small>}
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="recomendacoestecnicas">Recomendações Técnicas</label>
                                <InputTextarea id="recomendacoestecnicas" value={checklistItem.recomendacoestecnicas} onChange={(e) => onInputChange(e, 'recomendacoestecnicas')} />
                            </div>
                            <div className="p-field p-col-12">
                                <label htmlFor="enquadramentostecnicos">Enquadramento Técnico</label>
                                <InputTextarea id="enquadramentostecnicos" value={checklistItem.enquadramentostecnicos} onChange={(e) => onInputChange(e, 'enquadramentostecnicos')} />
                            </div>
                            <div className="p-d-flex" >
                                <Checkbox inputId="ativo" name="ativo" value={checklistItem.ativo}
                                    checked={checklistItem.ativo} onChange={(e) => onAtivoChange(e)} />
                                <label htmlFor="ativo" className="p-ml-2 p-mr-2">Ativo</label>
                            </div>
                        </div>
                        <div className="p-d-flex p-justify-end">
                            <div>
                                <Button label="Voltar" type="button" icon="pi pi-arrow-left" className="p-button-warning p-mr-2" onClick={history.goBack} />
                                <Button label="Salvar" icon="pi pi-check" className="p-button-success" onClick={saveChecklistItem} />
                            </div>
                        </div>
                    </Panel>
                    <Panel header={'Anexos'} className="p-mt-2">
                        <AtividadeChecklistAnexos id={props.match.params.id} />
                    </Panel>
                </div>
            </div>
        </div>
    );
}